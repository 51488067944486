import React, { useCallback, useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
// import { SingleValue } from "react-select";

// import Table from "../../../core/common/dataTable";
// import { TableData } from "../../../core/data/interface";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import PredefinedDateRanges from "../../../core/common/datePicker";
import { all_routes } from "../../router/all_routes";
// import TooltipOption from "../../../core/common/tooltipOption";
import { _get, _post } from "../../Utils/Helper";
// import { ClassAttendanceForm as initialClassAttendanceForm } from "../../../core/data/json/student_attendance";
// import { academicYear } from "../../../core/common/selectoption/selectoption";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { SingleValue } from "react-select";
import { Table } from "antd";

// Define interfaces for better type safety
interface Class {
  id: number;
  class_name: string;
}

interface Section {
  id: number;
  section: string;
}

interface Student {
  id: number;
  admission_no: string;
  full_name: string;
  attendance: AttendanceStatus;
  notes?: string;
}

type AttendanceStatus = "Present" | "Late" | "Absent" | "Holiday" | "Halfday";

const ClassAttendanceForm: React.FC = () => {
  const initialForm = {
    query_type:"",
    teacher_name:"Ishaq",
    teacher_id:"1",
    section:"",
    class_name:"",
    day:"",
    status:"",
    student_name:"",
    admission_no:"",
    term:"",
    academic_year:""
  };

  const [form, setForm] = useState(initialForm);
  const routes = all_routes;
  const [sections, setSections] = useState<Section[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  // Options for selects
  const sectionsOptions: Option[] = sections.map((section) => ({
    label: section.section,
    value: section.section,
  }));

  const classesOptions: Option[] = classes.map((classItem) => ({
    label: classItem.class_name,
    value: classItem.class_name,
  }));

  // Fetch classes
  const getClasses = () => {
    _get(
      `classes?query_type=select-all`,
      (res) => setClasses(res.data),
      (err) => console.error(err)
    );
  };

  // Fetch sections
  const getSections = () => {
    _get(
      `classes?query_type=select-sections`,
      (res) => setSections(res.data),
      (err) => console.error(err)
    );
  };

  // Fetch students based on selected class
  const getStudents = useCallback(() => {
    if (form.class_name) {
      _get(
        `students?query_type=select-class&current_class=${form.class_name}`,
        (res) => {
          // Initialize attendance and notes for each student
          const fetchedStudents: Student[] = res.data.map((student: any) => ({
            ...student,
            attendance: "Present", // Default attendance
            notes: "",
          }));
          setStudents(fetchedStudents);
        },
        (err) => console.error(err)
      );
    } else {
      setStudents([]);
    }
  }, [form.class_name]);

  // Initial fetch
  useEffect(() => {
    getClasses();
    getSections();
  }, []);

  // Fetch students when class_name changes
  useEffect(() => {
    getStudents();
  }, [getStudents]);

  // Handle attendance change
  const handleAttendanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    studentId: number
  ) => {
    const { value } = event.target;
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === studentId
          ? { ...student, attendance: value as AttendanceStatus }
          : student
      )
    );
  };

  // Handle notes change
  const handleNotesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    studentId: number
  ) => {
    const { value } = event.target;
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === studentId ? { ...student, notes: value } : student
      )
    );
  };

  // Handle form submission
  const handleSubmit = () => {
    const payload = students?.map((student: Student) => ({
      admission_no: student.admission_no,
      id: student.id,
      student_name: student.full_name,
      status: student.attendance,
      class_name:form.class_name,
      academic_year:form.academic_year,
      term: form.term,
      section: form.section,
      teacher_name: form.teacher_name,
      teacher_id: form.teacher_id,
      notes: student.notes || "",
      query_type: "create",
    }));

    _post(
      "students/attendance",
      payload,
      (res) => {
        console.log("Submission successful", res);
        toast.success("Exam results submitted successfully!");
      },
      (err) => {
        console.error("Error submitting data", err);
        toast.error("There was an error submitting the data.");
      }
    );
  };

  // Define table columns with TypeScript types
  const columns = [
    {
      title: "Admission No",
      dataIndex: "admission_no",
      key: "admission_no",
      render: (text: string) => (
        <Link to="#" className="link-primary">
          {text}
        </Link>
      ),
      sorter: (a: Student, b: Student) =>
        a.admission_no.localeCompare(b.admission_no),
    },
    {
      title: "Student Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: Student, b: Student) =>
        a.full_name.localeCompare(b.full_name),
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      render: (attendance: AttendanceStatus, record: Student) => (
        <div className="d-flex align-items-center check-radio-group flex-nowrap">
          {["Present", "Late", "Absent", "Holiday", "Halfday"].map((status) => (
            <label className="custom-radio me-2" key={status}>
              <input
                type="radio"
                name={`attendance-${record.id}`}
                value={status}
                checked={attendance === status}
                onChange={(e) => handleAttendanceChange(e, record.id)}
              />
              <span className="checkmark" />
              {status}
            </label>
          ))}
        </div>
      ),
      sorter: (a: Student, b: Student) =>
        a.attendance.localeCompare(b.attendance),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text: string, record: Student) => (
        <input
          type="text"
          className="form-control"
          placeholder="Enter Note"
          value={record.notes}
          onChange={(e) => handleNotesChange(e, record.id)}
        />
      ),
      sorter: (a: Student, b: Student) =>
        (a.notes || "").localeCompare(b.notes || ""),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* Page Header */}
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Student Attendance</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.adminDashboard}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Report</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Student Attendance
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            {/* <TooltipOption /> */}
          </div>
        </div>
        {/* /Page Header */}

        {/* Student List */}
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
            <h4 className="mb-3">Daily Attendance</h4>
            <div className="d-flex align-items-center flex-wrap">

              {/* <div className="col-md-3">
                <div className="mb-3">
                  <label className="form-label">Section</label>
                  <CommonSelect
                    className="select"
                    options={sectionsOptions}
                    defaultValue={
                      {
                        label: form.section,
                        value: form.section,
                      }
                    }
                    handleChange={(newValue: SingleValue<Option>) =>
                      setForm((prev) => ({
                        ...prev,
                        section: newValue ? newValue.value : "",
                      }))
                    }
                  />
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label">Class</label>
                  <CommonSelect
                    className="select"
                    options={classesOptions}
                    defaultValue={
                      {
                        label: form.class_name,
                        value: form.class_name,
                      }
                    }
                    handleChange={(newValue: SingleValue<Option>) =>
                      setForm((prev) => ({
                        ...prev,
                        class_name: newValue ? newValue.value : "",
                      }))
                    }
                  />
                </div>
              </div>
              {/* Date Range Picker */}
              <div className="input-icon-start col-md-8 mb-3  position-relativ">

                <label className="form-label">Date ranges</label>
                <PredefinedDateRanges />
              </div>
              {/* <div className="dropdown mb-3">
                <button
                  className="btn btn-outline-light bg-white dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-sort-ascending-2 me-2" />
                  Sort by A-Z
                </button>
                <ul className="dropdown-menu p-3">
                  <li>
                    <button className="dropdown-item rounded-1 active">
                      Ascending
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item rounded-1">
                      Descending
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item rounded-1">
                      Recently Viewed
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item rounded-1">
                      Recently Added
                    </button>
                  </li>
                </ul>
              </div> */}

            </div>
          </div>

          {/* Table Body */}
          <div className="card-body p-0 py-3">
            <Table
              dataSource={students}
              columns={columns}
            // rowKey="id"
            // If your Table component supports selection, you can enable it here
            // rowSelection={{}}
            />
          </div>

          {/* Submission Button */}
          <div className="card-footer d-flex justify-content-end">
            <button
              className="btn btn-success"
              onClick={handleSubmit}
              disabled={students.length === 0}
            >
              Submit Attendance
            </button>
          </div>
        </div>
        {/* /Student List */}
      </div>
    </div>
  );
};

export default ClassAttendanceForm;
