import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useQuery, { _get, _post } from "../../Utils/Helper";
import { Button, Dropdown, Menu } from "antd";
import FeesSetupModal from "./FeesSetupModal";
import { toast } from "react-toastify";
import { acadamicTerms, academicYear } from "../../../core/common/selectoption/selectoption";

const FeesSetup = () => {
  const navigate = useNavigate();
  const application_no = useQuery().get("application_no");

  interface Payment{
    id:string;
    code: string;
    status: string;
    description: string;
    amount: string;
    term: string;
    year: string;
    due_date:string; 
    section:string;
     payment_type:string;
    class_level:string;
  }
  
  const [form, setForm] = useState({
    year: academicYear[0].value,
    term: acadamicTerms[0].value, 
  });
  const _edit ={
    id:"",
    code: "",
    status: "Active",
    description: "",
    amount: '0',
    term: form.term,
    year: form.year,
    due_date: "",
    section: "",
    payment_type: "",
    class_level: '0',
  }
  const [payments, setPayments] = useState<Payment[]>([]);

  useEffect(() => {
    _get(
      `revenue-heads?query_type=select&year=${form.year}&term=${form.term}`,
      (resp) => {
        setPayments(resp.data);
      },
      (err) => {
        console.error(err);
      }
    );
  }, [form.year, form.term]);

  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState<Payment>(_edit);

  const handleModalSubmit = (formData: any) => {
    const randNumber = Math.floor(100000000 + Math.random() * 900000000).toString();

    if (editData) {
      // Edit existing fee
      _post(
        `revenue-heads`,
        {
          query_type: "update",
          code: editData.code,
          status: editData.status,
          ...formData,
        },
        (resp) => {
          toast.success("Updated successfully");
          setModalVisible(false);
          setEditData(_edit);
          setPayments((prev) =>
            prev.map((item) =>
              item.code === editData.code ? { ...item, ...formData } : item
            )
          );
        },
        (err) => {
          console.error(err);
          toast.error("Failed to update");
        }
      );
    } else {
      // Create new fee
      _post(
        `revenue-heads`,
        {
          query_type: "create",
          code: randNumber,
          status: 'Active',
          ...formData,
        },
        (resp) => {
          toast.success("Created successfully");
          setModalVisible(false);
          setPayments((prev) => [...prev, { ...formData, code: randNumber }]);
        },
        (err) => {
          console.error(err);
          toast.error("Failed to create");
        }
      );
    }
  };

  const handleEdit = (payment: any) => {
    setEditData(payment);
    setModalVisible(true);
  };

  const handleDelete = (id: string) => {
    _get(
      `revenue-heads?query_type=delete&id=${id}`,
      (resp) => {
        toast.success("Deleted successfully");
        setPayments((prev) => prev.filter((item) => item.id !== id));
      },
      (err) => {
        console.error(err);
        toast.error("Failed to delete");
      }
    );
  };

  const menu = (payment: any) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEdit(payment)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleDelete(payment.id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-xxl-12 col-xl-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                      <h4 className="mb-3">Fees</h4>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="dropdown mb-3 me-2">
                          <Link
                            to=""
                            className="btn btn-outline-light bg-white dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-calendar-due me-2" /> Year : {academicYear[0].label}
                          </Link>
                          <ul className="dropdown-menu p-3">
                            <li><Link
                              className="dropdown-item rounded-1"
                              to=""
                              onClick={() => {
                                setForm((p: any) => ({ ...p, year: '' }));
                              }}
                            >
                              <i className="ti ti-calendar-due me-2" />
                              General
                            </Link></li>
                            {academicYear.map(year => (
                              <li key={year.value}>
                                <Link
                                  className="dropdown-item rounded-1"
                                  to=""
                                  onClick={() => {
                                    setForm((p: any) => ({ ...p, year: year.value }));
                                  }}
                                >
                                  <i className="ti ti-calendar-due me-2" />
                                  Year : {year.value}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="dropdown mb-3 me-2">
                          <Link
                            to=""
                            className="btn btn-outline-light bg-white dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-filter me-2" /> {form.term || 'General'}
                          </Link>
                          <ul className="dropdown-menu p-3">
                            <li><Link
                              className="dropdown-item rounded-1"
                              to=""
                              onClick={() => {
                                setForm((p: any) => ({ ...p, term: '' }));
                              }}
                            >
                              <i className="ti ti-filter me-2" />
                              General
                            </Link></li>
                            <li><Link
                              className="dropdown-item rounded-1"
                              to=""
                              onClick={() => {
                                setForm((p: any) => ({ ...p, term: 'Per Annum' }));
                              }}
                            >
                              <i className="ti ti-filter me-2" />
                              Per Annum
                            </Link></li>
                            {acadamicTerms.map(term => (
                              <li key={term.value}>
                                <Link
                                  className="dropdown-item rounded-1"
                                  to=""
                                  onClick={() => {
                                    setForm((p: any) => ({ ...p, term: term.value }));
                                  }}
                                >
                                  <i className="ti ti-filter me-2" />
                                  {term.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="dropdown mb-3 me-2">
                          <Button onClick={() => setModalVisible(true)} className="btn btn-md">+ Add new Fee</Button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 py-3">
                      {/* Fees List */}
                      <div className="custom-datatable-filter table-responsive">
                        <table className="table datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>Description</th>
                              <th>Due Date</th>
                              <th>Amount (NGN)</th>
                              <th>Year</th>
                              <th>Term</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments?.map((payment: any, index: number) => (
                              <tr key={index}>
                                <td>
                                  <p className="text-primary fees-group">
                                    <span className="d-block">
                                      {payment?.description}
                                    </span>
                                  </p>
                                </td>
                                <td>{payment?.due_date}</td>
                                <td>{payment?.amount}</td>
                                <td>{payment?.year}</td>
                                <td>{payment?.term}</td>
                                <td>
                                  <span className="badge badge-soft-success d-inline-flex align-items-center">
                                    <i className="ti ti-circle-filled fs-5 me-1" />
                                    {payment?.status}
                                  </span>
                                </td>
                                <td>
                                  <Dropdown overlay={menu(payment)} trigger={['click']}>
                                    <Button className="btn btn-light">Actions</Button>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <FeesSetupModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={handleModalSubmit}
        initialValues={editData}
      />
    </>
  )
}
 export default FeesSetup;
