import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Studentlist } from "../../../../core/data/json/studentList";
import { TableData } from "../../../../core/data/interface";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import StudentModals from "../studentModals";
import Table from "../../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import {
  allClass,
  allSection,
  gender,
  names,
  schoolLocation,
  status,
} from "../../../../core/common/selectoption/selectoption";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import TooltipOption from "../../../../core/common/tooltipOption";
import { _get } from "../../../Utils/Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { SingleValue } from "react-select";
// import RootState from "../../../../redux/reducers";

const StudentList = () => {
  const _form = {
    search: "",
    class_name: "",
    section: "",
    sex: "",
    student_name: "",
    status: "",
    school_location:""
  };
  const [form, setForm] = useState(_form);
  const {classes} = useSelector((state:RootState)=>state.auth)
  const navigate = useNavigate();
  const [new_data, setNewData] = useState([]);
  const [student_id, setStudentID] = useState(0)
  // const handleChange = ({ target: { name, value } }) => {
  //     setForm((p) => ({ ...p, [name]: value }));
  // }

  const getStudentList = useCallback(() => {
    _get(
      `students?query_type=select-all&current_class=${form.class_name}&student_name=${form.student_name}&sex=${form.sex}&school_location=${form.school_location}`,
      (res) => {
        setNewData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  },[form.class_name,form.student_name,form.sex,form.school_location]);

  useEffect(() => {
    getStudentList();
  }, [getStudentList]);

  const routes = all_routes;
  const data = Studentlist;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
    getStudentList()
  };
  const handleApplyClick = () => {
    getStudentList()
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    // {
    //   title: "Admission No",
    //   dataIndex: "admission_no",
    //   // render: (text: string) => (
    //   //   <Link to={routes.studentDetail} className="link-primary">
    //   //     {text}
    //   //   </Link>
    //   // ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.AdmissionNo.length - b.AdmissionNo.length,
    // },
    {
      title: "Admission No",
      dataIndex: "admission_no",
      render: (text: string, record: any) => (
        <Link
          to={`${routes.studentDetail}?student_id=${record.id}`}
          className="link-primary"
        >
          {text}
        </Link>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      render: (text: string, record: any, index:number) => (
        <div className="d-flex align-items-center">
          {record.profile_picture?<Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.profile_picture}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link>:null}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link 
                to={`${routes.studentDetail}?student_id=${record.id}`}
                className="link-primary"
               >{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Current Class",
      dataIndex: "current_class",
      sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    // {
    //   title: "Section",
    //   dataIndex: "section",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.section.length - b.section.length,
    // },
    {
      title: "Gender",
      dataIndex: "sex",
      sorter: (a: TableData, b: TableData) => a.gender.length - b.gender.length,
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text: string) => (
    //     <>
    //       {text === "Active" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    // },
    // {
    //   title: "Date of Join",
    //   dataIndex: "DateofJoin",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.DateofJoin.length - b.DateofJoin.length,
    // },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      sorter: (a: TableData, b: TableData) => a.DOB.length - b.DOB.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text:string, record:any) => (
        <>
          <div className="d-flex align-items-center">
           {/* <Link
              to="#"
              className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle  p-0 me-2"
            >
              <i className="ti ti-brand-hipchat" />
            </Link>
            <Link
              to="#"
              className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle  p-0 me-2"
            >
              <i className="ti ti-phone" />
            </Link>
            <Link
              to="#"
              className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-3"
            >
              <i className="ti ti-mail" />
            </Link>
             */}
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="student-details"
                  >
                    <i className="ti ti-menu me-2" />
                    View Student
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to={`${routes.editStudent}?student_id=${record?.id}`}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#login_detail"
                  >
                    <i className="ti ti-lock me-2" />
                    Login Details
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item rounded-1" to="#">
                    <i className="ti ti-toggle-right me-2" />
                    Disable
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="student-promotion"
                  >
                    <i className="ti ti-arrow-ramp-right-2 me-2" />
                    Promote Student
                  </Link>
                </li>
                <li>
                <Link
              to="#"
              onClick={()=>setStudentID(record.id)}
              data-bs-toggle="modal"
              data-bs-target="#add_fees_collect"
              className="btn btn-light fs-12 fw-semibold me-3"
            >
              Collect Fees
            </Link>
            </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Suspend
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          {/* {JSON.stringify(student)} */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Students List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">Students</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Students
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />

              {/* <div className="mb-2">
                <Link
                  to={routes.addStudent}
                  className="btn btn-primary d-flex align-items-center"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Student
                </Link>
              </div> */}
            </div>
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Students List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                   {/* <div className="col-md-6">
                            <div className="mb-3 mt-2 dropdown">
                              <CommonSelect
                                className="select"
                                placeholder="Select Class"
                                options={classes.map((class_item:any)=>({label:class_item.class_name,
                                  value:class_item.class_name,
                                  IsMulti:true}))}
                                defaultValue={{
                                  label:form.class_name,
                                  value:form.class_name,
                                }}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setForm((prev) => ({
                                    ...prev,
                                    class_name: newValue ? newValue.value : "",
                                  }))
                                }
                              />
                            </div>
                          </div>
               */}
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 pb-0 border-bottom">
                        <div className="row">
                          <div className="col-md-6">
                          <div className="mb-3">
                          <label className="form-label">School Location</label>
                          <CommonSelect
                            options={schoolLocation}
                            defaultValue={{
                              label: form.school_location,
                              value: form.school_location,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                school_location: newValue ? newValue.value : "",
                              }))
                            }
                          />
                        </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classes.map((class_item:any)=>({label:class_item.class_name,
                                  value:class_item.class_name}))}
                                defaultValue={{
                                  label:form.class_name,
                                  value:form.class_name,
                                }}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setForm((prev) => ({
                                    ...prev,
                                    class_name: newValue ? newValue.value : "",
                                  }))
                                }
                              />
                            </div>
                          </div>
                          
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <input className="form-control"
                                name={'student_name'}
                                defaultValue={form.student_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender</label>
                              <CommonSelect
                                className="select"
                                options={gender}
                                defaultValue={{
                                  label:form.sex,
                                  value:form.sex,
                                }}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setForm((prev) => ({
                                    ...prev,
                                    sex: newValue ? newValue.value : "",
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={status}
                                defaultValue={status[0]}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setForm((prev) => ({
                                    ...prev,
                                    status: newValue ? newValue.value : "",
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to={'#'}
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                
                </div> 
                <div className="d-flex align-items-center bg-white border rounded-2 p-1 mb-3 me-2">
                  <Link
                    to={routes.studentList}
                    className="active btn btn-icon btn-sm me-1 primary-hover"
                  >
                    <i className="ti ti-list-tree" />
                  </Link>
                  <Link
                    to={routes.studentGrid}
                    className="btn btn-icon btn-sm bg-light primary-hover"
                  >
                    <i className="ti ti-grid-dots" />
                  </Link>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    List By Class
                  </Link>
                  <ul className="dropdown-menu p-3" style={{ height:'30vh', overflow:'auto'}}>
                    {classes.map((class_item:any)=><li>
                      <Link to="#" onClick={()=>{setForm((p:any)=>({...p, class_name:class_item.class_name }))}} className="dropdown-item rounded-1 active">
                      {class_item?.class_name||""}
                      </Link>
                    </li>)}
                    
                  </ul>
                  {/* {JSON.stringify(new_data)} */}
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
              <Table dataSource={new_data} columns={columns} Selection={true} />
              {/* /Student List */}
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <StudentModals student_id={student_id}/>
    </>
  );
};

export default StudentList;
