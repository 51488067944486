export const staffAttendance = [
  {
    id: "EA123794",
    studentName: "Janet",
    rollNo: "Roll No : 35013",
    department: "Admin",
    designation: "Technical Head",
    english: "green",
    spanish: "green",
    physics: "green",
    chemistry: "green",
    maths: "green",
    computer: "green",
    envScience: "green",
    img: "assets/img/students/student-01.jpg",
    key: 1,
  },
  {
    id: "EA123793",
    studentName: "Joann",
    rollNo: "Roll No : 35012",
    department: "Management",
    english: "green",
    spanish: "red",
    physics: "green",
    chemistry: "green",
    maths: "green",
    computer: "green",
    envScience: "green",
    designation: "Receptionist",
    img: "assets/img/students/student-02.jpg",
    key: 2,
  },
  {
    id: "EA123792",
    studentName: "Kathleen",
    rollNo: "Roll No : 35011",
    english: "green",
    spanish: "green",
    physics: "green",
    department: "Management",
    chemistry: "green",
    maths: "green",
    computer: "green",
    envScience: "green",
    designation: "Admin",
    img: "assets/img/students/student-03.jpg",
    key: 3,
  },
  {
    id: "EA123791",
    studentName: "Gifford",
    rollNo: "Roll No : 35010",
    english: "green",
    spanish: "green",
    physics: "green",
    department: "Management",
    chemistry: "green",
    maths: "blue",
    computer: "green",
    envScience: "green",
    designation: "Admin",
    img: "assets/img/students/student-04.jpg",
    key: 4,
  },
  {
    id: "EA123790",
    studentName: "Lisa",
    rollNo: "Roll No : 35009",
    english: "green",
    spanish: "green",
    physics: "blue",
    chemistry: "green",
    maths: "green",
    department: "Finance",
    computer: "green",
    envScience: "green",
    designation: "Accountant",
    img: "assets/img/students/student-05.jpg",
    key: 5,
  },
  {
    id: "EA123789",
    studentName: "Ralph",
    rollNo: "Roll No : 35008",
    english: "green",
    spanish: "green",
    physics: "green",
    chemistry: "green",
    maths: "green",
    department: "Management",
    computer: "green",
    envScience: "green",
    designation: "HR Manager",
    img: "assets/img/students/student-06.jpg",
    key: 6,
  },
  {
    id: "EA123788",
    studentName: "Julie",
    rollNo: "Roll No : 35007",
    english: "green",
    spanish: "green",
    physics: "green",
    chemistry: "green",
    maths: "green",
    department: "Library",
    computer: "green",
    envScience: "green",
    designation: "Librarian",
    img: "assets/img/students/student-07.jpg",
    key: 7,
  },
  {
    id: "EA123787",
    studentName: "Ryan",
    rollNo: "Roll No : 35006",
    english: "green",
    spanish: "red",
    physics: "green",
    chemistry: "green",
    maths: "green",
    department: "Transport",
    computer: "green",
    envScience: "green",
    designation: "Driver",
    img: "assets/img/students/student-08.jpg",
    key: 8,
  },
  {
    id: "EA123786",
    studentName: "Susan",
    rollNo: "Roll No : 35004",
    english: "green",
    spanish: "green",
    physics: "green",
    chemistry: "green",
    department: "Finance",
    maths: "green",
    computer: "red",
    envScience: "green",
    designation: "Accounts Manager",
    img: "assets/img/students/student-09.jpg",
    key: 9,
  },
  {
    id: "EA123785",
    studentName: "Richard",
    rollNo: "Roll No : 35003",
    english: "green",
    spanish: "green",
    physics: "green",
    chemistry: "green",
    department: "Management",
    maths: "green",
    computer: "green",
    envScience: "green",
    designation: "Receptionist",
    img: "assets/img/students/student-09.jpg",
    key: 10,
  },
];
