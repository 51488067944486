import React, { useCallback, useEffect, useRef, useState } from "react";
// import { StudentTable } from "../../../core/data/json/class_home_work";
import Table from "../../../core/common/dataTable/index";
import {
  classSection,
  classSylabus,
  language,
  teacher,
  weak,
} from "../../../core/common/selectoption/selectoption";

import { TableData } from "../../../core/data/interface";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import PredefinedDateRanges from "../../../core/common/datePicker";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import useQuery, { _get, _post, server_url } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import Icon from "@ant-design/icons";
import { classhomework } from "../../../core/data/json/class_home_work";

const TearcherLessons = () => {
  const routes = all_routes;
  interface Classes {
    class_name: string;
    assignment_date: string; 
    submitting_date: string;   // Similarly, Date might be better
    attachment: string;      // Or perhaps an array if handling multiple attachment
    content: string;
    subject_name: string;
  }
  
  const _form = {
    assignment_date: "",
    class_name: "",
    submitting_date: "",
    attachment: "",
    subject_name:"",
    content: "",
    query_type:'create',
    teacher:"nagudu"
  };
  
  const id = useParams().id;
  const query = useQuery();
  const lesson_id = query.get("lesson_id");
  const [new_data, setNewData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState<Classes[]>([]);
  const [file,setFile] = useState<File | null>(null);
  const navigate = useNavigate();

  const onChangeMedicalCondition = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

const [form, setForm] = useState(_form);
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    _post(
      "lessons?query_type=create",
      form,
      (res) => {
        getLesson()
        // setLoading(false);
        toast.success("Created Assignment successfully");
      },
      (err) => {
        // setLoading(false);
        console.error("Error creating Assignment:", err);
      }
    );
  };
  const getLesson = () => {
    _get(
      `lessons?query_type=select`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewData(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  
  const [subjectClasses, setSubjectClasses] = useState({
    subject: "",
    class_name: "",
    section: "",
  });
  useEffect(() => {
    // getSecScl();
    _get(
      "get_subject_management?query_type=select-subject",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubjects(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);

  const getClasses = useCallback(() => {
    _get(
      `classes?query_type=select-all`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setClasses(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  }, [form]);

  useEffect (() => {
    getClasses()
    getLesson()
  },[])
  const classOptions = classes?.length ? classes?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  })):[];
  const data = classhomework;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      )
    },

    {
      title: "Tearcher",
      dataIndex: "teacher",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
   
    {
      title: "Subject",
      dataIndex: "subject_name",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
        title: "title",
        dataIndex: "title",
        render: (text: string) => (
          <div className="d-flex align-items-center">
            {text}
          </div>
        ),
      },
    {
      title: "Content",
      dataIndex: "content",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Lesson Date",
      dataIndex: "lesson_date",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
   
    {
      title: "Attachment",
      dataIndex: "attachment",
      render: (text: string) => (
        <div className="d-flex align-items-center">
         {text?.length>10 && ( <Link to={text} className="avatar avatar-md">
          <i style={{fontSize: 20}} className="ti ti-files" />
          </Link>)}
          
        </div>
      )
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div>
            
                <Link
                  to={`${all_routes.ViewLesson}?lesson_id=${record.id}`}
                >
                  <i className="ti-eye me-2" />
                  View
                </Link>
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Teacher Lessons</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Academic </Link>
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">
                      Class Assignment
                    </li> */}
                  </ol>
                </nav>
              </div>
              <button onClick={() => navigate (all_routes.LessonForm)} className="btn btn-primary">+Add Lesson</button>
            </div>

            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                <Table
                  dataSource={new_data}
                  columns={columns}
                  Selection={true}
                />

                {/* /Guardians List */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Add Home Work */}
        {/* {JSON.stringify(new_data)} */}
        
        <div className="modal fade" id="add_home_work">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Assignment</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                {/* {JSON.stringify(form)} */}
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="mb-3">
                        <label className="form-label">Class</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class"
                          defaultValue="I"
                        />
                      </div> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">class</label>
                            <CommonSelect
                              className="select"
                              options={classOptions}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  class_name: newValue ? newValue.value : "",
                                }))
                              }
                              defaultValue={{
                                label: form.class_name,
                                value: form.class_name,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>

                            <CommonSelect
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  subject_name: newValue ? newValue.value : "",
                                }))
                              }
                              defaultValue={{
                                label: form.subject_name,
                                value: form.subject_name,
                              }}
                              options={subjects}
                            />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default TearcherLessons;
