import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import {
  Contract,
  Hostel,
  Marital,
  MaritalStatus,
  PickupPoint,
  Shift,
  VehicleNumber,
  allClass,
  allSex,
  allSubject,
  bloodGroup,
  gender,
  roomNO,
  route,
  schoolLocation,
  status,
} from "../../../../core/common/selectoption/selectoption";
import { TagsInput } from "react-tag-input-component";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { useLocation } from "react-router-dom";
import { _get, _post } from "../../../Utils/Helper";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
// import { Table } from "react-bootstrap";

const TeacherForm = () => {
  const _form = {
    name: "",
    last_name: "",
    sex: "",
    age: "",
    address: "",
    date_of_birth: "",
    marital_status: "",
    state_of_origin: "",
    mobile_number: "",
    email_address: "",
    qualification: "",
    working_experience: "",
    religion: "",
    last_place_of_work: "",
    do_you_have: "",
    when_do: "",
    account_name: "",
    account_number: "",
    bank: "",
    class_name: "",
    subject: "",
    school_location: "",
    section: "",
  };

  interface SubjectClass {
    class_name: string;
    subject: string;
    section: string;
  }

  const [form, setForm] = useState(_form);
  const [teacherClass, setTeacherClass] = useState<SubjectClass[]>([]);
  const [subjectClasses, setSubjectClasses] = useState({
    subject: "",
    class_name: "",
    section: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = () => {
    _post(
      "teacher_personal_data_form?query_type=create",
      { ...form, teacherSubjects: teacherClass },
      (res) => {
        if (res.success) {
          toast.success("Submitted successfully");
          console.log(form);
          setForm(_form);
          navigate(-1);
        }
      },
      (err) => {
        // setLoading(false);
        console.log(err);
      }
    );

    // e.preventDefault();
    // if (validate()) {
    //     // Form is valid, handle submission
    //     console.log(form);
    //     setForm(_form);
    //     setErrors({});
    // }
  };

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const [new_class_name, setNewClassName] = useState([]);
  const [classes, setClasses] = useState([]);
  const classOptions = classes?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  }));
  const getClassName = () => {
    _get(
      `get_class_management?query_type=select_class_name`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewClassName(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  const getClasses = useCallback(() => {
    _get(
      `classes?query_type=select-section-classes&section=${form.section}`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setClasses(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  }, [form.section]);

  const getSections = () => {
    _get(
      `classes?query_type=select-sections`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSections(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  const [sections, setSections] = useState([]);
  const mapped_section = sections?.map((i: any) => ({
    label: i.section,
    value: i.section,
  }));
  const [subjects, setSubjects] = useState([]);
  const [subjectClass, setsubjectClass] = useState<SubjectClass[]>([]);
  useEffect(() => {
    // getSecScl();
    _get(
      "get_subject_management?query_type=select-subject",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubjects(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);

  const handleAdd = () => {
    setTeacherClass((prev) => [
      ...prev,
      {
        ...subjectClasses,
      },
    ]);
    // handleParent();
    setSubjectClasses({ subject: "", class_name: "", section: "" });
  };
  useEffect(() => {
    getSections();
    getClassName();
  }, []);

  useEffect(() => {
    getClasses();
  }, [getClasses]);

  // const classOptions = new_class_name?.map((i: any) => ({
  //   label: i.class_name,
  //   value: i.class_name,
  // }));
  const classOptions1 = subjects?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  }));
  const routes = all_routes;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>([]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const location = useLocation();
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  useEffect(() => {
    if (location.pathname === routes.editTeacher) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${month}-${day}-${year}`;
      const defaultValue = dayjs(formattedDate);
      setIsEdit(true);
      setOwner(["English"]);
      setOwner1(["Medecine Name"]);
      setOwner2(["Allergy", "Skin Allergy"]);
      setDefaultDate(defaultValue);
      console.log(formattedDate, 11);
    } else {
      setIsEdit(false);
      setDefaultDate(null);
    }
  }, [location.pathname]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Teacher</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.teacherList}>Teacher</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {isEdit ? "Edit" : "Add"} Teacher
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form>
                <>
                  {/* Personal Information */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-info-square-rounded fs-16" />
                        </span>
                        <h4 className="text-dark">Personal Information</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {JSON.stringify(form)} */}

                      <div className="row row-cols-xxl-5 row-cols-md-6">
                        {/* <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Teacher ID</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "T849126" : undefined}
                            />
                          </div>
                        </div> */}
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              School Location
                            </label>
                            <CommonSelect
                              options={schoolLocation}
                              defaultValue={{
                                label: form.school_location,
                                value: form.school_location,
                              }}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  school_location: newValue
                                    ? newValue.value
                                    : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "Teresa" : undefined}
                              name="name"
                              value={form.name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              value={form.last_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div> */}
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Sex</label>
                            <CommonSelect
                              // handleChange={handleChange}
                              className="select"
                              options={allSex}
                              defaultValue={{
                                label: form.sex,
                                value: form.sex,
                              }}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  sex: newValue ? newValue.value : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Age</label>
                            <input
                              type="number"
                              className="form-control"
                              name="age"
                              value={form.age}
                              onChange={handleChange}
                            />
                          </div>
                        </div> */}

                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Address:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="address"
                              value={form.address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Date of Birth</label>
                            <div className="input-icon position-relative">
                              {isEdit ? (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  value={defaultDate}
                                  placeholder="Select Date"
                                />
                              ) : (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  defaultValue=""
                                  placeholder="Select Date"
                                  // name="date_of_birth"
                                  // value={form.date_of_birth}
                                  // onChange={handleChange}
                                />
                              )}

                              <span className="input-icon-addon">
                                <i className="ti ti-calendar" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Marital Status</label>
                            <CommonSelect
                              className="select"
                              options={MaritalStatus}
                              defaultValue={{
                                label: form.marital_status,
                                value: form.marital_status,
                              }}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  marital_status: newValue
                                    ? newValue.value
                                    : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              State of Origin:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "Francis Saviour" : undefined
                              }
                              name="state_of_origin"
                              value={form.state_of_origin}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mobile No:</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "Stella Bruce" : undefined}
                              name="mobile_number"
                              value={form.mobile_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email Address:</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "Stella Bruce" : undefined}
                              name="email_address"
                              value={form.email_address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Qualification</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "MBA" : undefined}
                              name="qualification"
                              value={form.qualification}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Work Experience
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "2  Years" : undefined}
                              name="working_experience"
                              value={form.working_experience}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Religion</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "Oxford Matriculation, USA" : undefined
                              }
                              name="religion"
                              value={form.religion}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Last Place of Work:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit
                                  ? "1852 Barnes Avenue, Cincinnati, OH 45202"
                                  : undefined
                              }
                              name="last_place_of_work"
                              value={form.last_place_of_work}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Do you have any health-related problem?:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "+1 35676 45556" : undefined
                              }
                              name="do_you_have"
                              value={form.do_you_have}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        {/* <div className="col-xxl-3 col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Permanent Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit
                                  ? "3495 Red Hawk Road, Buffalo Lake, MN 55314"
                                  : undefined
                              }
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-xxl-3 col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              PAN Number / ID Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "343445954908" : undefined}
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-xxl-3 col-xl-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Status</label>
                            <CommonSelect
                              className="select"
                              options={status}
                              defaultValue={isEdit ? status[0] : undefined}
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-xxl-12 col-xl-12">
                          <div className="mb-3">
                            <label className="form-label">Notes</label>
                            <textarea
                              className="form-control"
                              placeholder="Other Information"
                              rows={4}
                              defaultValue={
                                isEdit
                                  ? "Depending on the specific needs of your organization or system, additional information may be collected or tracked. Its important to ensure that any data collected complies with privacy regulations and policies to protect students sensitive information"
                                  : undefined
                              }
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* /Personal Information */}
                </>

                <>
                  {/* Payroll */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Payroll</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">EPF No</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "34234345" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Basic Salary</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "150000" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Contract Type</label>
                            <CommonSelect
                              className="select"
                              options={Contract}
                              defaultValue={isEdit ? Contract[0] : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Shift</label>
                            <CommonSelect
                              className="select"
                              options={Shift}
                              defaultValue={isEdit ? Shift[0] : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Location</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "2nd Floor" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Date of Leaving
                            </label>
                            <div className="input-icon position-relative">
                              {isEdit ? (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  value={defaultDate}
                                  placeholder="Select Date"
                                />
                              ) : (
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  defaultValue=""
                                  placeholder="Select Date"
                                />
                              )}
                              <span className="input-icon-addon">
                                <i className="ti ti-calendar" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Payroll */}
                  {/* Leaves */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-dark">Leaves</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Medical Leaves</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "01" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Casual Leaves</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "02" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Maternity Leaves
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "20" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Sick Leaves</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "02" : undefined}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Leaves */}
                  {/* Bank Details */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-map fs-16" />
                        </span>
                        <h4 className="text-dark">Bank Account Detail</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "Teresa" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Number</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "0126784900" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Bank Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "Bank of America" : undefined
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">IFSC Code</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "BOA83209832" : undefined}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Branch Name</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={isEdit ? "Cincinnati" : undefined}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Bank Details */}
                </>

                {/* Transport Information */}
                {/* <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-bus-stop fs-16" />
                      </span>
                      <h4 className="text-dark">Transport Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Route</label>
                          <CommonSelect
                            className="select"
                            options={route}
                            defaultValue={isEdit ? route[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Vehicle Number</label>
                          <CommonSelect
                            className="select"
                            options={VehicleNumber}
                            defaultValue={isEdit ? VehicleNumber[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Pickup Point</label>
                          <CommonSelect
                            className="select"
                            options={PickupPoint}
                            defaultValue={isEdit ? PickupPoint[0] : undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Transport Information */}
                {/* Hostel Information */}
                {/* <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-fortress fs-16" />
                      </span>
                      <h4 className="text-dark">Hostel Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Hostel</label>
                          <CommonSelect
                            className="select"
                            options={Hostel}
                            defaultValue={isEdit ? Hostel[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Room No</label>
                          <CommonSelect
                            className="select"
                            options={roomNO}
                            defaultValue={isEdit ? roomNO[0] : undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Hostel Information */}
                <>
                  {/* Social Media Links */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building fs-16" />
                        </span>
                        <h4 className="text-dark">Social Media Links</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row rows-cols-xxl-5">
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Facebook</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "www.facebook.com" : undefined
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Instagram</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "www.instagram.com" : undefined
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Linked In</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "www.Linkedin.com" : undefined
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Youtube</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "www.youtube.com" : undefined
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Twitter URL</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                isEdit ? "www.twitter.com" : undefined
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Social Media Links */}
                  {/* Documents */}
                  {/* <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Documents</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Resume
                              </label>
                              <p>
                                Upload image size of 4MB, Accepted Format PDF
                              </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                <i className="ti ti-file-upload me-1" />
                                Change
                                <input
                                  type="file"
                                  className="form-control image_sign"
                                  multiple
                                />
                              </div>
                              <p className="mb-2">Resume.pdf</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Upload Joining Letter
                              </label>
                              <p>
                                Upload image size of 4MB, Accepted Format PDF
                              </p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                <i className="ti ti-file-upload me-1" />
                                Upload Document
                                <input
                                  type="file"
                                  className="form-control image_sign"
                                  multiple
                                />
                              </div>
                              <p className="mb-2">Resume.pdf</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* /Documents */}
                  {/* Password */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Account Details</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Name:</label>
                            <input
                              // type="password"
                              className="form-control"
                              type="text"
                              name="account_name"
                              value={form.account_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Account Number:
                            </label>
                            <input
                              // type="password"
                              className="form-control"
                              type="number"
                              name="account_number"
                              value={form.account_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Bank:</label>
                            <input
                              // type="password"
                              className="form-control"
                              type="text"
                              name="bank"
                              value={form.bank}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Class And Subject</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <table className="table datanew">
                          <thead className="thead-light">
                            <tr>
                              <th>Section</th>
                              <th>Subject</th>
                              <th>Subject</th>
                              <th className="text-center"> + </th>
                            </tr>
                          </thead>
                          <tbody>
                            {teacherClass.length
                              ? teacherClass.map((x, idx) => (
                                  <tr key={idx}>
                                    <td>{x.section}</td>
                                    <td>{x.class_name}</td>
                                    <td>{x.subject}</td>
                                  </tr>
                                ))
                              : null}
                            <tr>
                              <td>
                                {/* <label className="form-label">Section</label> */}
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <CommonSelect
                                      className="select"
                                      options={mapped_section}
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setForm((prev) => ({
                                          ...prev,
                                          section: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <CommonSelect
                                      className="select"
                                      options={classOptions}
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setSubjectClasses((prev) => ({
                                          ...prev,
                                          class_name: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                      defaultValue={{
                                        label: subjectClasses.class_name,
                                        value: subjectClasses.class_name,
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <CommonSelect
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setSubjectClasses((prev) => ({
                                          ...prev,
                                          subject: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                      defaultValue={{
                                        label: subjectClasses.subject,
                                        value: subjectClasses.subject,
                                      }}
                                      options={subjects}
                                    />
                                  </div>
                                </div>
                              </td>

                              <td className="text-center">
                                {" "}
                                {subjectClasses.class_name &&
                                subjectClasses.subject ? (
                                  <Button
                                    type="primary"
                                    onClick={handleAdd}
                                    style={{ marginTop: 2 }}
                                  >
                                    Add +
                                  </Button>
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* /Password */}
                </>

                <div className="text-end">
                  <button type="button" className="btn btn-light me-3">
                    Cancel
                  </button>
                  <Link
                    to="#"
                    className="btn btn-primary me-3"
                    //  to={routes.teacherList}
                    onClick={() => handleSubmit()}
                  >
                    Add Teacher
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default TeacherForm;
