export const transportVehicles = [
  {
    key: "1",
    id: "B80482",
    vehicleNo: "8930",
    vehicleModel: "Scania",
    img: "assets/img/parents/parent-01.jpg",
    madeofYear: "2021",
    registrationNo: "US1A3545",
    chassisNo: "32546665456",
    gps: "GPS7899456689",

    name: "Thomas",
    phone: "+1 64044 748904",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "2",
    id: "B80481",
    vehicleNo: "1235",
    vehicleModel: "Mini Bus",
    img: "assets/img/parents/parent-10.jpg",
    madeofYear: "2024",
    registrationNo: "US2B5465",
    chassisNo: "12345678901",
    gps: "GPS4579454785",

    name: "Mary",
    phone: "+1 14541 55665",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "3",
    id: "B80482",
    vehicleNo: "6465",
    vehicleModel: "Mini Bus",
    img: "assets/img/parents/parent-09.jpg",
    madeofYear: "2017",
    registrationNo: "US3C4547",
    chassisNo: "22124413454",
    gps: "GPS3254789541",

    name: "Michael",
    phone: "+1 78954 85461",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "4",
    id: "B80481",
    vehicleNo: "7895",
    vehicleModel: "Kinsmart",
    img: "assets/img/parents/parent-08.jpg",
    madeofYear: "2022",
    registrationNo: "US4D1234",
    chassisNo: "12516665456",
    gps: "GPS1478545214",

    name: "Jessie",
    phone: "+1 12345 68891",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "5",
    id: "B80482",
    vehicleNo: "4625",
    vehicleModel: "Kinsmart",
    img: "assets/img/parents/parent-07.jpg",
    madeofYear: "2019",
    registrationNo: "US1A6547",
    chassisNo: "22746675177",
    gps: "GPS6985478541",

    name: "Robert",
    phone: "+1 78454 78841",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "6",
    id: "B80481",
    vehicleNo: "7854",
    vehicleModel: "Single deck",
    img: "assets/img/parents/parent-06.jpg",
    madeofYear: "2015",
    registrationNo: "US2B5977",
    chassisNo: "32546665456",
    gps: "GPS3254145874",

    name: "Colleen",
    phone: "+1 78546 97894",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "7",
    id: "B80482",
    vehicleNo: "9789",
    vehicleModel: "Kinsmart",
    img: "assets/img/parents/parent-05.jpg",
    madeofYear: "2024",
    registrationNo: "US1A3147",
    chassisNo: "16546647421",
    gps: "GPS4854651254",

    name: "Arthur",
    phone: "+1 97878 87854",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "8",
    id: "B80481",
    vehicleNo: "4569",
    vehicleModel: "Mini Bus",
    img: "assets/img/parents/parent-04.jpg",
    madeofYear: "2016",
    registrationNo: "US2B5789",
    chassisNo: "75546785147",
    gps: "GPS9658745214",

    name: "Claudia",
    phone: "+1 64599 78542",
    status: "Inactive",
    statusClass: "badge badge-soft-danger",
  },
  {
    key: "9",
    id: "B80482",
    vehicleNo: "7857",
    vehicleModel: "Mini Bus",
    img: "assets/img/parents/parent-03.jpg",
    madeofYear: "2018",
    registrationNo: "US1A3147",
    chassisNo: "32546647992",
    gps: "GPS7854652145",

    name: "Johnson",
    phone: "+1 45781 45145",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "10",
    id: "B80481",
    vehicleNo: "6879",
    vehicleModel: "Single deck",
    img: "assets/img/parents/parent-02.jpg",
    madeofYear: "2023",
    registrationNo: "US2B5244",
    chassisNo: "14578665456",
    gps: "GPS9985231258",

    name: "Marquita",
    phone: "+1 45112 48879",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
];
