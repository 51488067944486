import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { _get } from "../../Utils/Helper";
import { Spinner } from "react-bootstrap";
import AttendanceBadges from "./AttendanceBadges";

// Define the props interface for the component
interface StudentAttendanceChartProps {
  student_id: number;
}

const StudeentAtendanceChart: React.FC<StudentAttendanceChartProps> = ({ student_id }) => {
  const [studentAttendence, setStudentAttendence] = useState<any[]>([]);

  const [attendance_chart, setAttendanceChart] = useState<any>({
    chart: {
      height: 255,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [], // Empty array to be filled dynamically
    labels: ["Present", "Late", "Half Day", "Absent"],
    colors: ["#1ABE17", "#1170E4", "#E9EDF4", "#E82646"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  });

  useEffect(() => {
    const fetchClassAttendances = () => {
      if (student_id) {
        _get(
          `students/attendance?query_type=select-student&student_id=${student_id}`,
          (res) => {
            if (res.success && res.data) {
              setStudentAttendence(res.data);
              // Dynamically update the chart series based on the response data
              const present = res.data.filter((att: any) => att.status === "Present").length;
              const late = res.data.filter((att: any) => att.status === "Late").length;
              const halfDay = res.data.filter((att: any) => att.status === "Half Day").length;
              const absent = res.data.filter((att: any) => att.status === "Absent").length;

              // Update the chart's series dynamically
              setAttendanceChart((prevChart: any) => ({
                ...prevChart,
                series: [present, late, halfDay, absent],
              }));
            }
          },
          (err) => console.error(err)
        );
      }
    };
    fetchClassAttendances();
  }, [student_id]);

  return !studentAttendence.length && !student_id? 
  <Spinner />
  :
  (
    <div className="card-body">
      <div className="attendance-chart">
        <p className="mb-3">
          <i className="ti ti-calendar-heart text-primary me-2" />
          No of activities <span className="fw-medium text-dark">{studentAttendence.length} Days</span>
        </p>
        <div className="border rounded p-3">
          <div className="row">
            <div className="col text-center border-end">
              <p className="mb-1">Present</p>
              <h5>{studentAttendence.filter((att: any) => att.status === "Present").length}</h5>
            </div>
            <div className="col text-center border-end">
              <p className="mb-1">Absent</p>
              <h5>{studentAttendence.filter((att: any) => att.status === "Absent").length}</h5>
            </div>
            <div className="col text-center">
              <p className="mb-1">Halfday</p>
              <h5>{studentAttendence.filter((att: any) => att.status === "Half Day").length}</h5>
            </div>
          </div>
        </div>
        <div className="text-center">
          <ReactApexChart
            options={attendance_chart}
            series={attendance_chart.series}
            type="donut"
            height={255}
          />
        </div>
        <AttendanceBadges data={studentAttendence} />
      </div>
    </div>
  );
};

export default StudeentAtendanceChart;
