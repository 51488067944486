import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../studentModals";
import StudentSidebar from "./studentSidebar";
import StudentBreadcrumb from "./studentBreadcrumb";
import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";
import { leaveData } from "../../../../core/data/json/leaveData";
import { Attendance } from "../../../../core/data/json/attendance";
import useQuery, { _get, _post } from "../../../Utils/Helper";
import { Button } from "antd";
import { transactionDate } from "../../../../core/common/selectoption/selectoption";

const StudentFees = () => {
  const navigate = useNavigate();
  const q = useQuery();
  const student_id = q.get('student_id')
  const routes = all_routes;
  const admin_no = useQuery().get("admin_no");
  const application_no = useQuery().get("application_no");
  const [student, setStudent] = useState({})

  const [payments, setPayments] = useState([])

  useEffect(() => {
    _get(
      `students?id=${student_id}`,
      (res) => {
        setStudent(res.data[0]);
      },
      (err) => {
        console.log(err);
      }
    );
  },[]);

  useEffect(()=>{
    _post(`revenue-heads?query_type=select-all`,
      {
        query_type:'select-all' 
      },
      (resp)=>{
        setPayments(resp.data)
      },
      (err)=>{
          console.error()
      }
    )
  },[0])

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {/* Page Header */}
            {JSON.stringify(student,payments)}
            <StudentBreadcrumb />
            {/* /Page Header */}
          </div>
          <div className="row">
            {/* Student Information */}
            <StudentSidebar />
            {/* /Student Information */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  {/* List */}
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link
                        to={`${routes.studentDetail}?admin_no=${admin_no}`}
                        className="nav-link"
                      >
                        <i className="ti ti-school me-2" />
                        Student Details!
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${routes.studentTimeTable}?admin_no=${admin_no}`}
                        className="nav-link"
                      >
                        <i className="ti ti-table-options me-2" />
                        Time Table
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${routes.studentLeaves}?admin_no=${admin_no}`}
                        className="nav-link "
                      >
                        <i className="ti ti-calendar-due me-2" />
                        Leave &amp; Attendance
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${routes.studentFees}?admin_no=${admin_no}`}
                        className="nav-link active"
                      >
                        <i className="ti ti-report-money me-2" />
                        Fees
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${routes.studentResult}?admin_no=${admin_no}`}
                        className="nav-link"
                      >
                        <i className="ti ti-bookmark-edit me-2" />
                        Exam &amp; Results
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${routes.studentLibrary}?admin_no=${admin_no}`}
                        className="nav-link"
                      >
                        <i className="ti ti-books me-2" />
                        Library
                      </Link>
                    </li>
                  </ul>
                  {/* /List */}
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                      <h4 className="mb-3">Fees</h4>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="dropdown mb-3 me-2">
                          <Link
                            to=""
                            className="btn btn-outline-light bg-white dropdown-toggle"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-calendar-due me-2" />
                            Year : 2024 / 2025
                          </Link>
                          <ul className="dropdown-menu p-3">
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Year : 2024 / 2025
                              </Link>
                            </li>
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Year : 2023 / 2024
                              </Link>
                            </li>
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Year : 2022 / 2023
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 py-3">
                      {/* Fees List */}
                      <div className="custom-datatable-filter table-responsive">
                        <table className="table datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>Description</th>
                              <th>Due Date</th>
                              <th>Amount (NGN) </th>
                              <th>Status</th>
                              <th>Ref ID</th>
                              <th>Mode</th>
                              <th>Date Paid</th>
                              <th>Discount (NGN)</th>
                              <th>Fine (NGN)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments?.map((payment:any, index:number)=><tr key={index}>
                              <td>
                                <p className="text-primary fees-group">
                                  <span className="d-block">
                                    {payment?.description}
                                  </span>
                                </p>
                              </td>
                              <td>{payment?.due_date}</td>
                              <td>{payment?.amount}</td>
                              <td>
                                <span className="badge badge-soft-success d-inline-flex align-items-center">
                                  <i className="ti ti-circle-filled fs-5 me-1" />
                                  {payment?.status}
                                </span>
                              </td>
                              <td>{payment?.status}</td>
                              <td>{payment?.method}</td>
                              <td>{payment?.created_at}</td>
                              <td>0%</td>
                              <td>0</td>
                            </tr>)}
                          </tbody>
                        </table>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 5,
                        }}
                      >
                        <Button
                          onClick={() =>
                            navigate(
                              `/examsscore?application_no=${application_no}`
                            )
                          }
                        >
                          Pay
                        </Button>
                      </div>
                      {/* /Fees List */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <StudentModals />
    </>
  );
};

export default StudentFees;
