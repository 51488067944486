import React, { useEffect, useRef, useState } from "react";
// import { Subjects } from "../../../core/data/json/class-subject";
import Table from "../../../core/common/dataTable/index";

import {
  count,
  language,
  sections,
  typetheory,
} from "../../../core/common/selectoption/selectoption";
import PredefinedDateRanges from "../../../core/common/datePicker";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link, useNavigate } from "react-router-dom";
import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../../router/all_routes";
import { _get, _post } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";

const Subjects = () => {
  const _form = {
    status: "Active",
    subject: "",
    section: "",
    query_type: "create",
  };
  const [form, setForm] = useState(_form);
  const [sections, setSections] = useState([]);
  const navigate = useNavigate();
  const [new_data, setNewData] = useState([]);
  const [new_class_name, setNewClassName] = useState([]);

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const getTeaherPersonal = () => {
    const _form = {
      status: "Active",
      subject: "",
      section: "",
    };

    _get(
      "subjects?query_type=select-all",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewData(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  };

  const getClassName = () => {
    _get(
      `get_class_management?query_type=select_class_name`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewClassName(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  const getSections = () => {
    _get(
      `classes?query_type=select-sections`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSections(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  useEffect(() => {
    getSections();
    getTeaherPersonal();
    getClassName();
  }, []);

  const routes = all_routes;
  const mapped_section = sections?.map((i: any) => ({
    label: i.section,
    value: i.section,
  }));
  const data = Subjects;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   render: (text: string, record: any, index: number) => (
    //     <>
    //       <Link to="#" className="link-primary">
    //         {record.id}
    //       </Link>
    //     </>
    //   ),
    //   // sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    // },

    // {
    //   title: "Class",
    //   dataIndex: "select_class",
    //   // sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    // },
    {
      title: "Subject Name",
      dataIndex: "subject",
      // sorter: (a: TableData, b: TableData) => a.code.length - b.code.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      // sorter: (a: TableData, b: TableData) => a.type.length - b.type.length,
    },
    // {
    //   title: "Assignment Pass Mark",
    //   dataIndex: "assignment_pass_mark",
    //   // sorter: (a: TableData, b: TableData) => a.type.length - b.type.length,
    // },
    // {
    //   title: "Exam Pass Mark",
    //   dataIndex: "exam_pass_mark",
    //   // sorter: (a: TableData, b: TableData) => a.type.length - b.type.length,
    // },
    // {
    //   title: "Other Description",
    //   dataIndex: "other_description",
    //   // sorter: (a: TableData, b: TableData) => a.type.length - b.type.length,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text: string) => (
    //     <>
    //       {text === "Active" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   // sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    // },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: () => (
    //     <>
    //       <div className="d-flex align-items-center">
    //         <div className="dropdown">
    //           <Link
    //             to="#"
    //             className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="ti ti-dots-vertical fs-14" />
    //           </Link>
    //           <ul className="dropdown-menu dropdown-menu-right p-3">
    //             <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#edit_subject"
    //               >
    //                 <i className="ti ti-edit-circle me-2" />
    //                 Edit
    //               </Link>
    //             </li>
    //             <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#delete-modal"
    //               >
    //                 <i className="ti ti-trash-x me-2" />
    //                 Delete
    //               </Link>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  const handleSubmit = () => {
    _post(
      "subjects",
      form,
      (res) => {
        if (res.success) {
          toast.success("Creating Subject successfully");
          getTeaherPersonal();
        } else {
          console.log("Submission failed", res); // Handle failure cases
        }
      },
      (err) => {
        console.error("Error:", err); // Error handling
      }
    );
  };
  const classOptions = new_class_name?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  }));
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Subjects</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Academic </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Subjects
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <TooltipOption />
                <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_subject"
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Add Subject
                  </Link>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Class Subject</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div
                      className="dropdown-menu drop-width"
                      ref={dropdownMenuRef}
                    >
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom pb-0">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Name</label>
                                <CommonSelect
                                  className="select"
                                  options={language}
                                  defaultValue={language[0]}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Code</label>
                                <CommonSelect
                                  className="select"
                                  options={count}
                                  defaultValue={count[0]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    {/* {JSON.stringify(new_data)} */}
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                {/* <Table
                  columns={new_data}
                  dataSource={columns}
                  Selection={true}
                /> */}
                <Table
                  dataSource={new_data}
                  columns={columns}
                  Selection={true}
                />

                {/* /Guardians List */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
      <div>
        {/* Add Subject */}

        <div className="modal fade" id="add_subject">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Subject</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="mb-3">
                        <label className="form-label">Select Class</label>
                        <input
                          type="text"
                          className="form-control"
                          name="select_class"
                          value={form.select_class}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* {JSON.stringify(new_class_name)} */}
                      <div className="mb-3">
                        <label className="form-label">Subject Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          value={form.subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={mapped_section}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              section: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>

                      {/* <div className="mb-3">
                        <label className="form-label">C/A Pass Mark</label>
                        <input
                          type="number"
                          className="form-control"
                          name="c_a_pass_mark"
                          value={form.c_a_pass_mark}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* <div className="mb-3">
                        <label className="form-label">
                          Assignment Pass Mark
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="assignment_pass_mark"
                          value={form.assignment_pass_mark}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Exam Pass Mark </label>
                        <input
                          type="number"
                          className="form-control"
                          name="exam_pass_mark"
                          value={form.exam_pass_mark}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Other Description</label>
                        <input
                          type="text"
                          className="form-control"
                          name="other_description"
                          value={form.other_description}
                          onChange={handleChange}
                        />
                      </div> */}

                      {/* <div className="mb-3">
                        <label className="form-label">Subject Name</label>
                        <CommonSelect
                          className="select"
                          options={count}
                          defaultValue={count[0]}
                        />
                      </div> */}
                      {/* <div className="mb-3">
                        <label className="form-label">Type</label>
                        <CommonSelect
                          className="select"
                          options={typetheory}
                          defaultValue={typetheory[0]}
                        />
                      </div> */}
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Add Subject
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Subject */}
        {/* Edit Subject */}
        <div className="modal fade" id="edit_subject">
          <div className="modal-dialog modal-dialog-centere">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Subject</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          defaultValue="English"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Code</label>
                        <CommonSelect
                          className="select"
                          options={count}
                          defaultValue={count[0]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Type</label>
                        <CommonSelect
                          className="select"
                          options={typetheory}
                          defaultValue={typetheory[0]}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Subject */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </div>
    </div>
  );
};

export default Subjects;
