import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import dayjs from 'dayjs';
import { OverlayTrigger, Table } from "react-bootstrap";
import { Tooltip } from "antd";
import useQuery, { _get, _post } from "../../../Utils/Helper";
import { all_routes } from "../../../router/all_routes";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import moment from "moment";
// import CustomDateRanges from "../../../../core/common/custom-date-ranges";

// Define interfaces for better type safety
interface Class {
    id: number;
    class_name: string;
}

interface Section {
    id: number;
    section: string;
}
interface Exam {
  exam_id: number;
  exam_name: string;
  subject: string;
  class_name: string;
}

const ExamAttendance: React.FC = () => {
  const query = useQuery() 
  const subject = query.get('subject') ||"" 
  const class_name = query.get('class_name') ||"" 
  const exam_id = query.get('exam_id') ||"" 

    const initialForm = {
        query_type: "",
        teacher_name: "Ishaq",
        teacher_id: "1",
        class_name,
        day: "",
        status: "",
        student_name: "",
        admission_no: "",
        term: "",
        academic_year: "",
        start_date: "",
        end_date: "",
        subject,
        exam_id,
        exam_name: "",
    };

    const [form, setForm] = useState(initialForm);
    const [sections, setSections] = useState<Section[]>([]);
    const [classes, setClasses] = useState<Class[]>([]);
    const [dates, setDates] = useState({
        startDate: dayjs().subtract(29, 'day'),
        endDate: dayjs(),
    });
    const navigate = useNavigate();
    const isFetching = useRef(false); // Prevent redundant fetches

    const [exams, setExams] = useState<Exam[]>([])
    const examOptions: Option[] = exams.map((examItem) => ({
      label: examItem.subject + '-'+ examItem.class_name,
      value:  examItem.subject + '-' +examItem.class_name,
    }));

    const handleChangeDates = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
        setDates({ startDate: start, endDate: end });
    };

    const sectionsOptions: Option[] = sections.map((section) => ({
        label: section.section,
        value: section.section,
    }));

    const classesOptions: Option[] = classes.map((classItem) => ({
        label: classItem.class_name,
        value: classItem.class_name,
    }));

    useEffect(() => {
        const fetchClasses = () => {
            _get(
                `classes?query_type=select-all`,
                (res) => setClasses(res.data),
                (err) => console.error(err)
            );
        };

        const fetchSections = () => {
            _get(
                `classes?query_type=select-sections`,
                (res) => setSections(res.data),
                (err) => console.error(err)
            );
        };
        const getExams = () => {
          _get(
            `get_exammination?query_type=select`,
            (res) => {
              console.log(res);
              if (res.results && res.results.length) {
                setExams(
                  res.results?.map((s: any) => ({
                    ...s,
                    start_time: moment(s.start_time, "HH:mm:ss").format("HH:mm:ssA"),
                    end_time: moment(s.end_time, "HH:mm:ss").format("HH:mm:ssA"),
                  }))
                );
              }
            },
            (err) => {
              // setLoading(false)
              console.log(err);
            }
          );
          // console.log(form)
        };
      
        getExams()
        fetchClasses();
        fetchSections();
    }, []);

    useEffect(() => {
        if (form.class_name && dates.startDate && dates.endDate && !isFetching.current) {
            isFetching.current = true; // Set fetching flag to true
            _post(
                'exams/attendance',
                {
                    query_type: "select-class",
                    class_name: form.class_name,
                    start_date: dates.startDate.format('YYYY-MM-DD'),
                    end_date: dates.endDate.format('YYYY-MM-DD')
                },
                (response) => {
                    const records: AttendanceRecord[] = response.data.flat();
                    const datesData = Array.from(new Set(records.map(record => record.created_at)));
                    setUniqueDates(datesData);

                    const groupedData = records.reduce((acc, record) => {
                        if (!acc[record.admission_no]) acc[record.admission_no] = [];
                        acc[record.admission_no].push(record);
                        return acc;
                    }, {} as { [admissionNo: string]: AttendanceRecord[] });

                    setStudentMap(groupedData);
                    isFetching.current = false; // Reset fetching flag
                },
                (err) => {
                    isFetching.current = false; // Reset fetching flag
                    console.error(err);
                }
            );
        }
    }, [form.class_name, dates.startDate, dates.endDate]); // Removed 'loading' from dependencies

    type AttendanceRecord = {
        admission_no: string;
        student_name: string;
        created_at: string;
        status: 'Present' | 'Absent' | 'Late' | 'Holiday' | 'Halfday';
        notes: string | null;
    };

    interface AttendanceGridProps {
        uniqueDates: string[];
        studentMap: { [admissionNo: string]: AttendanceRecord[] };
    }

    const [uniqueDates, setUniqueDates] = useState<string[]>([]);
    const [studentMap, setStudentMap] = useState<{ [admissionNo: string]: AttendanceRecord[] }>({});

    const getStatusColor = (status: AttendanceRecord['status']) => {
        switch (status) {
            case 'Present': return '#4CAF50';
            case 'Absent': return '#F44336';
            case 'Late': return '#FF9800';
            case 'Holiday': return '#2196F3';
            case 'Halfday': return '#FFC107';
            default: return '#E0E0E0';
        }
    };

    const AttendanceGrid: React.FC<AttendanceGridProps> = ({ uniqueDates, studentMap }) => {
        return (
            <div style={{ overflowX: 'auto' }}>
                <Table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '10%' }}>Admission No</th>
                            <th style={{ width: '10%' }}>Student Name</th>
                            <th>Attendance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(studentMap).map((admissionNo) => {
                            const studentRecords = studentMap[admissionNo];
                            const studentName = studentRecords[0]?.student_name || 'Unknown';

                            return (
                                <tr key={admissionNo}>
                                    <td>{admissionNo}</td>
                                    <td>{studentName}</td>
                                    {uniqueDates.map((date) => {
                                        const recordsForDate = studentRecords.filter((rec) => rec.created_at === date);

                                        return (
                                            <td key={date} style={{
                                                backgroundColor: '#f5f5f5',
                                                width: 'auto',
                                                textAlign: 'center',
                                                padding: '2px',
                                                border: '1px solid #ddd',
                                            }}>
                                                <div>
                                                    {recordsForDate.map((record, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                backgroundColor: getStatusColor(record.status),
                                                                width: '20px',
                                                                height: '20px',
                                                                display: 'inline-block',
                                                                textAlign: 'center',
                                                                color: '#fff',
                                                            }}
                                                            title={`Date: ${record.created_at}, Status: ${record.status}`}
                                                        >
                                                            {record.status.charAt(0)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    };

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                    <div className="my-auto mb-2">
                        <h3 className="page-title mb-1">Exam Attendance</h3>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="#">Exams</Link>
                                </li>
                                <li className="breadcrumb-item active">Exam Attendance</li>
                            </ol>
                        </nav>

                    </div>
                    <div className="align-items-center  justify-content-between  mb-2">
                        <button
                            type="button"
                            className="btn btn-outline-light bg-white me-5"
                            onClick={()=>navigate(`${all_routes.newExamAttendance}?class_name=${form.class_name}&subject=${form.subject}&exam_id=${form.exam_id}`)}
                        >
                        + Add Attendance
                        </button>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                        <h4 className="mb-3">Exam by Class</h4>
                        <div className="d-flex align-items-center flex-wrap row">
                            {/* <div className="col-md-3">
                                <label className="form-label">Section</label>
                                <CommonSelect
                                    className="select"
                                    options={sectionsOptions}
                                    handleChange={(newValue: SingleValue<Option>) =>
                                        setForm((prev) => ({
                                            ...prev,
                                            section: newValue ? newValue.value : "",
                                        }))
                                    }
                                />
                            </div> */}
                            
                            <div className="col-md-6">
                                <label className="form-label">Class</label>
                                <CommonSelect
                                    className="select"
                                    options={classesOptions}
                                    defaultValue={{
                                      label: form.class_name||"",
                                      value: form.class_name||"",
                                    }}
                                    handleChange={(newValue: SingleValue<Option>) =>
                                        setForm((prev) => ({
                                            ...prev,
                                            class_name: newValue ? newValue.value : "",
                                        }))
                                    }
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Select Exam</label>
                                
                <CommonSelect
                    className="select"
                    options={examOptions}
                    defaultValue={
                      {
                        label: form.exam_name,
                        value: form.exam_name,
                      }
                    }
                    handleChange={(newValue: SingleValue<Option>) =>
                      setForm((prev) => ({
                        ...prev,
                        exam_name: newValue ? newValue.value : "",
                        class_name: newValue ? newValue.value.split('-')[1] : "",
                      }))
                    }
                  />
                            </div>
                        </div>
                    </div>
                    <AttendanceGrid uniqueDates={uniqueDates} studentMap={studentMap} />
                </div>
            </div>
        </div>
    );
};

export default ExamAttendance;
