import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { _get } from "../../Utils/Helper";
import CustomDateRanges from "../../../core/common/custom-date-ranges";
import dayjs from "dayjs";
import { Input, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import CommonSelect from "../../../core/common/commonSelect";
import TooltipOption from "../../../core/common/tooltipOption";
import PredefinedDateRanges from "../../../core/common/datePicker";
import { activeList, classSylabus } from "../../../core/common/selectoption/selectoption";

const ViewStudentAttendance: React.FC = () => {
	const [dates, setDates] = useState({
		startDate: dayjs().subtract(29, "day"),
		endDate: dayjs(),
	});
	const [studentAttendence, setStudentAttendence] = useState([]);

	const { user } = useSelector((state: RootState) => state.auth);
	const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
	const handleApplyClick = () => {
	  if (dropdownMenuRef.current) {
		dropdownMenuRef.current.classList.remove("show");
	  }
	};
	const handleChangeDates = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
		setDates({ startDate: start, endDate: end });
	};

	useEffect(() => {
		const fetchClassAttendances = () => {
			if(user.admission_no){_get(
				`students/attendance?query_type=select-student&admission_no=${user.admission_no}&start_date=${dates.startDate.format(
					"YYYY-MM-DD"
				)}&end_date=${dates.endDate.format("YYYY-MM-DD")}`,
				(res) => {
					if(res.success && res.data)
					setStudentAttendence(res.data)},
				(err) => console.error(err)
			);
		}
		};
		fetchClassAttendances();
	}, [dates,user.admission_no]);

	// Attendance record type
	type AttendanceRecord = {
		admission_no: string;
		student_name: string;
		created_at: string;
		status: "Present" | "Absent" | "Late" | "Holiday" | "Halfday";
		notes: string | null;
	};



	// Color coding for attendance status
	const getStatusColor = (status: AttendanceRecord["status"]) => {
		switch (status) {
			case "Present":
				return "#4CAF50";
			case "Absent":
				return "#F44336";
			case "Late":
				return "#FF9800";
			case "Holiday":
				return "#2196F3";
			case "Halfday":
				return "#FFC107";
			default:
				return "#E0E0E0";
		}
	};

	// Define columns for Ant Design Table
	const columns = [
		{
			title: "Date",
			dataIndex: "created_at",
			key: "created_at",
			render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
		},
		{
			title: "Student Name",
			dataIndex: "student_name",
			key: "student_name",
		},
		{
			title: "Admission No",
			dataIndex: "admission_no",
			key: "admission_no",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status: AttendanceRecord["status"]) => (
				<span style={{ color: getStatusColor(status), fontWeight: "bold" }}>
					{status}
				</span>
			),
		},
		{
			title: "Notes",
			dataIndex: "notes",
			key: "notes",
		},
	];

	interface WeekGroup {
		weekStart: dayjs.Dayjs;
		days: (AttendanceRecord | null)[];
	  }
	  
	  const groupAttendanceByWeek = (records: AttendanceRecord[]): WeekGroup[] => {
		const weeks: Record<string, WeekGroup> = {}; // Use an object to group by week start
	  
		// Sort records by created_at to process in chronological order
		records.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
	  
		records.forEach((record) => {
		  const date = dayjs(record.created_at);
		  const startOfWeek = date.startOf('week').add(1, 'day'); // Adjust to start from Monday
		  const weekIndex = startOfWeek.format('YYYY-MM-DD'); // Use Monday as the key for grouping
	  
		  // If the week doesn't exist, create a new entry
		  if (!weeks[weekIndex]) {
			weeks[weekIndex] = {
			  weekStart: startOfWeek,
			  days: Array(5).fill(null), // Initialize an array for Monday-Friday
			};
		  }
	  
		  const dayIndex = date.day() - 1; // Monday = 0, Tuesday = 1, ..., Friday = 4
		  if (dayIndex >= 0 && dayIndex <= 4) {
			weeks[weekIndex].days[dayIndex] = record; // Map record to corresponding day
		  }
		});
	  
		// Convert object to array to preserve chronological order
		return Object.values(weeks);
	  };
	  

	const groupedAttendance = groupAttendanceByWeek(studentAttendence);
	// const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

	const AttendanceGrid = ({ groupedAttendance }: { groupedAttendance: WeekGroup[] }) => {
		return (
		  <div
			className="card"
			style={{
			  display: "grid",
			  gridTemplateColumns: "repeat(5, 1fr)", // 5 columns for Monday-Friday
			  gap: "10px",
			  textAlign: "center",
			}}
		  >
			{/* Days of the week header */}
			{daysOfWeek.map((day) => (
			  <div
				key={day}
				className="calendar-header"
				style={{
				  fontWeight: "bold",
				  padding: "10px",
				  backgroundColor: "#f8f9fa",
				  border: "1px solid #dee2e6",
				}}
			  >
				{day}
			  </div>
			))}
	  
			{/* Attendance data */}
			{groupedAttendance.map((week, weekIndex) => (
			  <React.Fragment key={`week-${weekIndex}`}>
				{week.days.map((record, dayIndex) => (
				  <div
					key={`day-${weekIndex}-${dayIndex}`}
					className="calendar-box"
					style={{
					  backgroundColor:
						record?.status === "Present"
						  ? "#28a745"
						  : record?.status === "Absent"
						  ? "#dc3545"
						  : record?.status === "Late"
						  ? "#ffc107"
						  : "#6c757d",
					  color: "#fff",
					  padding: "10px",
					  borderRadius: "5px",
					  minHeight: "50px",
					  display: "flex",
					  justifyContent: "center",
					  alignItems: "center",
					  border: "1px solid #dee2e6",
					}}
				  >
					{record ? (
					  <div>
						<div>{dayjs(record.created_at).format("MMM DD")}</div>
						<div>{record.status}</div>
					  </div>
					) : (
					  "-" // Placeholder for days with no attendance
					)}
				  </div>
				))}
			  </React.Fragment>
			))}
		  </div>
		);
	  };
	
	return (
		<div
			className="card"
			
		>
			 <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Attendances</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Student </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Attendances
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3"><h5>NAME: {user.student_name?.toUpperCase()} - {user.admission_no}</h5></h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                                defaultValue={classSylabus[0]}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                                defaultValue={activeList[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
			
			<AttendanceGrid groupedAttendance={groupedAttendance}/>
            </div>
          </div>
        </div>
      </div>

		</div>
	);
	
	
};

export default ViewStudentAttendance;
