import React, { useCallback, useEffect, useRef, useState } from "react";
import { classhomework } from "../../../core/data/json/class_home_work";
import Table from "../../../core/common/dataTable/index";
import {
  classSection,
  classSylabus,
  language,
  weak,
} from "../../../core/common/selectoption/selectoption";

import { TableData } from "../../../core/data/interface";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import PredefinedDateRanges from "../../../core/common/datePicker";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import { _get, _post, server_url } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import Icon from "@ant-design/icons";

const LessonForm = () => {
  const routes = all_routes;
  interface Classes {
    class_name: string;
    lesson_date: string; 
    submitting_date: string;   // Similarly, Date might be better
    attachment: string;      // Or perhaps an array if handling multiple attachment
    content: string;
    subject: string;
    teacher: string;
    title: string;
    comment: string;
  }
  
  const _form = {
    lesson_date: "",
    class_name: "",
    submitting_date: "",
    attachment: "",
    subject:"",
    content: "",
    query_type:'create',
    teacher:"Nagudu",
    title:"",
    comment: "",
  };
  

  const [new_data, setNewData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState<Classes[]>([]);
  const [file,setFile] = useState<File | null>(null);

  const onChangeMedicalCondition = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

const [form, setForm] = useState(_form);
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    _post(
      "lessons?query_type=create",
      form,
      (res) => {
       if(res.success){ getAssingment()
        // setLoading(false);
        setForm(_form)
        toast.success("Created Assignment successfully");}
      },
      (err) => {
        // setLoading(false);
        console.error("Error creating Assignment:", err);
      }
    );
  };
  const getAssingment = () => {
    _get(
      `lessons?query_type=select`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewData(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  
  const [subjectClasses, setSubjectClasses] = useState({
    subject: "",
    class_name: "",
    section: "",
  });
  useEffect(() => {
    // getSecScl();
    _get(
      "get_subject_management?query_type=select-subject",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubjects(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);

  const getClasses = useCallback(() => {
    _get(
      `classes?query_type=select-all`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setClasses(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  }, [form]);

  useEffect (() => {
    getClasses()
    getAssingment()
  },[])
  const classOptions = classes?.length ? classes?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  })):[];
  const data = classhomework;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      )
    },

    {
      title: "Class",
      dataIndex: "class_name",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
   
    {
      title: "Subject",
      dataIndex: "subject",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "title",
      dataIndex: "title",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Content",
      dataIndex: "content",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Assingment Date",
      dataIndex: "lesson_date",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Submission Date",
      dataIndex: "submitting_date",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      render: (text: string) => (
        <div className="d-flex align-items-center">
         {text?.length>10 && ( <Link to={text} className="avatar avatar-md">
          <i style={{fontSize: 20}} className="ti ti-files" />
          </Link>)}
          
        </div>
      )
    },

    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="card"
                  data-bs-target="#edit_home_work"
                >
                  <i className="ti ti-edit-circle me-2" />
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="card"
                  data-bs-target="#delete-card"
                >
                  <i className="ti ti-trash-x me-2" />
                  Delete
                </Link>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Lessons Form</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Academic </Link>
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">
                      Class Assignment
                    </li> */}
                  </ol>
                </nav>
              </div>
             
            </div>
            <div className="">
            <div className="">
              
              <form>
                <div className="">
                {/* {JSON.stringify(form)} */}
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="mb-3">
                        <label className="form-label">Class</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class"
                          defaultValue="I"
                        />
                      </div> */}
                      <div className="row">
                        <div className="col-md-6">
                        {/* <div className="mb-3">
                            <label className="form-label">
                              Teacher 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Teacher"
                              name="teacher"
                              value={form.teacher}
                              onChange={handleChange}
                            />
                          </div> */}
                          <div className="mb-3">
                            <label className="form-label">class</label>
                            <CommonSelect
                              className="select"
                              options={classOptions}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  class_name: newValue ? newValue.value : "",
                                }))
                              }
                              defaultValue={{
                                label: form.class_name,
                                value: form.class_name,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>

                            <CommonSelect
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  subject: newValue ? newValue.value : "",
                                }))
                              }
                              defaultValue={{
                                label: form.subject,
                                value: form.subject,
                              }}
                              options={subjects}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Lesson Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="10 May 2024"
                              name="lesson_date"
                              value={form.lesson_date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Title 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="title"
                              name="title"
                              value={form.title}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Attachment</label>
                        <input
                              type="file"
                              className="form-control image-sign"
                              multiple
                              disabled={form.attachment?.length > 10}
                              name="upload"
                              onChange={onChangeMedicalCondition}
                            />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Content</label>
                        <textarea
                          className="form-control"
                          placeholder="Add content"
                          rows={4}
                          defaultValue={""}
                          name="content"
                          value={form.content}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label">Comment</label>
                        <textarea
                          className="form-control"
                          placeholder="Add Comment"
                          rows={4}
                          defaultValue={""}
                          name="comment"
                          value={form.comment}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                </div>
                <div className="card-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="card"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="card"
                    onClick={handleSubmit}
                  >
                    Add Assignment
                  </Link>
                </div>
              </form>
            </div>
          </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default LessonForm;
