import React, { useState } from "react";
import Table from "../../../core/common/dataTable/index";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { Link } from "react-router-dom";
import { SingleValue } from "react-select";
import { all_routes } from "../../router/all_routes";

const AssignmentsForm = () => {
  interface Question {
    question_type: string;
    option_a: string;
    option_b: string;
    option_c: string;
    option_d: string;
    true_false: string;
    content: string;
    correct:string;
    [key: string]: any;
  };
  const initialForm = {
    assignment_date: "",
    class_name: "",
    teacher_name: "",
    submitting_date: "",
    attachment: "",
    subject: "",
    content: "",
    title: "",
    correct:"",
    marks: "",
  };

  const initialQuestion = {
    question_type: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
    true_false: "",
    content: "",
    correct:""
  };

  const [form, setForm] = useState(initialForm);
  const [question, setQuestion] = useState<Question>(initialQuestion);
  const [questions, setQuestions] = useState<Question[]>([]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion({ ...question, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (newValue: SingleValue<Option>, fieldName: string) => {
    setQuestion({ ...question, [fieldName]: newValue ? newValue.value : "" });
  };

  const handleAddQuestion = (e: React.FormEvent) => {
    e.preventDefault();
  
    // Validate required fields
    if (!question.question_type || !question.content) {
      alert("Please complete all required fields for the question.");
      return;
    }
  
    // If the question type is "Multiple Choice", generate options JSON
    let optionsJSON = null;
    if (question.question_type === "Multiple Choice") {
      const options = ["option_a", "option_b", "option_c", "option_d"].map(
        (key) => ({
          value: question[key] || "",
          is_correct: question[key] === question.correct,
        })
      );
  
      // Validate that all options are filled
      if (options.some((option) => !option.value)) {
        alert("Please fill in all options for the multiple-choice question.");
        return;
      }
  
      optionsJSON = JSON.stringify(options);
    }
  
    // Create the complete question object
    const newQuestion = {
      question_text: question.content,
      question_type: question.question_type,
      options: optionsJSON, // Only applicable for "Multiple Choice"
      marks: question.marks || 5, // Default to 5 if no marks are specified
    };
  
    // Update the questions array with the new question
    setQuestions((prev: any) => [...prev, newQuestion]);
    console.log("New Question Added:", newQuestion);
  
    // Reset the question form
    setQuestion(initialQuestion);
  };
  
  const columns = [
    { title: "#", dataIndex: "id", render: (_: string, __: any, index: number) => index + 1 },
    { title: "Type", dataIndex: "question_type" },
    { title: "Content", dataIndex: "content" },
    { title: "Correct Answer", dataIndex: "correct" },
    {
      title: "Action", render: (_: string, __: any, index: number) => (
        <button
          onClick={() => handleDeleteQuestion(index)}
          className="btn btn-sm btn-danger"
        >
          Delete
        </button>
      )
    },
  ];

  const handleDeleteQuestion = (index: number) => {
    setQuestions((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* Page Header */}
        {JSON.stringify(questions)}
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Assignment Form</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={all_routes.adminDashboard}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Academic </Link>
                </li>
                {/* <li className="breadcrumb-item active" aria-current="page">
                      Class Assignment
                    </li> */}
              </ol>
            </nav>
          </div>

        </div>
        <div className="card">
        <div className="card-body p-3">
        <form>
          <div className="mb-3">
            <h4 className="form-label text-bold">Assignment Title</h4>
            <input
              type="text"
              name="title"
              value={form.title}
              onChange={handleFormChange}
              className="form-control"
            />
          </div>
          {/* Other assignment form fields */}
        </form>

        <h4 className="text-center">Add Questions</h4>
        <form onSubmit={handleAddQuestion}>
          <div className="mb-3">
            <label className="form-label text-bold">Question Type</label>
            <CommonSelect
              className="select"
              options={[
                { label: "Multiple Choice", value: "Multiple Choice" },
                { label: "True/False", value: "True/False" },
                { label: "Fill in the Blank", value: "Fill in the Blank" },
                { label: "Short Answer", value: "Short Answer" },
                { label: "Essay", value: "Essay" },
              ]}
              handleChange={(newValue) => handleSelectChange(newValue, "question_type")}
              defaultValue={{
                label: question.question_type,
                value: question.question_type,
              }}
            />
          </div>
          {question.question_type?<>
          <div className="mb-3">
            <label className="form-label text-bold">Question Content</label>
            <input
              type="text"
              name="content"
              value={question.content}
              onChange={handleQuestionChange}
              className="form-control"
            />
          </div>
          
          {question.question_type === "Multiple Choice" && (
  <div className="row">
    <h4 className="text-center">Add Options</h4>

    {["A", "B", "C", "D"].map((label, index) => (
      <div className="mb-3 col-md-6" key={index}>
        <label>Option {label}</label>
        <input
          type="text"
          name={`option_${label.toLowerCase()}`}
          value={question[`option_${label.toLowerCase()}`] || ""}
          onChange={handleQuestionChange}
          className="form-control"
        />
      </div>
    ))}

    {/* Correct Answer Selector */}
    {["option_a", "option_b", "option_c", "option_d"].every(
      (opt) => question[opt]
    ) ? (
      <div className="mb-3 col-md-6">
        <label className="form-label text-bold">Correct Answer</label>
        <CommonSelect
          className="select"
          options={["A", "B", "C", "D"].map((label) => ({
            label: question[`option_${label.toLowerCase()}`],
            value: question[`option_${label.toLowerCase()}`],
          }))}
          handleChange={(newValue:SingleValue<Option>) => handleSelectChange(newValue, "correct")}
          defaultValue={{
            label: question.correct,
            value: question.correct,
          }}
        />
      </div>
    ) : (
      <p className="text-warning text-center">Fill all the options</p>
    )}

  </div>
)}

{question.question_type === "True/False" && (
  <>
    <div className="mb-3 row">
      <label className="form-label text-bold">Answer</label>

      <div className="mb-3 col-md-6">
        <input
          type="radio"
          id="trueOption"
          name="answer"
          value="True"
          checked={question.option_a === "True"}
          onChange={(e) =>
            setQuestion((prev) => ({ ...prev, option_a: e.target.value }))
          }
          className="form-check-input"
        />
        <label htmlFor="trueOption" className="form-check-label">
          True
        </label>
      </div>

      <div className="mb-3 col-md-6">
        <input
          type="radio"
          id="falseOption"
          name="answer"
          value="False"
          checked={question.option_a === "False"}
          onChange={(e) =>
            setQuestion((prev) => ({ ...prev, option_a: e.target.value }))
          }
          className="form-check-input"
        />
        <label htmlFor="falseOption" className="form-check-label">
          False
        </label>
      </div>
    </div>

    {/* Correct Answer Selector */}
    {question.option_a ? (
      <div className="mb-3 col-md-6">
        <label className="form-label text-bold">Correct Answer</label>
        <CommonSelect
          className="select"
          options={[
            { label: "True", value: "True" },
            { label: "False", value: "False" },
          ]}
          handleChange={(newValue:SingleValue<Option>) => handleSelectChange(newValue, "correct")}
          defaultValue={{
            label: question.correct || "Select Correct Answer",
            value: question.correct || "",
          }}
        />
      </div>
    ) : (
      <p className="text-warning text-center">Select an answer</p>
    )}
  </>
)}

    
          
             {question.question_type === "Fill in the Blank" && (
              <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-bold">Correct Answer</label>
                <input
                  type="text"
                  value={question.correct }
                  onChange={(e) =>
                    setQuestion((prev) => ({
                      ...prev,
                      option_a: e.target.value,
                    }))
                  }
                  className="form-control"
                  placeholder="Enter the correct  answer"
                />
              </div>
              
              </div>
            )}
            {question.question_type === "Short Answer"&& (
              <div className="mb-3">
                <label className="form-label text-bold">Short Answer</label>
                <input
                  type="text"
                  value={question.option_a }
                  onChange={(e) =>
                    setQuestion((prev) => ({
                      ...prev,
                      option_a: e.target.value,
                    }))
                  }
                  className="form-control"
                  placeholder="Enter the answer"
                />
              </div>
            )}
            {question.question_type === "Essay" && (
              <div className="mb-3">
                <label className="form-label text-bold">Essay</label>
                <textarea
                  rows={5}
                  value={question.option_a }
                  onChange={(e) =>
                    setQuestion((prev) => ({
                      ...prev,
                      option_a: e.target.value,
                    }))
                  }
                  className="form-control"
                  placeholder="Enter the answer"
                />
              </div>)}
                  <div className="text-center">
                  <button type="submit" className="btn btn-primary">Add Question</button>
                  </div>
                 </> :null}
        </form>
        </div>
        </div>
        <br/>
        <div className="card">
          <div className="card-body p-0 py-3">
            <h4>Questions List</h4>
            <Table dataSource={questions} columns={columns} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentsForm;
