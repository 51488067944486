import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  allClass,
  names,
  status,
} from "../../../../core/common/selectoption/selectoption";
import TeacherModal from "../teacherModal";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";
import { teacherLists } from "../../../../core/data/json/teacherlist";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import TooltipOption from "../../../../core/common/tooltipOption";
import { _get } from "../../../Utils/Helper";

const TeacherList = () => {
  const _form = {
    search: "",
  };
  const [form, setForm] = useState(_form);
  const navigate = useNavigate();
  const [new_data, setNewData] = useState([]);
  // {
  //   key: "1",
  //   img: "assets/img/teachers/teacher-01.jpg",
  //   id: "T849127",
  //   name: "Teresa",
  //   class: "III A",
  //   subject: "Physics",
  //   email: "teresa@example.com",
  //   phone: "+1 82392 37359",
  //   dateofJoin: "25 Mar 2024",
  //   status: "Active",
  //   statusclass: "badge badge-soft-success",
  // },
  // {
  //   key: "2",
  //   img: "assets/img/teachers/teacher-02.jpg",
  //   id: "T849126",
  //   name: "Daniel",
  //   class: "II (A)",
  //   subject: "Computer",
  //   email: "daniel@example.com",
  //   phone: "+1 56752 86742",
  //   dateofJoin: "28 Mar 2024",
  //   status: "Active",
  //   statusclass: "badge badge-soft-success",
  // }

  // const handleChange = ({ target: { name, value } }) => {
  //     setForm((p) => ({ ...p, [name]: value }));
  // }

  const getTeaherPersonal = () => {
    _get(
      "get_teacher_personal_data_form?query_type=select-all",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewData(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  useEffect(() => {
    getTeaherPersonal();
  }, []);

  const routes = all_routes;
  // const data =  [
  //   {
  //     key: "1",
  //     img: "assets/img/teachers/teacher-01.jpg",
  //     id: "T849127",
  //     name: "Teresa",
  //     class: "III A",
  //     subject: "Physics",
  //     email: "teresa@example.com",
  //     phone: "+1 82392 37359",
  //     dateofJoin: "25 Mar 2024",
  //     status: "Active",
  //     statusclass: "badge badge-soft-success",
  //   },
  //   {
  //     key: "2",
  //     img: "assets/img/teachers/teacher-02.jpg",
  //     id: "T849126",
  //     name: "Daniel",
  //     class: "II (A)",
  //     subject: "Computer",
  //     email: "daniel@example.com",
  //     phone: "+1 56752 86742",
  //     dateofJoin: "28 Mar 2024",
  //     status: "Active",
  //     statusclass: "badge badge-soft-success",
  //   }];
  // const [new_data, setNewData] = useState()
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string) => (
        <Link to={`/teacher/teacher-details/${text}`} className="link-primary">
          {text}
        </Link>
      ),
      // sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          {/* <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.img}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link> */}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
      // sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    // {
    //   title: "Class",
    //   dataIndex: "class",
    //   // sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    // },
    // {
    //   title: "Subject",
    //   dataIndex: "subject",
    // },
    {
      title: "Email",
      dataIndex: "email_address",
      // sorter: (a: TableData, b: TableData) => a.email.length - b.email.length,
    },
    {
      title: "Phone",
      dataIndex: "mobile_number",
      // sorter: (a: TableData, b: TableData) => a.phone.length - b.phone.length,
    },
    {
      title: "State Of Origin",
      dataIndex: "state_of_origin",
      // sorter: (a: TableData, b: TableData) =>
      //   a.dateofJoin.length - b.dateofJoin.length,
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text: string) => (
    //     <>
    //       {text === "Active" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    // },

    {
      title: "Action",
      dataIndex: "id",
      render: (text: string) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                {/* <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to={routes.teacherDetails}
                  >
                    <i className="ti ti-menu me-2" />
                    View Teacher
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to={`/teacher/edit-teacher/${text}`} // Pass teacher_id in the URL
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>

                {/* <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#login_detail"
                  >
                    <i className="ti ti-lock me-2" />
                    Login Details
                  </Link>
                </li> */}
                {/* <li>
                  <Link className="dropdown-item rounded-1" to="#">
                    <i className="ti ti-toggle-right me-2" />
                    Disable
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Teacher List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Peoples</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Teacher List
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              {/* {JSON.stringify(new_data)} */}
              <div className="mb-2">
                <Link
                  to={routes.addTeacher}
                  className="btn btn-primary d-flex align-items-center"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Teacher
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Teachers List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width "
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <CommonSelect
                                className="select"
                                options={names}
                                defaultValue={names[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={allClass}
                                defaultValue={allClass[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={status}
                                defaultValue={status[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="d-flex align-items-center bg-white border rounded-2 p-1 mb-3 me-2">
                  <Link
                    to="#"
                    className="active btn btn-icon btn-sm me-1 primary-hover"
                  >
                    <i className="ti ti-list-tree" />
                  </Link>
                  <Link
                    to={routes.teacherGrid}
                    className="btn btn-icon btn-sm bg-light primary-hover"
                  >
                    <i className="ti ti-grid-dots" />
                  </Link>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}

              <Table dataSource={new_data} columns={columns} Selection={true} />

              {/* /Student List */}
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <TeacherModal />
    </>
  );
};

export default TeacherList;
