export const deleteRequestData = [
  {
    id: "U461893",
    name: "Janet",
    requisitionDate: "25 Mar 2024",
    deleteRequestDate: "27 Mar 2024",
    status: "Confirm",
    key: 1,
    img: "assets/img/students/student-01.jpg",
  },
  {
    id: "U461892",
    name: "Joann",
    requisitionDate: "18 Mar 2024",
    deleteRequestDate: "20 Mar 2024",
    status: "Confirm",
    key: 2,
    img: "assets/img/students/student-02.jpg",
  },
  {
    id: "U461891",
    name: "Kathleen",
    requisitionDate: "14 Mar 2024",
    deleteRequestDate: "16 Mar 2024",
    status: "Confirm",
    key: 3,
    img: "assets/img/students/student-03.jpg",
  },
  {
    id: "U461890",
    name: "Gifford",
    requisitionDate: "27 Feb 2024",
    deleteRequestDate: "26 Feb 2024",
    status: "Confirm",
    key: 4,
    img: "assets/img/students/student-04.jpg",
  },
  {
    id: "U461889",
    name: "Lisa",
    requisitionDate: "13 Feb 2024",
    deleteRequestDate: "15 Feb 2024",
    status: "Confirm",
    key: 5,
    img: "assets/img/students/student-05.jpg",
  },
  {
    id: "U461888",
    name: "Ralph",
    requisitionDate: "11 Feb 2024",
    deleteRequestDate: "13 Feb 2024",
    status: "Confirm",
    key: 6,
    img: "assets/img/students/student-06.jpg",
  },
  {
    id: "U461887",
    name: "Julie",
    requisitionDate: "26 Jan 2024",
    deleteRequestDate: "28 Jan 2024",
    status: "Confirm",
    key: 7,
    img: "assets/img/students/student-07.jpg",
  },
  {
    id: "U461886",
    name: "Ryan",
    requisitionDate: "19 Jan 2024",
    deleteRequestDate: "21 Jan 2024",
    status: "Confirm",
    key: 8,
    img: "assets/img/students/student-08.jpg",
  },
  {
    id: "U461885",
    name: "Susan",
    requisitionDate: "08 Jan 2024",
    deleteRequestDate: "10 Jan 2024",
    status: "Confirm",
    key: 9,
    img: "assets/img/students/student-09.jpg",
  },
  {
    id: "U461884",
    name: "Susan",
    requisitionDate: "22 Dec 2024",
    deleteRequestDate: "24 Dec 2024",
    status: "Confirm",
    key: 10,
    img: "assets/img/students/student-10.jpg",
  },
];
