import React, { useState } from "react";
import { Link } from "react-router-dom";

// Assuming there's a component to handle image paths
const ImageWithBasePath = ({
  src,
  alt,
  className,
}: {
  src: string;
  alt: string;
  className: string;
}) => {
  return <img src={src} alt={alt} className={className} />;
};

// ParentGridItems component
interface Parent {
  id: string;
  fullname: string;
  addedOn: string;
  email_address: string;
  phone_no: 0;
  parentImg: string;
  studentImg: string;
  studentName: string;
}

interface ParentGridItemsProps {
  parents: Parent[];
}

const ParentGridItems: React.FC<ParentGridItemsProps> = ({ parents }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="row">
      {parents.map((parent) => (
        <div className="col-xl-4 col-md-6 d-flex" key={parent.id}>
          <div className="card flex-fill">
            <div className="card-header d-flex align-items-center justify-content-between">
              <Link
                to="#"
                className="link-primary"
                onClick={() => setShow(true)}
              >
                {parent.id}
              </Link>
              <div className="d-flex align-items-center">
                <div className="dropdown">
                  <Link
                    to="#"
                    className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="ti ti-dots-vertical fs-14" />
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-right p-3">
                    <li>
                      <Link
                        className="dropdown-item rounded-1"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_parent"
                      >
                        <i className="ti ti-edit-circle me-2" />
                        Edit
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item rounded-1"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete-modal"
                      >
                        <i className="ti ti-trash-x me-2" />
                        Delete
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="bg-light-300 rounded-2 p-3 mb-3">
                <div className="d-flex align-items-center">
                  <Link
                    to="#"
                    onClick={() => setShow(true)}
                    className="avatar avatar-lg flex-shrink-0"
                  >
                    <ImageWithBasePath
                      src={parent.parentImg}
                      className="img-fluid rounded-circle"
                      alt="parent-img"
                    />
                  </Link>
                  <div className="ms-2">
                    <h6 className="text-dark text-truncate mb-0">
                      <Link to="#" onClick={() => setShow(true)}>
                        {parent.fullname}
                      </Link>
                    </h6>
                    <p>Added on {parent.addedOn}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between gx-2">
                <div>
                  <p className="mb-0">Email</p>
                  <p className="text-dark">{parent.email_address}</p>
                </div>
                <div>
                  <p className="mb-0">Phone</p>
                  <p className="text-dark">{parent.phone_no}</p>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Link
                  to="#"
                  className="avatar avatar-md flex-shrink-0 p-0 me-2"
                >
                  <ImageWithBasePath
                    src={parent.studentImg}
                    alt="student-img"
                    className="img-fluid rounded-circle"
                  />
                </Link>
                <p className="text-dark">{parent.studentName}</p>
              </div>
              <Link
                to="#"
                className="btn btn-light btn-sm"
                onClick={() => setShow(true)}
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ParentGridItems;
