import React, { useEffect, useRef, useState } from "react";
import { classRoutine } from "../../../core/data/json/class-routine";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import {
  allClass,
  classSection,
  count,
  Days,
  routinename,
  teacher,
  weak,
} from "../../../core/common/selectoption/selectoption";
import { TimePicker } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import { _get, _post } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import moment from "moment";

const ClassRoutine = () => {
  const routes = all_routes;
  // interface FormType {
  //   teacher: string;
  //   class: string;
  //   section: string;
  //   day: string;
  //   start_time: string;
  //   end_time: string;
  //   class1: string;
  //   class_name: string;
  //   class_room: string;
  // }
  // const _form: FormType = {
  //   teacher: "",
  //   class: "",
  //   section: "",
  //   day: "",
  //   start_time: null as Date | null,
  //   end_time: null as Date | null,
  //   class_room: "",
  //   name: "",
  //   class_name: "",
  // };
  interface FormType {
    teacher: string;
    class: string;
    section: string;
    day: string;
    start_time: Date | null;
    end_time: Date | null;
    class_name: string;
    class_room: string;
  }

  const _form: FormType = {
    teacher: "",
    class: "",
    section: "",
    day: "",
    start_time: null as Date | null,
    end_time: null as Date | null,
    class_room: "",
    class_name: "", // Corrected property name
  };

  // const _form = {
  //   teacher: "",
  //   class: "",
  //   section: "",
  //   day: "",
  //   start_time: null as Date | null,
  //   end_time: null as Date | null,
  //   class_room: "",
  //   name: "",
  // };
  // const _form = {
  //   teacher: "",
  //   class: "",
  //   section: "",
  //   day: "",
  //   start_time: null as Date | null,
  //   end_time: null as Date | null,
  //   class_room: "",
  // };
  // const [form, setForm] = useState(_form);
  // const [subjects, setSubjects] = useState([]);

  const [form, setForm] = useState(_form);
  const navigate = useNavigate();
  const [new_data, setNewData] = useState([]);

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const getTeaherPersonal = () => {
    _get(
      "get_class_routine",
      (res) => {
        console.log(res);
        if (res.results && res.results.length) {
          setNewData(
            res.results.map((s: any) => ({
              ...s,
              start_time: moment(s.start_time).format("DD/MM/YYYY H:mm:ss:A"),
              end_time: moment(s.end_time).format("DD/MM/YYYY H:mm:ss:A"),
            }))
          );
        }
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  useEffect(() => {
    getTeaherPersonal();
  }, []);

  const handleSubmit = () => {
    _post(
      "class_routine?query_type=create",
      form,
      (res) => {
        if (res.success) {
          alert("Submitted successfully");
          getTeaherPersonal(); // Ensure this function exists
          navigate(`/academic/class-routine`); // Navigate to the previous page
        } else {
          alert("Submission failed: " + res.message); // Inform user of failure
          console.log("Submission failed", res); // Debugging information
        }
      },
      (err) => {
        console.error("Error:", err); // Error handling
        alert("An error occurred: " + err.message); // User-friendly error message
      }
    );
  };
  const [admissionList, setAdmissionList] = useState([]);

  const getAdmissionList = () => {
    _get(
      `get_class_management?query_type=select_class_name`,
      (res) => {
        console.log("Admission List Response:", res); // Log the response
        setAdmissionList(res.results);
      },
      (err) => {
        console.log("Error fetching admission list:", err);
      }
    );
  };
  const [teacherNames, setTeacherNames] = useState([]);

  const getTeacherName = () => {
    _get(
      `get_teacher_personal_data_form?query_type=select_teacher_name`,
      (res) => {
        console.log("Teacher Name Response:", res);
        setTeacherNames(res.results);
      },
      (err) => {
        console.log("Error fetching teacher names:", err);
      }
    );
  };

  useEffect(() => {
    getAdmissionList();
    getTeacherName();
  }, []);

  const data = classRoutine;
  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer2 = () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer3 = () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer4 = () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const route = all_routes;
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   render: (text: string, record: any, index: number) => (
    //     <>
    //       <Link to="#" className="link-primary">
    //         {record.id}
    //       </Link>
    //     </>
    //   ),
    //   sorter: (a: any, b: any) => a.id.length - b.id.length,
    // },

    {
      title: "Class",
      dataIndex: "class_",
      // sorter: (a: any, b: any) => a.class.length - b.class.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      // sorter: (a: any, b: any) => a.section.length - b.section.length,
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      // sorter: (a: any, b: any) => a.teacher.length - b.teacher.length,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      // sorter: (a: any, b: any) => a.subject.length - b.subject.length,
    },
    {
      title: "Day",
      dataIndex: "day",
      // sorter: (a: any, b: any) => a.day.length - b.day.length,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      // sorter: (a: any, b: any) => a.startTime.length - b.startTime.length,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      // sorter: (a: any, b: any) => a.endTime.length - b.endTime.length,
    },
    {
      title: "Class Room",
      dataIndex: "class_room",
      // sorter: (a: any, b: any) => a.classRoom.length - b.classRoom.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_class_routine"
                >
                  <i className="ti ti-edit-circle me-2" />
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-modal"
                >
                  <i className="ti ti-trash-x me-2" />
                  Delete
                </Link>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Class Routine</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Academic </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Class Routine
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <TooltipOption />
                <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_class_routine"
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Add Class Routine
                  </Link>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Class Routine</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div
                      className="dropdown-menu drop-width"
                      ref={dropdownMenuRef}
                    >
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom pb-0">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Class</label>

                                <CommonSelect
                                  className="select"
                                  options={allClass}
                                  defaultValue={allClass[0]}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Section</label>

                                <CommonSelect
                                  className="select"
                                  options={classSection}
                                  defaultValue={classSection[0]}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Teacher</label>
                                <CommonSelect
                                  className="select"
                                  options={routinename}
                                  defaultValue={routinename[0]}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Room No</label>
                                <CommonSelect
                                  className="select"
                                  options={count}
                                  defaultValue={count[0]}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Day</label>
                                <CommonSelect
                                  className="select"
                                  options={weak}
                                  defaultValue={weak[0]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                {/* {JSON.stringify(new_data)} */}

                <Table
                  dataSource={new_data}
                  columns={columns}
                  Selection={true}
                />
                {/* /Guardians List */}
                {/* {JSON.stringify(teacherNames)} */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
      <>
        {/* Add Class Routine */}
        <div className="modal fade" id="add_class_routine">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Class Routine</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Teacher</label>

                        <CommonSelect
                          className="select"
                          // options={teacher}
                          // defaultValue={class[0]}
                          options={teacherNames.map((i: any) => ({
                            label: i.name,
                            value: i.name,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              teacher: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      {/* {JSON.stringify(admissionList)} */}
                      <div className="mb-3">
                        <label className="form-label">Class</label>
                        <CommonSelect
                          className="select"
                          options={admissionList.map((i: any) => ({
                            label: i.class_name,
                            value: i.class_name,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              class_: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={admissionList.map((i: any) => ({
                            label: i.section,
                            value: i.section,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              section: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Day</label>
                        <CommonSelect
                          options={Days}
                          defaultValue={{
                            label: form.day,
                            value: form.day,
                          }}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              day: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Start Time</label>
                            <div className="date-pic">
                              <TimePicker
                                getPopupContainer={getModalContainer2}
                                use12Hours
                                placeholder="Choose"
                                format="h:mm A"
                                className="form-control timepicker"
                                name="start_time"
                                onChange={(time) => {
                                  // Check if a valid time is selected
                                  if (time) {
                                    setForm((prev) => ({
                                      ...prev,
                                      start_time: time.toDate(), // Store as Date object
                                    }));
                                  } else {
                                    setForm((prev) => ({
                                      ...prev,
                                      start_time: null, // Reset if no valid time is selected
                                    }));
                                  }
                                }}
                              />

                              <span className="cal-icon">
                                <i className="ti ti-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">End Time</label>
                            <div className="date-pic">
                              <TimePicker
                                getPopupContainer={getModalContainer}
                                use12Hours
                                placeholder="Choose"
                                format="h:mm A"
                                className="form-control timepicker"
                                name="end_time"
                                onChange={(time) => {
                                  // Check if a valid time is selected
                                  if (time) {
                                    setForm((prev) => ({
                                      ...prev,
                                      end_time: time.toDate(), // Store as Date object
                                    }));
                                  } else {
                                    setForm((prev) => ({
                                      ...prev,
                                      end_time: null, // Reset if no valid time is selected
                                    }));
                                  }
                                }}
                              />
                              <span className="cal-icon">
                                <i className="ti ti-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Class Room</label>
                        <CommonSelect
                          className="select"
                          options={new_data.map((i: any) => ({
                            label: i.class_room,
                            value: i.class_room,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              class_room: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Add Class Routine
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Class Routine */}
        {/* Edit Class Routine */}
        <div className="modal fade" id="edit_class_routine">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Class Routine</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Teacher</label>
                        <CommonSelect
                          className="select"
                          options={teacher}
                          defaultValue={teacher[2]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Class</label>
                        <CommonSelect
                          className="select"
                          options={allClass}
                          defaultValue={allClass[2]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={classSection}
                          defaultValue={classSection[2]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Day</label>
                        <CommonSelect className="select" options={weak} />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Start Time</label>
                            <div className="date-pic">
                              <TimePicker
                                getPopupContainer={getModalContainer3}
                                use12Hours
                                placeholder="Choose"
                                format="h:mm A"
                                className="form-control timepicker"
                              />
                              <span className="cal-icon">
                                <i className="ti ti-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">End Time</label>
                            <div className="date-pic">
                              <TimePicker
                                getPopupContainer={getModalContainer4}
                                use12Hours
                                placeholder="Choose"
                                format="h:mm A"
                                className="form-control timepicker"
                              />
                              <span className="cal-icon">
                                <i className="ti ti-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Class Room</label>
                        <CommonSelect className="select" options={count} />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Class Routine */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default ClassRoutine;
