import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { Button, DatePicker, Input } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import {
  AdmissionNo,
  Hostel,
  PickupPoint,
  VehicleNumber,
  academicYear,
  allClass,
  allSection,
  bloodGroup,
  cast,
  gender,
  house,
  mothertongue,
  names,
  relationship,
  religion,
  rollno,
  roomNO,
  route,
  schoolLocation,
  status,
} from "../../../../core/common/selectoption/selectoption";
import { TagsInput } from "react-tag-input-component";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { useLocation } from "react-router-dom";
import useQuery, { _get, _post, server_url } from "../../../Utils/Helper";
import { Target } from "react-feather";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { label } from "yet-another-react-lightbox/*";
interface Parent {
  fullname: string;
  phone_no: string;
  email_address: string;
  occupation: string;
  relationship: string;
  is_gurdian: string;
}

interface Subjects {
  label: string;
  value: string;
}
const initialParent: Parent = {
  fullname: "",
  phone_no: "",
  email_address: "",
  occupation: "",
  relationship: "",
  is_gurdian: "",
};
const EditStudent = () => {
  const _form = {
    query_type: "UPDATE",
    type_of_application: "",
    name_of_applicant: "",
    last_name: "",
    home_address: "",
    date_of_birth: "",
    sex: "",
    religion: "",
    tribe: "",
    school_attended: "",
    class1: "",
    state_of_origin: "",
    l_g_a: "",
    student_name: "",
    special_health_needs: "",
    blood_group: "",
    academic_year: "",
    admission_date: "",
    status: "",
    primary_contact_number: "",
    mother_tongue: "",
    language_known: "",
    admission_no: "",
    email_address: "",
    phone_number: "",
    medical_condition: "",
    upload_transfer_certificate: "",
    studen_class: "",
    profile_picture: "",
    school_location: "",
    section: "",
    current_class:"", 
  };

  const [form, setForm] = useState(_form);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState<Boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if(form.school_location===''){
      toast.error('Please select current class')
      return
    }
    else if(form.current_class===''){
      toast.error('Please select current class')
      return
    }else if(form.sex===''){
      toast.error('Please select gender')
      return
    }else if(form.date_of_birth===''){
      toast.error('Please select date of birth')
      return
    }

    setLoading(true);
    _post(
      "students?query_type=update",
      { ...form, query_type:'UPDATE' },
      (res) => {
        if (res.success) {
            if(res.data && res.data.length > 0) {
                setForm({...res.data[0], student_name: res.data[0].full_name});
            }
          setLoading(false);
          toast.success("Submitted successfully");
          
     
          setParentList([])
          setParents(initialParent)
        }
      },
      (err) => {
        // Handle error
        setLoading(false);

        console.log(err);
      }
    );
    setErrors({});
  };

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const [classes, setClasses] = useState([]);
  const classOptions = classes?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  }));

  const [sections, setSections] = useState([]);
  const mapped_section = sections?.map((i: any) => ({
    label: i.section,
    value: i.section,
  }));

  const routes = all_routes;
  const getClassName = () => {
    _get(
      `get_class_management?query_type=select_class_name`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setClasses(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  const query = useQuery()
const student_id = query.get('student_id')||""

useEffect(() =>{
    _get(
        `students?query_type=select&id=${student_id}`,
        (res) => {
          //   navigate(`/agent`)
          console.log(res);
          if(res.data && res.data.length > 0) {
          setForm({...res.data[0], student_name: res.data[0]?.full_name});
          }
        },
        (err) => {
          // setLoading(false)
          console.log(err);
        }
      );
},[student_id])

  const getClasses = useCallback(() => {
    _get(
      `classes?query_type=select-section-classes&section=${form.section}`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setClasses(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  }, [form.section]);

  const getSections = () => {
    _get(
      `classes?query_type=select-sections`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSections(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  useEffect(() => {
    getClasses();
  }, [getClasses]);

  useEffect(() => {
    // getTeaherPersonal();
    getSections();
    getClassName();
  }, []);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>([
    "Arabic",
    "English",
    "French",
    "Hausa",
    "Yoruba",
    "Igbo",
    "Fulfulde",
    "Others",
  ]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const location = useLocation();
  const handleAdmDateChange = (newValue: any) => {
    // Ensure the newValue is a valid Day.js date object before formatting
    const formattedDate =
      newValue && dayjs(newValue).isValid()
        ? dayjs(newValue).format("YYYY-MM-DD")
        : "";

    setForm((prevForm) => ({
      ...prevForm,
      admission_date: formattedDate,
    }));
  };

  const handleDOBChange = (newValue: any) => {
    // Ensure the newValue is a valid Day.js date object before formatting
    const formattedDate =
      newValue && dayjs(newValue).isValid()
        ? dayjs(newValue).format("YYYY-MM-DD")
        : "";

    setForm((prevForm) => ({
      ...prevForm,
      date_of_birth: formattedDate,
    }));
  };
  const [parentList, setParentList] = useState<Parent[]>([]);
  const [parents, setParents] = useState<Parent>(initialParent);
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const handleChangesetParents = (e: any) => {
    setParents((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  useEffect(() => {
    _get(
      "subjects?query_type=select-all",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubjects(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);
  const onChangeMedicalCondition = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload_transfer_certificate: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };
  const onChangeUpload = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, profile_picture: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };
  const onChangeUploadTransferCertificate = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, transfer_certificate: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };
  const handleParent = () => {
    setParents(initialParent);
  };
  const handleAdd = () => {
    setParentList((prev) => [...prev, { ...parents }]);
    handleParent();
  };

  useEffect(() => {
    if (location.pathname === routes.editStudent) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const defaultValue = dayjs(formattedDate);
      setIsEdit(true);
      setOwner(["English"]);
      setOwner1(["Medecine Name"]);
      setOwner2(["Allergy", "Skin Allergy"]);
      setDefaultDate(defaultValue);
      console.log(formattedDate, 11);
    } else {
      setIsEdit(false);
      setDefaultDate(null);
    }
  }, [location.pathname]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          {/* {JSON.stringify(form)} */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Student</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.studentList}>Students</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {isEdit ? "Edit" : "Add"} Student
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form>
                {/* Personal Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-info-square-rounded fs-16" />
                      </span>
                      <h4 className="text-dark">Personal Information</h4>
                    </div>
                  </div>

                  <div className="card-body pb-1">
                    {/* <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                          <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                            <i className="ti ti-photo-plus fs-16" />
                          </div>
                          <div className="profile-upload">
                            <div className="profile-uploader d-flex align-items-center">
                              <div className="drag-upload-btn mb-3">
                                Upload
                                <input
                                  type="file"
                                  className="form-control image-sign"
                                  multiple
                                />
                              </div>
                              <Link to="#" className="btn btn-primary mb-3">
                                Remove
                              </Link>
                            </div>
                            <p className="fs-12">
                              Upload image size 4MB, Format JPG, PNG, SVG
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* {JSON.stringify(mapped_section)} */}
                    <div className="profile-upload">
                      <div className="profile-uploader d-flex align-items-center">
                        <div className="drag-upload-btn mb-3">
                          Upload
                          <input
                            type="file"
                            className="form-control image-sign"
                            multiple
                            disabled={form.profile_picture?.length > 10}
                            name="profile_picture"
                            onChange={onChangeUpload}
                          />
                        </div>
                        <Link to="#" className="btn btn-primary mb-3">
                          Remove
                        </Link>
                      </div>
                      <p className="fs-12">
                        Upload image size 4MB, Format JPG, PNG, SVG
                      </p>
                    </div>

                    <div className="row row-cols-xxl-5 row-cols-md-6">
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">School Location</label>
                          <CommonSelect
                            options={schoolLocation}
                            defaultValue={{
                              label: form.school_location,
                              value: form.school_location,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                school_location: newValue ? newValue.value : "",
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Student Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="student_name"
                            value={form.student_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Academic Year</label>
                          <CommonSelect
                            className="select"
                            options={academicYear}
                            // handleChange={({target: {value}})=>{   setForm((p) => ({ ...p, academic_year: value }));}}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                academic_year: newValue ? newValue.value : "",
                              }))
                            }
                            defaultValue={{
                              label: form.academic_year,
                              value: form.academic_year,
                            }}
                            // onChange={handleChange}
                          />
                          {/* <select
                                    name="type_of_application"
                                    value={form.type_of_application}
                                    onChange={handleChange}>
                                    <option>---select---</option>
                                    <option>Nursery</option>
                                    <option>Primary</option>
                                    <option>Secondary</option>
                                </select> */}
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Number</label>
                          <input
                            type="text"
                            className="form-control"
                            name="admission_no"
                            value={form.admission_no}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Date</label>
                          <div className="input-icon position-relative">
                            <DatePicker
                              className="form-control datetimepicker"
                              format="YYYY-MM-DD"
                              value={
                                form.admission_date
                                  ? dayjs(form.admission_date, "YYYY-MM-DD")
                                  : null
                              }
                              name="admission_date"
                              onChange={handleAdmDateChange}
                              placeholder="Select Date"
                            />
                            <span className="input-icon-addon">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Roll Number</label>
                          <input
                            type="text"
                            className="form-control"
                            name="admission_no"
                            value={form.roll_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Status</label>
                          <CommonSelect
                            className="select"
                            options={status}
                            defaultValue={isEdit ? status[0] : undefined}
                          />
                        </div>
                      </div> */}

                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            value={form.last_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Section</label>
                          <CommonSelect
                            className="select"
                            options={mapped_section}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                section: newValue ? newValue.value : "",
                              }))
                            }
                            defaultValue={{
                              label: form.section,
                              value: form.section,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Class</label>
                          <CommonSelect
                            className="select"
                            options={classOptions}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                current_class: newValue ? newValue.value : "",
                              }))
                            }
                            defaultValue={{
                              label: form.current_class,
                              value: form.current_class,
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Section</label>
                          <CommonSelect
                            className="select"
                            options={allSection}
                            defaultValue={isEdit ? allSection[0] : undefined}
                          />
                        </div>
                      </div> */}
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Gender</label>
                          <CommonSelect
                            className="select"
                            options={gender}
                            handleChange={(newValue: SingleValue<Option>) => {
                              setForm((prev) => ({
                                ...prev,
                                sex: newValue ? newValue.value : "",
                              }));
                            }}
                            defaultValue={
                             { label:form.sex,
                              value: form.sex}
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Date of Birth</label>
                          <div className="input-icon position-relative">
                            <DatePicker
                              className="form-control datetimepicker"
                              format="YYYY-MM-DD"
                              onChange={handleDOBChange}
                              // value={form.date_of_birth}
                              value={
                                form.date_of_birth
                                  ? dayjs(form.date_of_birth, "YYYY-MM-DD")
                                  : null
                              }
                              name={"date_of_birth"}
                              placeholder="Select Date"
                            />

                            <span className="input-icon-addon">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Blood Group</label>
                          <CommonSelect
                            className="select"
                            options={bloodGroup}
                            defaultValue={{
                              label: form.blood_group,
                              value: form.blood_group,
                            }}
                            handleChange={(newValue: SingleValue<Option>) => {
                              setForm((prev) => ({
                                ...prev,
                                blood_group: newValue ? newValue.value : "",
                              }));
                            }}

                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Home Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="home_address"
                            value={form.home_address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Religion</label>
                          <CommonSelect
                            className="select"
                            options={religion}
                            defaultValue={{
                              label: form.religion,
                              value: form.religion,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                religion: newValue ? newValue.value : "",
                              }))
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Category</label>
                          <CommonSelect
                            className="select"
                            options={cast}
                            defaultValue={isEdit ? cast[0] : undefined}
                          />
                        </div>
                      </div> */}
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Primary Contact Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="primary_contact_number"
                            value={form.primary_contact_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Email Address</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email_address"
                            value={form.email_address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Caste</label>
                          <input
                            type="text"
                            className="form-control"
                            name="caste"
                            value={form.caste}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Mother Tongue</label>
                          <CommonSelect
                            className="select"
                            options={mothertongue}
                            defaultValue={{
                              label: form.mother_tongue,
                              value: form.mother_tongue,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                mother_tongue: newValue ? newValue.value : "",
                              }))
                            }

                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Language Known</label>
                          <CommonSelect
                            // className="input-tags form-control"
                            options={mothertongue}
                            className="select"
                            defaultValue={{
                              label: form.language_known,
                              value: form.language_known,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                language_known: newValue ? newValue.value : "",
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Personal Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-user-shield fs-16" />
                      </span>
                      <h4 className="text-dark">
                        Parents &amp; Guardian Information
                      </h4>
                    </div>
                  </div>
                  {/* {JSON.stringify(parentList)} */}
                  <div className="card-body pb-0">
                    <div className="border-bottom mb-3">
                      <table className="table datanew">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Email </th>
                            <th>Occupation </th>
                            <th>Relationship </th>
                            <th className="text-center"> + </th>
                          </tr>
                        </thead>
                        <tbody>
                          {parentList.length
                            ? parentList.map((x, idx) => (
                                <tr key={idx}>
                                  <td>{x.fullname}</td>
                                  <td>{x.phone_no}</td>
                                  <td>{x.email_address}</td>
                                  <td>{x.occupation}</td>
                                  <td>{x.relationship}</td>
                                </tr>
                              ))
                            : null}
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="fullname"
                                value={parents.fullname}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="phone_no"
                                value={parents.phone_no}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="email_address"
                                value={parents.email_address}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="occupation"
                                value={parents.occupation}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <CommonSelect
                                options={relationship}
                                defaultValue={{
                                  label: parents.relationship,
                                  value: parents.relationship,
                                }}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setParents((prev) => ({
                                    ...prev,
                                    relationship: newValue
                                      ? newValue.value
                                      : "",
                                  }))
                                }
                              />
                            </td>
                            <td className="text-center">
                              {" "}
                              {parents.email_address &&
                              parents.fullname &&
                              parents.phone_no &&
                              parents.occupation &&
                              parents.relationship &&
                              parentList.length < 1 ? (
                                <Button
                                  type="primary"
                                  onClick={handleAdd}
                                  style={{ marginTop: 2 }}
                                >
                                  Add +
                                </Button>
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* Documents */}
                    <div className="card">
                      <div className="card-header bg-light">
                        <div className="d-flex align-items-center">
                          <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                            <i className="ti ti-file fs-16" />
                          </span>
                          <h4 className="text-dark">Documents</h4>
                        </div>
                      </div>
                      <div className="card-body pb-1">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-2">
                              <div className="mb-3">
                                <label className="form-label mb-1">
                                  Medical Condition
                                </label>
                                <p>
                                  Upload image size of 4MB, Accepted Format PDF
                                </p>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                  <i className="ti ti-file-upload me-1" />
                                  Upload Document
                                  <input
                                    type="file"
                                    className="form-control image_sign"
                                    multiple
                                    name="medical_condition"
                                    disabled={
                                      form.medical_condition?.length > 10
                                    }
                                    onChange={onChangeMedicalCondition}
                                  />
                                </div>
                                {isEdit ? (
                                  <p className="mb-2">BirthCertificate.pdf</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-2">
                              <div className="mb-3">
                                <label className="form-label mb-1">
                                  Upload Transfer Certificate
                                </label>
                                <p>
                                  Upload image size of 4MB, Accepted Format PDF
                                </p>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="btn btn-primary drag-upload-btn mb-2">
                                  <i className="ti ti-file-upload me-1" />
                                  Upload Document
                                  <input
                                    type="file"
                                    className="form-control image_sign"
                                    multiple
                                    name="upload_transfer_certificate"
                                    disabled={
                                      form.upload_transfer_certificate?.length >
                                      10
                                    }
                                    onChange={onChangeUploadTransferCertificate}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="border-bottom mb-3"> */}
                    {/* <h5 className="mb-3">Mother’s Info</h5> */}
                    {/* <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3"></div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mother Name:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_name"
                              value={form.mother_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              State of Origin (Mother):
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="state_of_origin3"
                              value={form.state_of_origin3}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Place of Work:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_place_of_work"
                              value={form.mother_place_of_work}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_occupation"
                              value={form.mother_occupation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div> */}
                    {/* </div> */}
                    {/* <div>
                      <h5 className="mb-3">Guardian Details</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Guardian Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_name"
                              value={form.guardian_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Guardian Relation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_relation"
                              value={form.guardian_relation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_phone_number"
                              value={form.guardian_phone_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              name="guardian_email"
                              value={form.guardian_email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Occupation</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_occupation"
                              value={form.guardian_occupation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_address"
                              value={form.guardian_address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* Parents & Guardian Information */}
                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-user-shield fs-16" />
                      </span>
                      <h4 className="text-dark">
                        Parents &amp; Guardian Information
                      </h4>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Father’s Info</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Father Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="father_name"
                              value={form.father_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              value={form.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone_number"
                              value={form.phone_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Father Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="father_occupation"
                              value={form.father_occupation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Mother’s Info</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mother Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_name"
                              value={form.mother_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_email"
                              value={form.mother_email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_phone_number"
                              value={form.mother_phone_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_occupation"
                              value={form.mother_occupation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-3">Guardian Details</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-2">
                            <div className="d-flex align-items-center flex-wrap">
                              <label className="form-label text-dark fw-normal me-2">
                                If Guardian Is
                              </label>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="parents"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="parents"
                                >
                                  Parents
                                </label>
                              </div>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="guardian"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="guardian"
                                >
                                  Guardian
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="other"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="other"
                                >
                                  Others
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Guardian Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_name"
                              value={form.guardian_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Guardian Relation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_relation"
                              value={form.guardian_relation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_phone_number"
                              value={form.guardian_phone_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              name="guardian_email"
                              value={form.guardian_email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Occupation</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_occupation"
                              value={form.guardian_occupation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_address"
                              value={form.guardian_address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Parents & Guardian Information */}
                {/* Sibilings */}
                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-users fs-16" />
                      </span>
                      <h4 className="text-dark">Sibilings</h4>
                    </div>
                  </div>
                  <div className="card-body"> */}
                {/* <div className="addsibling-info">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-2">
              <label className="form-label">Sibling Info</label>
              <div className="d-flex align-items-center flex-wrap">
                <label className="form-label text-dark fw-normal me-2">
                  Is Sibling studying in the same school
                </label>
                <div className="form-check me-3 mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sibling"
                    id="yes"
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="yes">
                    Yes
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sibling"
                    id="no"
                  />
                  <label className="form-check-label" htmlFor="no">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          {newContents.map((_, index) => (
            <div key={index} className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <CommonSelect
                      className="select"
                      options={names}
                      defaultValue={isEdit?names[0]:undefined}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Roll No</label>
                    <CommonSelect
                      className="select"
                      options={rollno}
                      defaultValue={isEdit?rollno[0]:undefined}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Admission No</label>
                    <CommonSelect
                      className="select"
                      options={AdmissionNo}
                      defaultValue={isEdit?AdmissionNo[0]:undefined}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="mb-3">
                    <div className="d-flex align-items-center">
                      <div className="w-100">
                        <label className="form-label">Class</label>
                        <CommonSelect
                          className="select"
                          options={allClass}
                          defaultValue={isEdit?allClass[0]:undefined}
                        />
                      </div>
                      {newContents.length > 1 && (
                        <div>
                          <label className="form-label">&nbsp;</label>
                          <Link
                            to="#"
                            className="trash-icon ms-3"
                            onChange={() => removeContent(index)}
                          >
                            <i className="ti ti-trash-x" />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
                {/* <div className="border-top pt-3">
        <Link
          to="#"
          onChange={addNewContent}
          className="add-sibling btn btn-primary d-inline-flex align-items-center"
        >
          <i className="ti ti-circle-plus me-2" />
          Add New
        </Link>
      </div> */}
                {/* </div> */}
                {/* </div> */}
                {/* /Sibilings */}
                {/* Address */}

                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-map fs-16" />
                      </span>
                      <h4 className="text-dark">Address</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Current Address</label>
                          <input type="text" className="form-control" defaultValue={isEdit? '3495 Red Hawk Road, Buffalo Lake, MN 55314': undefined}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Permanent Address
                          </label>
                          <input type="text" className="form-control" defaultValue={isEdit? '3495 Red Hawk Road, Buffalo Lake, MN 55314': undefined}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* /Address */}
                {/* Transport Information */}
                {/* <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-bus-stop fs-16" />
                      </span>
                      <h4 className="text-dark">Transport Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Route</label>
                          <CommonSelect
                            className="select"
                            options={route}
                            defaultValue={isEdit?route[0]:undefined}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Vehicle Number</label>
                          <CommonSelect
                            className="select"
                            options={VehicleNumber}
                            defaultValue={isEdit?VehicleNumber[0]:undefined}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Pickup Point</label>
                          <CommonSelect
                            className="select"
                            options={PickupPoint}
                            defaultValue={isEdit?PickupPoint[0]:undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* /Transport Information */}
                {/* Hostel Information */}
                {/* <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-fortress fs-16" />
                      </span>
                      <h4 className="text-dark">Hostel Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Hostel</label>
                          <CommonSelect
                            className="select"
                            options={Hostel}
                            defaultValue={isEdit?Hostel[0]:undefined}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Room No</label>
                          <CommonSelect
                            className="select"
                            options={roomNO}
                            defaultValue={isEdit?roomNO[0]:undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
                {/* /Hostel Information */}
                {/* Documents */}

                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-file fs-16" />
                      </span>
                      <h4 className="text-dark">Documents</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <div className="mb-3">
                            <label className="form-label mb-1">
                              Medical Condition
                            </label>
                            <p>Upload image size of 4MB, Accepted Format PDF</p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                              <i className="ti ti-file-upload me-1" />
                              Change
                              <input
                                type="file"
                                className="form-control image_sign"
                                multiple
                              />
                            </div>
                            {isEdit? <p className="mb-2">BirthCertificate.pdf</p> : <></>}
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <div className="mb-3">
                            <label className="form-label mb-1">
                              Upload Transfer Certificate
                            </label>
                            <p>Upload image size of 4MB, Accepted Format PDF</p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="btn btn-primary drag-upload-btn mb-2">
                              <i className="ti ti-file-upload me-1" />
                              Upload Document
                              <input
                                type="file"
                                className="form-control image_sign"
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* /Documents */}
                {/* Medical History */}

                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-medical-cross fs-16" />
                      </span>
                      <h4 className="text-dark">Medical History</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-2">
                          <label className="form-label">
                            Medical Condition
                          </label>
                          <div className="d-flex align-items-center flex-wrap">
                            <label className="form-label text-dark fw-normal me-2">
                              Medical Condition of a Student
                            </label>
                            <div className="form-check me-3 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="condition"
                                id="good"
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="good"
                              >
                                Good
                              </label>
                            </div>
                            <div className="form-check me-3 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="condition"
                                id="bad"
                              />
                              <label className="form-check-label" htmlFor="bad">
                                Bad
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="condition"
                                id="others"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="others"
                              >
                                Others
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Allergies</label>
                        
                        <TagsInput
                            // className="input-tags form-control"
                            value={owner2}
                            onChange={setOwner2}
                          />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Medications</label>
                        <TagsInput
                            // className="input-tags form-control"
                            value={owner1}
                            onChange={setOwner1}
                          />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* /Medical History */}
                {/* Previous School details */}
                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building fs-16" />
                      </span>
                      <h4 className="text-dark">Previous School Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">School Name</label>
                          <input type="text" className="form-control" defaultValue={isEdit? 'Oxford Matriculation, USA': undefined}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Address</label>
                          <input type="text" className="form-control" defaultValue={isEdit? '1852 Barnes Avenue, Cincinnati, OH 45202': undefined}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* /Previous School details */}
                {/* Other Details */}
                {/* <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-bank fs-16" />
                      </span>
                      <h4 className="text-dark">Other Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="mb-3">
                          <label className="form-label">Bank Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              isEdit ? "Bank of America" : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="mb-3">
                          <label className="form-label">Branch</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={isEdit ? "Cincinnati" : undefined}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-5">
                        <div className="mb-3">
                          <label className="form-label">IFSC Number</label>
                          <input type="text" className="form-control" defaultValue={isEdit? 'BOA83209832': undefined}/>
                        </div>
                      </div> 
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Other Information
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Other Details */}
                <div className="text-end">
                  <button type="button" className="btn btn-light me-3">
                    Cancel
                  </button>
                  <Button disabled={loading?true:false} onClick={handleSubmit} className="btn btn-primary">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default EditStudent;
