import React, { useCallback, useEffect, useRef, useState } from "react";
// import { StudentTable } from "../../../core/data/json/class_home_work";
import Table from "../../../core/common/dataTable/index";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { _get, _post, server_url } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

const StudentAssignments = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const routes = all_routes;
  interface Classes {
    class_name: string;
    assignment_date: string;
    submitting_date: string;   // Similarly, Date might be better
    attachment: string;      // Or perhaps an array if handling multiple attachment
    content: string;
    subject: string;
    teacher_name: string;
    student_name:string;
    admission_no: number; //
  }

  const _form = {
    assignment_date: "",
    class_name: "",
    submitting_date: "",
    attachment: "",
    subject: "",
    content: "",
    query_type: 'create',
    teacher_name: user.name,
    student_name: "",
    admission_no: "",
  };

  const navigate = useNavigate()
  const [assignments, setAssignments] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState<Classes[]>([]);

  const onChangeMedicalCondition = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  const [form, setForm] = useState(_form);
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: any) => {
    if(!form.assignment_date){
      toast.error("Please select assignment date");
      return;
    }
  if(!form.submitting_date){
    toast.error("Please select submissoin date");
      return;
  }
  if(!form.subject){
    toast.error("Please select subject");
      return;
  }
  if(!form.content){
    toast.error("Please write content");
      return;
    }
    if(!form.class_name){
      toast.error("Please select class");
      return;
    }
    
    e.preventDefault();
    _post(
      "assignments?query_type=create",
      {...form, student_name:user.student_name, admission_no:user.admission_no},
      (res) => {
        getAssingment()
        // setLoading(false);
        toast.success("Created Assignment successfully");
      },
      (err) => {
        // setLoading(false);
        console.error("Error creating Assignment:", err);
      }
    );
  };

  const getAssingment = useCallback(() => {
    if (user.current_class) {
      toast.success(user.current_class);
      _get(
        `assignments?query_type=select&class_name=${user.current_class}`,
        (res) => {
          setAssignments(res.data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [user.current_class]);

  const [subjectClasses, setSubjectClasses] = useState({
    subject: "",
    class_name: "",
    section: "",
  });
  useEffect(() => {
    // getSecScl();
    _get(
      "get_subject_management?query_type=select-subject",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubjects(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);

  const getClasses = useCallback(() => {
    _get(
      `classes?query_type=select-all`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setClasses(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  }, [form]);

  useEffect(() => {
    getClasses()
    getAssingment()
  }, [getAssingment])
  const classOptions = classes?.length ? classes?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  })) : [];


  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {index + 1}
          </Link>
        </>
      )
    },

    {
      title: "Class",
      dataIndex: "class_name",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },

    {
      title: "Subject",
      dataIndex: "subject",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Assingment Date",
      dataIndex: "assignment_date",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Submission Date",
      dataIndex: "submitting_date",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text}
        </div>
      ),
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      render: (text: string) => (
        <div className="d-flex align-items-center">
          {text?.length > 10 && (<Link to={text} className="avatar avatar-md">
            <i style={{ fontSize: 20 }} className="ti ti-files" />
          </Link>)}

        </div>
      )
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <Button onClick={() => navigate(`${all_routes.viewStudentAssignment}?id=${record.id}`)}>View</Button>
        </>
      ),
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Student Assignments</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Academic </Link>
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">
                      Class Assignment
                    </li> */}
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                {/* <TooltipOption /> */}
                {/* <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_home_work"
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Add Assignment
                  </Link>
                </div> */}
              </div>
            </div>
            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                {/* <h4 className="mb-3">Class Assignment</h4> */}
                {/* <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div
                      className="dropdown-menu drop-width"
                      ref={dropdownMenuRef}
                    >
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom pb-0">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Subject</label>
                                <CommonSelect
                                  className="select"
                                  options={language}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              {/* <div className="mb-3">
                                <label className="form-label">Class</label>

                                <CommonSelect
                                  className="select"
                                  options={classSylabus}
                                />
                              </div> *
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Section</label>

                                <CommonSelect
                                  className="select"
                                  options={classSection}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Date</label>
                                <CommonSelect
                                  className="select"
                                  options={weak}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                <Table
                  dataSource={assignments}
                  columns={columns}
                  Selection={true}
                />

                {/* /Guardians List */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Add Home Work */}
        {/* {JSON.stringify(assignments)} */}
        <div className="modal fade" id="add_home_work">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Assignment</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  {/* {JSON.stringify(form)} */}
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <div className="mb-3">
                        <label className="form-label">Class</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class"
                          defaultValue="I"
                        />
                      </div> */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">class</label>
                              <CommonSelect
                                className="select"
                                options={classOptions}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setForm((prev) => ({
                                    ...prev,
                                    class_name: newValue ? newValue.value : "",
                                  }))
                                }
                                defaultValue={{
                                  label: form.class_name,
                                  value: form.class_name,
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Subject</label>

                              <CommonSelect
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setForm((prev) => ({
                                    ...prev,
                                    subject: newValue ? newValue.value : "",
                                  }))
                                }
                                defaultValue={{
                                  label: form.subject,
                                  value: form.subject,
                                }}
                                options={subjects}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Assignment Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="10 May 2024"
                                name="assignment_date"
                                value={form.assignment_date}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Submission Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="12 May 2024"
                                name="submitting_date"
                                value={form.submitting_date}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Attachment</label>
                          <input
                            type="file"
                            className="form-control image-sign"
                            multiple
                            disabled={form.attachment?.length > 10}
                            name="upload"
                            onChange={onChangeMedicalCondition}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Content</label>
                          <textarea
                            className="form-control"
                            placeholder="Add Comment"
                            rows={4}
                            defaultValue={""}
                            name="content"
                            value={form.content}
                            onChange={handleChange}
                          />
                        </div>
                        {/* <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Add Assignment
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Home Work */}
        {/* Edit Home Work */}

        <div className="modal fade" id="edit_home_work">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Assignment</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="mb-3">
                        <label className="form-label">Class</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class"
                          defaultValue="I"
                        />
                      </div> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">class</label>
                            <CommonSelect
                              className="select"
                              options={classOptions}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setSubjectClasses((prev) => ({
                                  ...prev,
                                  class_name: newValue ? newValue.value : "",
                                }))
                              }
                              defaultValue={{
                                label: subjectClasses.class_name,
                                value: subjectClasses.class_name,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>

                            <CommonSelect
                              handleChange={(newValue: SingleValue<Option>) =>
                                setSubjectClasses((prev) => ({
                                  ...prev,
                                  subject: newValue ? newValue.value : "",
                                }))
                              }
                              defaultValue={{
                                label: subjectClasses.subject,
                                value: subjectClasses.subject,
                              }}
                              options={subjects}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Assignment Date
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="10 May 2024"
                              name="assignment_date"
                              value={form.assignment_date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Submission Date
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="12 May 2024"
                              name="submission_date"
                              value={form.submitting_date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Attachment</label>
                        <input
                          type="file"
                          className="form-control"
                          placeholder="Placeholders"
                          name="attachment"
                          value={form.attachment}
                          onChange={onChangeMedicalCondition}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Content</label>
                        <textarea
                          className="form-control"
                          placeholder="Add Comment"
                          rows={4}
                          defaultValue={""}
                          name="content"
                          value={form.content}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Home Work */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default StudentAssignments;
