export const studentDayWiseData = [
  {
    key: "1",
    id: "AD8483",
    rollNo: "3483",
    name: "Hellana",
    img: "assets/img/teachers/teacher-03.jpg",
    department: "Management",
    role: "Receptionist",
    class: "badge badge-soft-success",
    attendance: "Present",
  },
  {
    key: "2",
    id: "AD8482",
    rollNo: "3482",
    name: "Daniel",
    img: "assets/img/teachers/teacher-02.jpg",
    department: "Finance",
    role: "Accounts Manager",
    class: "badge badge-soft-success",
    attendance: "Present",
  },
  {
    key: "3",
    id: "AD8481",
    rollNo: "3481",
    name: "Kevin",
    img: "assets/img/profiles/avatar-27.jpg",
    department: "Management",
    role: "Driver",
    class: "badge badge-soft-danger",
    attendance: "Absent",
  },
  {
    key: "4",
    id: "AD8480",
    rollNo: "3480",
    name: "Teresa",
    img: "assets/img/teachers/teacher-01.jpg",
    department: "Finance",
    role: "Librarian",
    class: "badge badge-soft-success",
    attendance: "Present",
  },
  {
    key: "5",
    id: "AD8479",
    rollNo: "3479",
    name: "James",
    img: "assets/img/members/members-01.jpg",
    department: "Management",
    role: "HR Manager",
    class: "badge text-dark bg-transparent-dark",
    attendance: "Half Day",
  },
  {
    key: "6",
    id: "AD8478",
    rollNo: "3478",
    name: "Johnson",
    img: "assets/img/teachers/teacher-08.jpg",
    department: "Admin",
    role: "Accountant",
    class: "badge badge-soft-success",
    attendance: "Present",
  },
  {
    key: "7",
    id: "AD8477",
    rollNo: "3477",
    name: "Edward",
    img: "assets/img/teachers/teacher-10.jpg",
    department: "Transport",
    role: "Admin",
    class: "badge badge-soft-success",
    attendance: "Present",
  },
  {
    key: "8",
    id: "AD8476",
    rollNo: "3476",
    name: "Jacquelin",
    img: "assets/img/teachers/teacher-07.jpg",
    department: "Library",
    role: "Admin",
    class: "badge badge-soft-pending",
    attendance: "Late",
  },
  {
    key: "9",
    id: "AD8475",
    rollNo: "3475",
    name: "Elizabeth",
    img: "assets/img/teachers/teacher-09.jpg",
    department: "Management",
    role: "Receptionist",
    class: "badge badge-soft-success",
    attendance: "Present",
  },
  {
    key: "10",
    id: "AD8474",
    rollNo: "3474",
    name: "Willie",
    img: "assets/img/teachers/teacher-05.jpg",
    department: "Management",
    role: "Technical Head",
    class: "badge badge-soft-success",
    attendance: "Present",
  },
];
