import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Button, Upload, Typography, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import useQuery, { _get, _post, server_url } from "../../Utils/Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import dayjs from 'dayjs'
const { Title, Paragraph } = Typography;

const ViewAssignment = () => {
  interface StudentAssignment{
    created_at:string;
    id: number;
    class_name: string;
    attachment: string;
    content: string;
    student_id: string;
    student_name: string;
    teacher_id: string;
    teacher_name: string;
    subject: string;
    comment: string;

  }
  const _assignment = {
    id: 0,
    class_name: "",
    attachment: "",
    content: "",
    student_id: "",
    student_name: "",
    teacher_id: "",
    teacher_name: "",
    subject: "",
    title:"",
    created_at:"",
    submitting_date:"",
    comment: "",
  };

  const query = useQuery();
  const id = query.get("id");
  const {user} = useSelector((state:RootState)=>state.auth)
  const [form, setForm] = useState(_assignment);
  const [assignment, setAssignment] = useState(_assignment);
  const [studentAssignment, setStudentAssignment] = useState<StudentAssignment>(_assignment);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeFile = (info: any) => {
    const { file } = info;

    if (file.status === "done") {
      const data = file.response;
      setForm((prev) => ({ ...prev, attachment: data.url }));
      message.success("Attachment uploaded successfully!");
    } else if (file.status === "error") {
      message.error("Error uploading attachment.");
    }
  };

  const handleSubmit = async () => {
    try {
      await _post(
        "student/assignments",
        {...form,student_name:user.student_name,admission_no:user.admission_no,
          query_type:'create'
        },
        () => {
          setForm(_assignment);
          getStudentAssignment();
          toast.success("Assignment submitted successfully!");
        },
        (err) => {
          console.error("Error submitting assignment:", err);
          toast.error("Error submitting assignment.");
        }
      );
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  const getStudentAssignment = useCallback(() => {
    if(user.id){
      _get(
        `student/assignments?query_type=select&student_id=${user.id}&assignment_id=${id}`,
        (res) => {
        if(res.success && res.data.length){
          console.log(res);
          setStudentAssignment(res.data[0]);
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, [id,user.id]);

  const getAssignment = useCallback(() => {
    if(id && user.id){
      _get(
        `assignments?query_type=select&id=${id}`,
        (res) => {
          console.log(res);
          setAssignment(res.data[0]);
          const assig = res.data[0]
            setForm((p)=>({...p, teacher_name:assig.teacher_name,  teacher_id:assig.teacher_id, class_name:assig.class_name, assignment_id:assig.id, student_id:user.id, student_name:user.full_name}))
          },
        (err) => {
          console.error(err);
        }
      );
    }
  }, [id, user.id]);
  useEffect(() => {
    getStudentAssignment()
    getAssignment();
  }, [getAssignment,getStudentAssignment]);

  return (
    <div className="page-wrapper" style={{ padding: "24px", maxWidth: "800px", margin: "30px auto" }}>
      <Title level={2} style={{ textAlign: "center" }}>
        Assignment:  {assignment.title}
      </Title>

      {assignment.id ? (
        <div style={{ marginBottom: "24px", padding: "16px", border: "1px solid #f0f0f0", borderRadius: "8px" }}>
          <Paragraph>
            <b>Class:</b> {assignment.class_name}
          </Paragraph>
          <Paragraph>
            <b>Subject:</b> {assignment.subject}
          </Paragraph>
          <Paragraph>
            <b>Teacher:</b> {assignment.teacher_name}
          </Paragraph>
          <Paragraph>
            <b>Due Date:</b> {assignment.submitting_date} 
          </Paragraph>
          {assignment.attachment?<Paragraph>
            <b>Attachment:</b>  <a href={assignment.attachment} target="_blank" rel="noopener noreferrer">
                View Attachment
              </a>
          </Paragraph>:null}
          <Paragraph>
            <b>Content:</b> {assignment.content}
          </Paragraph>
        </div>
      ):null}

      <Form layout="vertical" onFinish={handleSubmit}>
        {/* <Form.Item label="Class Name" required>
          <Input
            name="class_name"
            value={form.class_name}
            onChange={handleChange}
            placeholder="Enter class name"
          />
        </Form.Item> */}
        <Form.Item label="Response Title" required>
          <Input
            name="subject"
            value={form.subject}
            onChange={handleChange}
            placeholder="Enter response title"
          />
        </Form.Item>
        <Form.Item label="Attachment">
          <Upload
            name="media"
            action={`${server_url}/upload_transfer_certificate-document`}
            onChange={onChangeFile}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Upload Attachment</Button>
          </Upload>
          {form.attachment && (
            <Paragraph>
              <a href={form.attachment} target="_blank" rel="noopener noreferrer">
                View Uploaded Attachment
              </a>
            </Paragraph>
          )}
        </Form.Item>
        <Form.Item label="Content" required>
          <Input.TextArea
            name="content"
            value={form.content}
            onChange={handleChange}
            rows={4}
            placeholder="Enter assignment content"
          />
        </Form.Item>
        <Form.Item label="comment" required>
          <Input.TextArea
            name="comment"
            value={form.comment}
            onChange={handleChange}
            rows={4}
            placeholder="Enter assignment comment"
          />
        </Form.Item>
        {studentAssignment.id?<Typography>
          <strong>Sumitted on:</strong> {dayjs(studentAssignment?.created_at).format('DD/MM/YYYY HH:mm:ss A')}
        </Typography>:<Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit Assignment
          </Button>
        </Form.Item>}
      </Form>
    </div>
  );
};

export default ViewAssignment;
