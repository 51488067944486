import React, { useEffect, useRef, useState } from "react";
import { classes } from "../../../core/data/json/classes";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  activeList,
  classSylabus,
  schoolLocation,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link, useNavigate } from "react-router-dom";
import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../../router/all_routes";
import { _get, _post } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
const classSection = [
  { value: "Select", label: "Select" },
  { value: "PRE-NURSERY", label: "PPRE-NURSERYRE" },
  { value: "NURSERY", label: "NURSERY" },
  { value: "PRIMARY", label: "PRIMARY" },
  { value: "SECONDARY", label: "SECONDARY" },
];
const Classes = () => {
  const _form = {
    query_type: "create",
    section: "",
    class_name: "",
    level: "",
    capacity: "",
    school_location: "",
  };

  interface Classroom {
    block_no: string;
    capacity: number;
  }

  const [form, setForm] = useState(_form);
  const [new_data_teacher, setNewDataTeacher] = useState([]);
  const [newDataClassRooms, setNewDataClassRooms] = useState<Classroom[]>([]);
  const handleChange = (e: any) => {
    if (e.target.name === "class_name") {
      setForm((p) => ({
        ...p,
        class_code: `${moment().format("YYYY")}-${form.class_name}`,
      }));
    }
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    _post(
      "classes?query_type=create",
      form,
      (res) => {
        toast.success("Submitted Success");
        setTimeout(() => {
          getTeaherPersonal();
        }, 1000);
      },
      (err) => {
        // setLoading(false);
        console.log(err);
      }
    );

    console.log(form);
    setForm(_form);
    // setErrors({});
  };
  const getSections = () => {
    _get(
      `classes?query_type=select-sections`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSections(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const getClassRooms = () => {
    _get(
      `classes?query_type=select-all`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewDataClassRooms(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const navigate = useNavigate();
  const [new_data, setNewData] = useState([]);
  const [new_data_teacher_name, setNewDataTeacherName] = useState({});
  const getTeaherPersonal = () => {
    _get(
      "classes?query_type=select-all",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewData(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  const getCapacity = (block_no: string): number | undefined => {
    return newDataClassRooms.find((x) => x.block_no === block_no)?.capacity;
  };
  const [sections, setSections] = useState([]);
  const mapped_section = sections?.map((i: any) => ({
    label: i.section,
    value: i.section,
  }));
  const getTeaherName = () => {
    _get(
      `get_teacher_personal_data_form?query_type=select_teacher_name`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewDataTeacher(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const classOptions = new_data_teacher?.map((i: any) => ({
    label: i.name,
    value: i.name,
  }));

  const classOptions1 = newDataClassRooms?.map((i: any) => ({
    label: i.block_no,
    value: i.block_no,
  }));
  useEffect(() => {
    getTeaherName();
    getClassRooms();
    getTeaherPersonal();
    getSections();
  }, []);

  const routes = all_routes;

  const data = classes;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const route = all_routes;
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
    },

    // {
    //   title: "Class Code",
    //   dataIndex: "class_code",
    //   // sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    // },
    {
      title: "Class Name",
      dataIndex: "class_name",
      // sorter: (a: TableData, b: TableData) =>
      //   a.section.length - b.section.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      // sorter: (a: TableData, b: TableData) =>
      //   a.section.length - b.section.length,
    },
    {
      title: "Level",
      dataIndex: "level",
      // sorter: (a: TableData, b: TableData) =>
      //   a.noOfStudents.length - b.noOfStudents.length,
    },
    // {
    //   title: "Max Population",
    //   dataIndex: "capacity",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.noOfSubjects.length - b.noOfSubjects.length,
    // },
    {
      title: "No. of Students",
      dataIndex: "capacity  ",
      // sorter: (a: TableData, b: TableData) =>
      //   a.noOfSubjects.length - b.noOfSubjects.length,
    },
    // {
    //   title: "Class Teacher",
    //   dataIndex: "class_teacher",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.noOfSubjects.length - b.noOfSubjects.length,
    // },
    // {
    //   title: "Class Teaher",
    //   dataIndex: "status",
    //   render: (text: string) => (
    //     <>
    //       {text === "Active" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: () => (
    //     <>
    //       <div className="d-flex align-items-center">
    //         <div className="dropdown">
    //           <Link
    //             to="#"
    //             className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="ti ti-dots-vertical fs-14" />
    //           </Link>
    //           <ul className="dropdown-menu dropdown-menu-right p-3">
    //             <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#edit_class"
    //               >
    //                 <i className="ti ti-edit-circle me-2" />
    //                 Edit
    //               </Link>
    //             </li>
    //             <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#delete-modal"
    //               >
    //                 <i className="ti ti-trash-x me-2" />
    //                 Delete
    //               </Link>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Classes List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Classes </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Classes
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_class"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add Class
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Classes List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                                defaultValue={classSylabus[0]}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                                defaultValue={activeList[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              {/* {JSON.stringify(new_data)} */}
              <Table columns={columns} dataSource={new_data} Selection={true} />
              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      ;{/* /Page Wrapper */}
      <>
        {/* Add Classes */}

        <div className="modal fade" id="add_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>

              <form>
                {/* {JSON.stringify(form)} */}
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="class_name"
                          value={form.class_name}
                          onChange={handleChange}
                        />
                      </div>
                      {/* {JSON.stringify(new_data)} */}
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={mapped_section}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              section: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Level</label>
                        <input
                          type="number"
                          className="form-control"
                          name="level"
                          // disabled={true}
                          value={form.level || ""} // Use form.capacity directly, which is set in handleChange
                          onChange={handleChange} // Update form.capacity on change
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Students</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Students"
                          defaultValue={30}
                          name="capacity"
                          value={form.capacity}
                          onChange={handleChange} // Update form.capacity on change
                        />
                      </div>
                      {/* <div className="col-xxl col-xl-3 col-md-6"> */}

                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    className="btn btn-primary   me-2"
                    to="#"
                    onClick={() => handleSubmit()}
                  >
                    Add Class
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Classes */}
        {/* Edit Classes */}
        <div className="modal fade" id="edit_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class Name"
                          defaultValue="I"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={classSection}
                          defaultValue={classSection[0]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Students</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Students"
                          defaultValue={30}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Subjects</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Subjects"
                          // defaultValue={03}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Classes */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
        {/* View Classes */}
        <div className="modal fade" id="view_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="modal-title">Class Details</h4>
                  <span className="badge badge-soft-success ms-2">
                    <i className="ti ti-circle-filled me-1 fs-5" />
                    Active
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Class Name</p>
                        <span>III</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Section</p>
                        <span>A</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Subjects</p>
                        <span>05</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Students</p>
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /View Classes */}
      </>
    </div>
  );
};

export default Classes;
