export const teacherDayWiseData = [
  {
    key: "1",
    class: "badge badge-soft-success",
    sNo: "1",
    id: "T849127",
    name: "Teresa",
    subject: "Physics",
    attendance: "Present",
    img: "assets/img/teachers/teacher-01.jpg",
  },
  {
    key: "2",
    class: "badge badge-soft-success",
    sNo: "2",
    id: "T849126",
    name: "Daniel",
    subject: "Computer",
    attendance: "Present",
    img: "assets/img/teachers/teacher-02.jpg",
  },
  {
    key: "3",
    class: "badge badge-soft-danger",
    sNo: "3",
    id: "T849125",
    name: "Hellana",
    subject: "English",
    attendance: "Absent",
    img: "assets/img/teachers/teacher-03.jpg",
  },
  {
    key: "4",
    class: "badge badge-soft-success",
    sNo: "4",
    id: "T849124",
    name: "Erickson",
    subject: "AgricScience",
    attendance: "Present",
    img: "assets/img/teachers/teacher-04.jpg",
  },
  {
    key: "5",
    class: "badge text-dark bg-transparent-dark",
    sNo: "5",
    id: "T849123",
    name: "Morgan",
    subject: "Env Science",
    attendance: "Half Day",
    img: "assets/img/teachers/teacher-05.jpg",
  },
  {
    key: "6",
    class: "badge badge-soft-success",
    sNo: "6",
    id: "T849122",
    name: "Aaron",
    subject: "Chemistry",
    attendance: "Present",
    img: "assets/img/teachers/teacher-06.jpg",
  },
  {
    key: "7",
    class: "badge badge-soft-success",
    sNo: "7",
    id: "T849121",
    name: "Jacquelin",
    subject: "Maths",
    attendance: "Present",
    img: "assets/img/teachers/teacher-07.jpg",
  },
  {
    key: "8",
    class: "badge badge-soft-pending",
    sNo: "8",
    id: "T849120",
    name: "Raul",
    subject: "Biology",
    attendance: "Late",
    img: "assets/img/teachers/teacher-08.jpg",
  },
  {
    key: "9",
    class: "badge badge-soft-success",
    sNo: "9",
    id: "T849119",
    name: "Elizabeth",
    subject: "Economics",
    attendance: "Present",
    img: "assets/img/teachers/teacher-09.jpg",
  },
  {
    key: "10",
    class: "badge badge-soft-success",
    sNo: "10",
    id: "T849118",
    name: "Edward",
    subject: "Finance",
    attendance: "Present",
    img: "assets/img/teachers/teacher-10.jpg",
  },
];
