export const staff = [
  {
    id: "8198",
    name: "Kevin",
    department: "Admin",
    designation: "Technical Head",
    phone: "+1 63423 72397",
    email: "kevin@example.com",
    dateOfJoin: "10 Mar 2024",
    img: "assets/img/profiles/avatar-27.jpg",
    key: 1,
  },
  {
    id: "8197",
    name: "Willie",
    department: "Management",
    designation: "Receptionist",
    phone: "+1 82913 61371",
    email: "willie@example.com",
    dateOfJoin: "16 Mar 2024",
    img: "assets/img/teachers/teacher-05.jpg",
    key: 2,
  },
  {
    id: "8196",
    name: "Daniel",
    department: "Management",
    designation: "Admin",
    phone: "+1 56752 86742",
    email: "daniel@example.com",
    dateOfJoin: "28 Mar 2024",
    img: "assets/img/teachers/teacher-02.jpg",
    key: 3,
  },
  {
    id: "8195",
    name: "Teresa",
    department: "Management",
    designation: "Admin",
    phone: "+1 82392 37359",
    email: "teresa@example.com",
    dateOfJoin: "25 Mar 2024",
    img: "assets/img/teachers/teacher-01.jpg",
    key: 4,
  },
  {
    id: "8194",
    name: "Johnson",
    department: "Finance",
    designation: "Accountant",
    phone: "+1 53619 54691",
    email: "johnson@example.com",
    dateOfJoin: "04 Apr 2024",
    img: "assets/img/teachers/teacher-06.jpg",
    key: 5,
  },
  {
    id: "8193",
    name: "Hellana",
    department: "Management",
    designation: "HR Manager",
    phone: "+1 23566 52683",
    email: "hellana@example.com",
    dateOfJoin: "11 Apr 2024",
    img: "assets/img/teachers/teacher-03.jpg",
    key: 6,
  },
  {
    id: "8192",
    name: "James",
    department: "Library",
    designation: "Librarian",
    phone: "+1 78429 82414",
    email: "james@example.com",
    dateOfJoin: "22 Apr 2024",
    img: "assets/img/members/members-01.jpg",
    key: 7,
  },
  {
    id: "8191",
    name: "Jacquelin",
    department: "Transport",
    designation: "Driver",
    phone: "+1 77502 54845",
    email: "jacquelin@example.com",
    dateOfJoin: "20 May 2024",
    img: "assets/img/teachers/teacher-07.jpg",
    key: 8,
  },
  {
    id: "8190",
    name: "Edward",
    department: "Finance",
    designation: "Accounts Manager",
    phone: "+1 56187 87489",
    email: "edward@example.com",
    dateOfJoin: "10 Jun 2024",
    img: "assets/img/teachers/teacher-10.jpg",
    key: 9,
  },
  {
    id: "8189",
    name: "Elizabeth",
    department: "Management",
    designation: "Receptionist",
    phone: "+1 97846 84518",
    email: "elizabeth@example.com",
    dateOfJoin: "18 Jun 2024",
    img: "assets/img/teachers/teacher-09.jpg",
    key: 10,
  },
];
