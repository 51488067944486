export const studentreport = [
  {
    admissionNo: "AD9892434",
    rollNo: "35013",
    name: "Janet",
    class: "I",
    section: "A",
    gender: "Female",
    parent: "Thomas",
    dateOfJoin: "25 Mar 2024",
    dob: "10 Jan 2015",
    status: "Active",
    img: "assets/img/students/student-10.jpg",
    parentimg: "assets/img/parents/parent-10.jpg",
  },
  {
    admissionNo: "AD9892433",
    rollNo: "35012",
    name: "Joann",
    class: "I",
    section: "A",
    gender: "Male",
    parent: "Marquita",
    dateOfJoin: "18 Mar 2024",
    dob: "19 Aug 2014",
    status: "Active",
    img: "assets/img/students/student-09.jpg",
    parentimg: "assets/img/parents/parent-09.jpg",
  },
  {
    admissionNo: "AD9892432",
    rollNo: "35011",
    name: "Kathleen",
    class: "I",
    section: "A",
    gender: "Female",
    parent: "Johnson",
    dateOfJoin: "14 Mar 2024",
    dob: "05 Dec 2017",
    status: "Active",
    img: "assets/img/students/student-08.jpg",
    parentimg: "assets/img/parents/parent-08.jpg",
  },
  {
    admissionNo: "AD9892431",
    rollNo: "35010",
    name: "Gifford",
    class: "I",
    section: "A",
    gender: "Male",
    parent: "Claudia",
    dateOfJoin: "27 Feb 2024",
    dob: "22 Mar 2018",
    status: "Active",
    img: "assets/img/students/student-07.jpg",
    parentimg: "assets/img/parents/parent-07.jpg",
  },
  {
    admissionNo: "AD9892430",
    rollNo: "35009",
    name: "Lisa",
    class: "I",
    section: "A",
    gender: "Female",
    parent: "Arthur",
    dateOfJoin: "13 Feb 2024",
    dob: "13 May 2017",
    status: "Inactive",
    img: "assets/img/students/student-06.jpg",
    parentimg: "assets/img/parents/parent-06.jpg",
  },
  {
    admissionNo: "AD9892429",
    rollNo: "35008",
    name: "Lisa",
    class: "I",
    section: "A",
    gender: "Male",
    parent: "Colleen",
    dateOfJoin: "11 Feb 2024",
    dob: "20 Jun 2015",
    status: "Active",
    img: "assets/img/students/student-05.jpg",
    parentimg: "assets/img/parents/parent-05.jpg",
  },
  {
    admissionNo: "AD9892428",
    rollNo: "35007",
    name: "Julie",
    class: "I",
    section: "A",
    gender: "Female",
    parent: "Robert",
    dateOfJoin: "24 Jan 2024",
    dob: "18 Sep 2013",
    status: "Active",
    img: "assets/img/students/student-04.jpg",
    parentimg: "assets/img/parents/parent-04.jpg",
  },
  {
    admissionNo: "AD9892427",
    rollNo: "35006",
    name: "Ryan",
    class: "I",
    section: "A",
    gender: "Male",
    parent: "Jessie",
    dateOfJoin: "19 Jan 2024",
    dob: "26 Nov 2012",
    status: "Active",
    img: "assets/img/students/student-03.jpg",
    parentimg: "assets/img/parents/parent-03.jpg",
  },
  {
    admissionNo: "AD9892426",
    rollNo: "35005",
    name: "Susan",
    class: "I",
    section: "A",
    gender: "Female",
    parent: "Michael",
    dateOfJoin: "08 Jan 2024",
    dob: "26 May 2010",
    status: "Inactive",
    img: "assets/img/students/student-02.jpg",
    parentimg: "assets/img/parents/parent-02.jpg",
  },
  {
    admissionNo: "AD9892425",
    rollNo: "35004",
    name: "Richard",
    class: "I",
    section: "A",
    gender: "Male",
    parent: "Mary",
    dateOfJoin: "22 Dec 2024",
    dob: "06 Oct 2011",
    status: "Active",
    img: "assets/img/students/student-01.jpg",
    parentimg: "assets/img/parents/parent-01.jpg",
  },
];
