import React, { MouseEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { feeGroup, feesTypes, paymentType } from '../../../core/common/selectoption/selectoption'
import { Button, DatePicker, Input, Row } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import {
  gender,
  relationship,
  schoolLocation,
  // relationship,
  // relationship,
  specialHealth,
} from "../../../../core/common/selectoption/selectoption";
import { TagsInput } from "react-tag-input-component";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { useLocation } from "react-router-dom";
import { _get, _post, server_url } from "../../../Utils/Helper";
import { SingleValue } from "react-select";
// import { Columns } from "react-feather";
// import Datatable from "../../../../core/common/dataTable";
// import DataFormtable from "../../../../core/common/dataTable/DataFormtable";
// import { Button } from "react-bootstrap";

const AddApplication = () => {
  const _form = {
    upload: "",
    type_of_application: "",
    name_of_applicant: "",
    home_address: "",
    date_of_birth: "",
    sex: "",
    relationship1: "",
    tribe: "",
    school_attended: "",
    class1: "",
    state_of_origin: "",
    l_g_a: "",
    nationality: "",
    examination_date: "",
    date: "",
    first_name: "",
    examination_number: "",
    state_of_origin1: "",
    address: "",
    school: "",
    examination_number1: "",
    name1: "",
    mother_name: "",
    state_of_origin3: "",
    home_address1: "",
    telephone_address: "",
    last_school_attended: "",
    special_health_needs: "",
    date_of_birth1: "",
    occapation: "",
    status: "",
    section: "",
    admission_no: "",
    last_name: "",
    email_address: "",
    email: "",
    father_occupation: "",
    phone_number: "",
    mother_place_of_work: "",
    medical_condition: "",
    upload_transfer_certificate: "",
    school_location: "",
  };
  interface Parent {
    fullname: string;
    phone_no: string;
    email_address: string;
    occupation: string;
    relationship: string;
    is_gurdian: string;
  }

  interface Subjects {
    label: string;
    value: string;
  }

  const initialParent: Parent = {
    fullname: "",
    phone_no: "",
    email_address: "",
    occupation: "",
    relationship: "",
    is_gurdian: "",
  };

  const [examSubjects, setExamSubjects] = useState<string[]>([]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const name = e.target.name;
    const value = e.target.value;
    setForm((p) => ({ ...p, [name]: value }));
    setExamSubjects((prevCheckedSubjects) => {
      if (isChecked) {
        // Add the subject if it's checked
        return [...prevCheckedSubjects, value];
      } else {
        // Remove the subject if it's unchecked
        return prevCheckedSubjects.filter((subject) => subject !== value);
      }
    });
  };

  const [form, setForm] = useState(_form);
  // const [parents, setParents] = useState(_parents);
  const [errors, setErrors] = useState({});
  const [new_data, setNewData] = useState([]);
  const navigate = useNavigate();

  const [parentList, setParentList] = useState<Parent[]>([]);

  // State for the current parent (individual Parent)
  const [parents, setParents] = useState<Parent>(initialParent);
  const handleParent = () => {
    setParents(initialParent);
  };
  // Function to add a new parent to the parentList
  const handleAdd = () => {
    setParentList((prev) => [
      ...prev,
      {
        ...parents,
        is_gurdian: parents.relationship === "Father" ? "Yes" : "No",
      },
    ]);
    handleParent();
  };

  const handleSubmit = () => {
    let finalParents = parentList;
    if (
      parents.email_address &&
      parents.fullname &&
      parents.phone_no &&
      parents.occupation &&
      parents.relationship
    ) {
      finalParents.push(parents);
    }
    _post(
      "secondary_school_entrance_form?query_type=create",
      { ...form, parents: finalParents, exam_subjects: examSubjects },
      (res) => {
        // if (res.success) {
        alert("Submitted successfully");
        navigate(-1);
        navigate("/application-no/application-no");
        // }
      },
      (err) => {
        // Handle error
        console.log(err);
      }
    );

    // Reset the form and errors after submission
    // setForm(_form);
    setErrors({});
  };

  const onChangeUpload = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  const onChangeMedicalCondition = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  const onChangeUploadTransferCertificate = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const handleParentChange = (e: any) => {
    setParents((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const handleChangesetParents = (e: any) => {
    setParents((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const routes = all_routes;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [owner, setOwner] = useState<string[]>(["English", "AgricScience"]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [defaultDate, setDefaultDate] = useState<dayjs.Dayjs | null>(null);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const location = useLocation();
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  // useEffect(() => {
  //   if (location.pathname === routes.editStudent) {
  //     const today = new Date();
  //     const year = today.getFullYear();
  //     const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  //     const day = String(today.getDate()).padStart(2, "0");
  //     const formattedDate = `${month}-${day}-${year}`;
  //     const defaultValue = dayjs(formattedDate);
  //     setIsEdit(true);
  //     setOwner(["English"]);
  //     setOwner1(["Medecine Name"]);
  //     setOwner2(["Allergy", "Skin Allergy"]);
  //     setDefaultDate(defaultValue);
  //     console.log(formattedDate, 11);
  //   } else {
  //     setIsEdit(false);
  //     setDefaultDate(null);
  //   }
  // }, [location.pathname]);

  const getSecScl = () => {
    _get(
      `get_admission_number_generator?query_type=select_type_of_school`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewData(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const [subjects, setSubjects] = useState<Subjects[]>([]);

  useEffect(() => {
    getSecScl();
    _get(
      "get_subject_management?query_type=select-subject",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubjects(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);
  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      render: (text: string) => (
        <Link to={routes.studentDetail} className="link-primary">
          {text}
        </Link>
      ),
    },

    {
      title: "Phone Number",
      dataIndex: "phone_no",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email_address",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          {/* <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.imgSrc}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link> */}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          {/* <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.imgSrc}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link> */}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
    },

    {
      title: "Relatinship",
      dataIndex: "relationship",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          {/* <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.imgSrc}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link> */}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
    },
  ];
  const handleAddRow = () => {};
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Student</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.studentList}>Students</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {isEdit ? "Edit" : "Add"} Student
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form>
                {/* Personal Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-info-square-rounded fs-16" />
                      </span>
                      <h4 className="text-dark">Personal Information</h4>
                    </div>
                  </div>
                  {/* {JSON.stringify(new_data)} */}
                  <div className="card-body pb-1">
                    <div className="row row-cols-xxl-5 row-cols-md-6">
                      <div className="profile-upload">
                        <div className="profile-uploader d-flex align-items-center">
                          <div className="drag-upload-btn mb-3">
                            Upload
                            <input
                              type="file"
                              className="form-control image-sign"
                              multiple
                              disabled={form.upload?.length > 10}
                              name="upload"
                              onChange={onChangeUpload}
                            />
                          </div>
                          <Link to="#" className="btn btn-primary mb-3">
                            Remove
                          </Link>
                        </div>
                        <p className="fs-12">
                          Upload image size 4MB, Format JPG, PNG, SVG
                        </p>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">School Location</label>
                          <CommonSelect
                            options={schoolLocation}
                            defaultValue={{
                              label: form.school_location,
                              value: form.school_location,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                school_location: newValue ? newValue.value : "",
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Type of Applicant:
                          </label>
                          <CommonSelect
                            className="select"
                            options={new_data.map((i: any) => ({
                              label: i.type_of_school,
                              value: i.type_of_school,
                            }))}
                            defaultValue={{
                              label: form.type_of_application,
                              value: form.type_of_application,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                type_of_application: newValue
                                  ? newValue.value
                                  : "",
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Name of Applicant:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name_of_applicant"
                            value={form.name_of_applicant}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Home Address:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="home_address"
                            value={form.home_address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Date of Birth:</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date_of_birth"
                            value={form.date_of_birth}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Sex</label>
                          <CommonSelect
                            options={gender}
                            defaultValue={{
                              label: form.sex,
                              value: form.sex,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                sex: newValue ? newValue.value : "",
                              }))
                            }
                          />
                        </div>
                      </div>

                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Religion:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="relationship1"
                            value={form.relationship1}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Tribe:</label>
                          <input
                            type="email"
                            className="form-control"
                            name="tribe"
                            value={form.tribe}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">School Attended:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="school_attended"
                            value={form.school_attended}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Last Class Attended:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="class1"
                            value={form.class1}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">State of Origin:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state_of_origin"
                            value={form.state_of_origin}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">L.G.A:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="l_g_a"
                            value={form.l_g_a}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Nationality:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="nationality"
                            value={form.nationality}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Last School Attended:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_school_attended"
                            value={form.last_school_attended}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Special Health Needs / Disability:
                          </label>
                          <CommonSelect
                            options={specialHealth}
                            defaultValue={{
                              label: form.special_health_needs,
                              value: form.special_health_needs,
                            }}
                            handleChange={(newValue: SingleValue<Option>) =>
                              setForm((prev) => ({
                                ...prev,
                                special_health_needs: newValue
                                  ? newValue.value
                                  : "",
                              }))
                            }
                          />
                        </div>
                      </div>

                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Last School Attended:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="caste"
                            value={form.caste}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Date Of Birth:</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date_of_birth1"
                            value={form.date_of_birth1}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* /Personal Information */}
                {/* Parents & Guardian Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-user-shield fs-16" />
                      </span>
                      <h4 className="text-dark">EXAMINATION TO BE TAKEN</h4>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <div className="border-bottom mb-3">
                      <div className="row">
                        <div className="col-xxl col-xl-3 col-md-6 d-flex align-items-center ">
                          <div className="status-title">
                            {/* <h5>Status</h5> */}
                            <p>English</p>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="checkbox"
                              type="checkbox"
                              value={"0"}
                              name={"english"}
                              id="switch-sm"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6 d-flex align-items-center ">
                          <div className="status-title">
                            {/* <h5>Status</h5> */}
                            <p>Mathematics </p>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="checkbox"
                              type="checkbox"
                              value={"0"}
                              name={"mathematics"}
                              id="switch-sm"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6 d-flex align-items-center ">
                          <div className="status-title">
                            {/* <h5>Status</h5> */}
                            <p>Others</p>
                          </div>
                          <div className="form-check form-switch">
                            {/* <input
                              className="checkbox"
                              type="checkbox"
                              id="switch-sm"
                              onChange={(e) => handleCheckboxChange(e)}
                            /> */}
                            <CommonSelect
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  other_score: newValue ? newValue.value : "",
                                  others:'0'
                                }))
                              }
                              options={subjects}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-user-shield fs-16" />
                      </span>
                      <h4 className="text-dark">
                        Parents &amp; Guardian Information
                      </h4>
                    </div>
                  </div>
                  {/* {JSON.stringify(parentList)} */}
                  <div className="card-body pb-0">
                    {/* <div className="border-bottom mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3"></div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="fullname"
                              value={parents.fullname}
                              onChange={handleChangesetParents}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone_no"
                              value={parents.phone_no}
                              onChange={handleChangesetParents}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email_address"
                              value={parents.email_address}
                              onChange={handleChangesetParents}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Occupation:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="occupation"
                              value={parents.occupation}
                              onChange={handleChangesetParents}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Relatinship</label>
                            <CommonSelect
                              options={relationship}
                              defaultValue={{
                                label: parents.relationship,
                                value: parents.relationship,
                              }}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setParents((prev) => ({
                                  ...prev,
                                  relationship: newValue ? newValue.value : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-xxl col-xl-3 col-md-6 d-flex align-items-center ">
                          <div className="form-check form-switch">
                            <input
                              className="checkbox"
                              type="checkbox"
                              id="switch-sm"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                          </div>

                          <div className="status-title">
                            <p>Is Guardian ? </p>
                          </div>
                        </div> 
                      </div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleAdd();
                        }}
                        className="btn btn-primary"
                      >
                        Add to List
                      </button>
                    </div> */}
                    <div className="card-body p-0 py-3">
                      {/* Student List */}
                      {/* <DataFormtable
                        dataSource={parentList}
                        setDataSource={setParentList}
                        columns={columns}
                        Selection={true}
                      /> */}
                      <table className="table datanew">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Email </th>
                            <th>Occupation </th>
                            <th>Relationship </th>
                            <th className="text-center"> + </th>
                          </tr>
                        </thead>
                        <tbody>
                          {parentList.length
                            ? parentList.map((x, idx) => (
                                <tr key={idx}>
                                  <td>{x.fullname}</td>
                                  <td>{x.phone_no}</td>
                                  <td>{x.email_address}</td>
                                  <td>{x.occupation}</td>
                                  <td>{x.relationship}</td>
                                </tr>
                              ))
                            : null}
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="fullname"
                                value={parents.fullname}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="phone_no"
                                value={parents.phone_no}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="email_address"
                                value={parents.email_address}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="occupation"
                                value={parents.occupation}
                                onChange={handleChangesetParents}
                              />
                            </td>
                            <td>
                              <CommonSelect
                                options={relationship}
                                defaultValue={{
                                  label: parents.relationship,
                                  value: parents.relationship,
                                }}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setParents((prev) => ({
                                    ...prev,
                                    relationship: newValue
                                      ? newValue.value
                                      : "",
                                  }))
                                }
                              />
                            </td>
                            <td className="text-center">
                              {" "}
                              {parents.email_address &&
                              parents.fullname &&
                              parents.phone_no &&
                              parents.occupation &&
                              parents.relationship &&
                              parentList.length < 1 ? (
                                <Button
                                  type="primary"
                                  onClick={handleAdd}
                                  style={{ marginTop: 2 }}
                                >
                                  Add +
                                </Button>
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* /Student List */}
                    </div>
                    {/* Documents */}
                    <div className="card">
                      <div className="card-header bg-light">
                        <div className="d-flex align-items-center">
                          <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                            <i className="ti ti-file fs-16" />
                          </span>
                          <h4 className="text-dark">Documents</h4>
                        </div>
                      </div>
                      <div className="card-body pb-1">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-2">
                              <div className="mb-3">
                                <label className="form-label mb-1">
                                  Medical Condition
                                </label>
                                <p>
                                  Upload image size of 4MB, Accepted Format PDF
                                </p>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                  <i className="ti ti-file-upload me-1" />
                                  Upload Document
                                  <input
                                    type="file"
                                    className="form-control image_sign"
                                    multiple
                                    name="medical_condition"
                                    disabled={
                                      form.medical_condition?.length > 10
                                    }
                                    onChange={onChangeMedicalCondition}
                                  />
                                </div>
                                {isEdit ? (
                                  <p className="mb-2">BirthCertificate.pdf</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-2">
                              <div className="mb-3">
                                <label className="form-label mb-1">
                                  Upload Transfer Certificate
                                </label>
                                <p>
                                  Upload image size of 4MB, Accepted Format PDF
                                </p>
                              </div>
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="btn btn-primary drag-upload-btn mb-2">
                                  <i className="ti ti-file-upload me-1" />
                                  Upload Document
                                  <input
                                    type="file"
                                    className="form-control image_sign"
                                    multiple
                                    name="upload_transfer_certificate"
                                    disabled={
                                      form.upload_transfer_certificate?.length >
                                      10
                                    }
                                    onChange={onChangeUploadTransferCertificate}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="border-bottom mb-3"> */}
                    {/* <h5 className="mb-3">Mother’s Info</h5> */}
                    {/* <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3"></div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mother Name:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_name"
                              value={form.mother_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              State of Origin (Mother):
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="state_of_origin3"
                              value={form.state_of_origin3}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Place of Work:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_place_of_work"
                              value={form.mother_place_of_work}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Occupation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mother_occupation"
                              value={form.mother_occupation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div> */}
                    {/* </div> */}
                    {/* <div>
                      <h5 className="mb-3">Guardian Details</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Guardian Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_name"
                              value={form.guardian_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Guardian Relation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_relation"
                              value={form.guardian_relation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_phone_number"
                              value={form.guardian_phone_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              name="guardian_email"
                              value={form.guardian_email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Occupation</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_occupation"
                              value={form.guardian_occupation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              name="guardian_address"
                              value={form.guardian_address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* /Previous School details */}
                {/* Other Details */}

                {/* /Other Details */}
                <div className="text-end">
                  <button type="button" className="btn btn-light me-3">
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    className="btn btn-primary"
                  >
                    Add ApplicationList
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AddApplication;
