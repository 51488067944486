import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import Login from "../auth/login/login";
import { init } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { all_routes } from "./all_routes";
import { RootState } from "../../redux/store";

const ALLRoutes: React.FC = () => {
  const {authenticated, user} = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Run initialization only if the user is not authenticated
    if (!authenticated) {
      dispatch(
        init(
          navigate,
          () => {
            if (location.pathname.includes("login") || location.pathname === "/") {
              navigate(location.pathname); // Redirect to dashboard
            }
          },
          () => {
            toast.error("Authentication is required");
            if (location.pathname.includes("login")||location.pathname.includes("application")) {
              navigate(location.pathname); // Redirect to dashboard
            }else{
              navigate(all_routes.login); // Redirect to login
            }
           
          }
        )
      );
    }
  }, [authenticated, dispatch, navigate]); // Avoid adding location.pathname to prevent looping

  return (
    <>
      {/* {JSON.stringify({ user })} */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<Feature />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>

        <Route element={<AuthFeature />}>
          {authRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default ALLRoutes;