import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import {
  classduration,
  classSection,
  classSylabus,
  language,
  period,
  routinename,
  schoolLocation,
  subjectGroup,
  teacher,
  Time,
  Timeto,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import { SingleValue } from "react-select";
import { _get, _post } from "../../Utils/Helper";
import { toast } from "react-toastify";
import TimetableCards from "./TimetableCards";
// import TimetableCards from "./TimetableCards.tsx";

const ClassTimetable = () => {
  const routes = all_routes;
  interface FormData {
    class_name: string;
    section: string;
    subject: string;
    teacher_id: string;
    school_location: string;
    day: string;
    time: string;
    period: string;
    duration: string;
    routinename: string;
    subjectClasses: string;
  }
  const _form = {
    class_name: "",
    section: "",
    subject: "",
    teacher_id: "",
    school_location: "",
    day: "Monday",
    time: "",
    period: "",
    duration: "",
    routinename: "",
    subjectClasses: "",
    query_type: "create",
  };
  interface Subjects {
    label: string;
    value: string;
  }
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const [form, setForm] = useState(_form);
  const [filter, setFilter] = useState({
    class_name: "",
    section: ""
  });
  const [sections, setSections] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [timetable, setTimetable] = useState([]);

  useEffect(() => {
    _get(
      "get_teacher_personal_data_form?query_type=select-all",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setTeachers(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);

  const teacherOptions = teachers?.map((i: any) => ({
    label: i.name,
    value: i.id,
  }));

  useEffect(() => {
    _get(
      "subjects?query_type=select-all",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubjects(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
  }, [0]);
  const subjectOptions = subjects?.map((i: any) => ({
    label: i.subject,
    value: i.subject,
  }));
  const handleSubmit = () => {
    _post(
      "lesson_time_table?query_type=create",
      timeTableList,
      (res) => {
        if (res.success) {
          toast.success("Submitted successfully");
          console.log(form);
          setForm(_form);
          // navigate(-1);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    _get(
      `classes?query_type=select-section-classes&section=${form.section}`,
      (res) => {
        console.log(res);
        setClasses(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    _get(
      `lesson_time_table?query_type=select-all&section=${form.section}`,
      (res) => {
        if (res.success) {
          setTimetable(res.data)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [form.section]);

  const getSections = () => {
    _get(
      `classes?query_type=select-sections`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSections(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  useEffect(() => {
    getSections();
  }, []);

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const [classes, setClasses] = useState([]);
  const classOptions = classes?.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  }));

  const mapped_section = sections?.map((i: any) => ({
    label: i.section,
    value: i.section,
  }));

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const [newContents, setNewContents] = useState<number[]>([0]);
  const [tuesdayContents, settuesdayContents] = useState<number[]>([0]);
  const [wednessdayContents, setwednessdayContents] = useState<number[]>([0]);
  const [thursdayContents, setthursdayContents] = useState<number[]>([0]);
  const [fridayContents, setfridayContents] = useState<number[]>([0]);
  const [timeTableList, setTimeTableList] = useState<FormData[]>([]);

  const addNewContent = () => {
    setTimeTableList((p) => [...p, form]);
    setNewContents([...newContents, newContents.length]);
    setForm((p) => ({ ..._form, day: p.day }));
  };
  const addTuesdayContent = () => {
    settuesdayContents([...tuesdayContents, tuesdayContents.length]);
    setTimeTableList((p) => [...p, form]);
    setForm((p) => ({ ..._form, day: p.day }));
  };
  const addwednessdayContent = () => {
    setwednessdayContents([...wednessdayContents, wednessdayContents.length]);
    setTimeTableList((p) => [...p, form]);
    setForm((p) => ({ ..._form, day: p.day }));
  };
  const addthursdayContents = () => {
    setthursdayContents([...thursdayContents, thursdayContents.length]);
    setTimeTableList((p) => [...p, form]);
    setForm((p) => ({ ..._form, day: p.day }));
  };
  const addfridayContents = () => {
    setfridayContents([...fridayContents, fridayContents.length]);
    setTimeTableList((p) => [...p, form]);
    setForm((p) => ({ ..._form, day: p.day }));
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  const removetuesdayContent = (index: any) => {
    settuesdayContents(tuesdayContents.filter((_, i) => i !== index));
  };
  const removewednessdayContent = (index: any) => {
    setwednessdayContents(wednessdayContents.filter((_, i) => i !== index));
  };
  const removethursdayContents = (index: any) => {
    setthursdayContents(thursdayContents.filter((_, i) => i !== index));
  };
  const removefridayContents = (index: any) => {
    setfridayContents(fridayContents.filter((_, i) => i !== index));
  };

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Time Table</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">Academic</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Time Table
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_time_table"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Time Table
                </Link>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Time Table</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 pb-0 border-bottom">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <CommonSelect
                                className="select"
                                options={mapped_section}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setFilter((prev) => ({
                                    ...prev,
                                    section: newValue ? newValue.value : "",
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classOptions}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setFilter((prev) => ({
                                    ...prev,
                                    class_name: newValue ? newValue.value : "",
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <TimetableCards data={timetable} />
            <div className="card-footer border-0 pb-0">
              <div className="row">
                <div className="col-lg-4 col-xxl-4 col-xl-4 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <span className="bg-primary badge badge-sm mb-2">
                        Morning Break
                      </span>
                      <p className="text-dark">
                        <i className="ti ti-clock me-1" />
                        10:30 to 10 :45 AM
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xxl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <span className="bg-warning badge badge-sm mb-2">
                        Lunch
                      </span>
                      <p className="text-dark">
                        <i className="ti ti-clock me-1" />
                        10:30 to 10 :45 AM
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xxl-3 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <span className="bg-info badge badge-sm mb-2">
                        Evening Break
                      </span>
                      <p className="text-dark">
                        <i className="ti ti-clock me-1" />
                        03:30 PM to 03:45 PM
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <>
        {/* Add Class Time Table */}
        <div className="modal fade" id="add_time_table">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Time Table</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    {/* {JSON.stringify({ timeTableList })} */}
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">School Location</label>
                        <CommonSelect
                          options={schoolLocation}
                          defaultValue={{
                            label: form.school_location,
                            value: form.school_location,
                          }}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              school_location: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={mapped_section}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              section: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Class</label>
                        <CommonSelect
                          className="select"
                          options={classOptions}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              class_name: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Subject Group</label>
                        <CommonSelect
                          className="select"
                          options={subjectGroup}
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Period Start Time</label>
                        <CommonSelect className="select" options={period} />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Duration(min)</label>

                        <CommonSelect
                          className="select"
                          options={classduration}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="add-more-timetable">
                    <ul
                      className="tab-links nav nav-pills"
                      id="pills-tab2"
                      role="tablist"
                    >
                      <li
                        className="nav-link active"
                        id="pills-monday-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-monday"
                        role="tab"
                        aria-controls="pills-monday"
                        aria-selected="true"
                      >
                        <Link
                          onClick={() => {
                            setForm((p) => ({ ...p, day: "Monday" }));
                          }}
                          to="#"
                        >
                          Monday
                        </Link>
                      </li>
                      <li
                        className="nav-link"
                        id="pills-tuesday-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-tuesday"
                        role="tab"
                        aria-controls="pills-tuesday"
                        aria-selected="false"
                        tabIndex={-1}
                      >
                        <Link
                          onClick={() => {
                            setForm((p) => ({ ...p, day: "Tuesday" }));
                          }}
                          to="#"
                        >
                          Tuesday
                        </Link>
                      </li>
                      <li
                        className="nav-link"
                        id="pills-wednesday-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-wednesday"
                        role="tab"
                        aria-controls="pills-wednesday"
                        aria-selected="false"
                        tabIndex={-1}
                      >
                        <Link
                          onClick={() => {
                            setForm((p) => ({ ...p, day: "Wednesday" }));
                          }}
                          to="#"
                        >
                          Wednesday
                        </Link>
                      </li>
                      <li
                        className="nav-link"
                        id="pills-thursday-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-thursday"
                        role="tab"
                        aria-controls="pills-thursday"
                        aria-selected="false"
                        tabIndex={-1}
                      >
                        <Link
                          onClick={() => {
                            setForm((p) => ({ ...p, day: "Thursday" }));
                          }}
                          to="#"
                        >
                          Thursday
                        </Link>
                      </li>
                      <li
                        className="nav-link"
                        id="pills-friday-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-friday"
                        role="tab"
                        aria-controls="pills-friday"
                        aria-selected="false"
                        tabIndex={-1}
                      >
                        <Link
                          onClick={() => {
                            setForm((p) => ({ ...p, day: "Friday" }));
                          }}
                          to="#"
                        >
                          Friday
                        </Link>
                      </li>
                    </ul>
                    {/* {Days.map((day, index) => ( */}
                    <div className="tab-content pt-0 dashboard-tab">
                      <div
                        className="tab-pane fade show active"
                        id="pills-monday"
                        role="tabpanel"
                        aria-labelledby="pills-monday-tab"
                      >
                        {newContents.map((_, index) => (
                          <div className="add-timetable-row">
                            <div className="row timetable-count">
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Subject</label>
                                  <CommonSelect
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        subject: newValue ? newValue.value : "",
                                      }))
                                    }
                                    options={subjectOptions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Teacher</label>
                                  <CommonSelect
                                    className="select"
                                    options={teacherOptions}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        teacher_id: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Time From
                                  </label>
                                  <CommonSelect
                                    className="select"
                                    options={Time}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        start_time: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="d-flex align-items-end">
                                  <div className="mb-3 flex-fill">
                                    <label className="form-label">
                                      Time To
                                    </label>
                                    <CommonSelect
                                      className="select"
                                      options={Timeto}
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setForm((prev) => ({
                                          ...prev,
                                          end_time: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                    />
                                  </div>
                                  {newContents.length > 1 && (
                                    <div className="mb-3 ms-2">
                                      <Link
                                        to="#"
                                        className="delete-time-table"
                                        onClick={() => removeContent(index)}
                                      >
                                        <i className="ti ti-trash" />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link
                            to="#"
                            className="btn btn-primary add-new-timetable"
                            onClick={addNewContent}
                          >
                            <i className="ti ti-square-rounded-plus-filled me-2" />
                            Add New
                          </Link>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-tuesday"
                        role="tabpanel"
                        aria-labelledby="pills-tuesday-tab"
                      >
                        {tuesdayContents.map((_, index) => (
                          <div className="add-timetable-row">
                            <div className="row timetable-count">
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Subject</label>
                                  <CommonSelect
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        subject: newValue ? newValue.value : "",
                                      }))
                                    }
                                    options={subjectOptions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Teacher</label>
                                  <CommonSelect
                                    className="select"
                                    options={teacherOptions}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        teacher_id: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Time From
                                  </label>
                                  <CommonSelect
                                    className="select"
                                    options={Time}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        start_time: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="d-flex align-items-end">
                                  <div className="mb-3 flex-fill">
                                    <label className="form-label">
                                      Time To
                                    </label>
                                    <CommonSelect
                                      className="select"
                                      options={Timeto}
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setForm((prev) => ({
                                          ...prev,
                                          end_time: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                    />
                                  </div>
                                  {tuesdayContents.length > 1 && (
                                    <div className="mb-3 ms-2">
                                      <Link
                                        to="#"
                                        className="delete-time-table"
                                        onClick={() =>
                                          removetuesdayContent(index)
                                        }
                                      >
                                        <i className="ti ti-trash" />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link
                            to="#"
                            onClick={addTuesdayContent}
                            className="btn btn-primary add-new-timetable"
                          >
                            <i className="ti ti-square-rounded-plus-filled me-2" />
                            Add New
                          </Link>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-wednesday"
                        role="tabpanel"
                        aria-labelledby="pills-wednesday-tab"
                      >
                        {wednessdayContents.map((_, index) => (
                          <div className="add-timetable-row">
                            <div className="row timetable-count">
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Subject</label>
                                  <CommonSelect
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        subject: newValue ? newValue.value : "",
                                      }))
                                    }
                                    options={subjectOptions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Teacher</label>
                                  <CommonSelect
                                    className="select"
                                    options={teacherOptions}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        teacher_id: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Time From
                                  </label>
                                  <CommonSelect
                                    className="select"
                                    options={Time}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        start_time: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="d-flex align-items-end">
                                  <div className="mb-3 flex-fill">
                                    <label className="form-label">
                                      Time To
                                    </label>
                                    <CommonSelect
                                      className="select"
                                      options={Timeto}
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setForm((prev) => ({
                                          ...prev,
                                          end_time: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                    />
                                  </div>
                                  {wednessdayContents.length > 1 && (
                                    <div className="mb-3 ms-2">
                                      <Link
                                        to="#"
                                        className="delete-time-table"
                                        onClick={() =>
                                          removewednessdayContent(index)
                                        }
                                      >
                                        <i className="ti ti-trash" />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link
                            to="#"
                            className="btn btn-primary add-new-timetable"
                            onClick={addwednessdayContent}
                          >
                            <i className="ti ti-square-rounded-plus-filled me-2" />
                            Add New
                          </Link>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-thursday"
                        role="tabpanel"
                        aria-labelledby="pills-thursday-tab"
                      >
                        {thursdayContents.map((_, index) => (
                          <div className="add-timetable-row">
                            <div className="row timetable-count">
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Subject</label>
                                  <CommonSelect
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        subject: newValue ? newValue.value : "",
                                      }))
                                    }
                                    options={subjectOptions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Teacher</label>
                                  <CommonSelect
                                    className="select"
                                    options={teacherOptions}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        teacher_id: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Time From
                                  </label>
                                  <CommonSelect
                                    className="select"
                                    options={Time}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        start_time: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="d-flex align-items-end">
                                  <div className="mb-3 flex-fill">
                                    <label className="form-label">
                                      Time To
                                    </label>
                                    <CommonSelect
                                      className="select"
                                      options={Timeto}
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setForm((prev) => ({
                                          ...prev,
                                          end_time: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                    />
                                  </div>
                                  {thursdayContents.length > 1 && (
                                    <div className="mb-3 ms-2">
                                      <Link
                                        to="#"
                                        className="delete-time-table"
                                        onClick={() =>
                                          removethursdayContents(index)
                                        }
                                      >
                                        <i className="ti ti-trash" />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link
                            to="#"
                            className="btn btn-primary add-new-timetable"
                            onClick={addthursdayContents}
                          >
                            <i className="ti ti-square-rounded-plus-filled me-2" />
                            Add New
                          </Link>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-friday"
                        role="tabpanel"
                        aria-labelledby="pills-friday-tab"
                      >
                        {fridayContents.map((_, index) => (
                          <div className="add-timetable-row">
                            <div className="row timetable-count">
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Subject</label>
                                  <CommonSelect
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        subject: newValue ? newValue.value : "",
                                      }))
                                    }
                                    options={subjectOptions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">Teacher</label>
                                  <CommonSelect
                                    className="select"
                                    options={teacherOptions}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        teacher_id: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Time From
                                  </label>
                                  <CommonSelect
                                    className="select"
                                    options={Time}
                                    handleChange={(
                                      newValue: SingleValue<Option>
                                    ) =>
                                      setForm((prev) => ({
                                        ...prev,
                                        start_time: newValue
                                          ? newValue.value
                                          : "",
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="d-flex align-items-end">
                                  <div className="mb-3 flex-fill">
                                    <label className="form-label">
                                      Time To
                                    </label>
                                    <CommonSelect
                                      className="select"
                                      options={Timeto}
                                      handleChange={(
                                        newValue: SingleValue<Option>
                                      ) =>
                                        setForm((prev) => ({
                                          ...prev,
                                          end_time: newValue
                                            ? newValue.value
                                            : "",
                                        }))
                                      }
                                    />
                                  </div>
                                  {fridayContents.length > 1 && (
                                    <div className="mb-3 ms-2">
                                      <Link
                                        to="#"
                                        className="delete-time-table"
                                        onClick={() =>
                                          removefridayContents(index)
                                        }
                                      >
                                        <i className="ti ti-trash" />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div>
                          <Link
                            to="#"
                            className="btn btn-primary add-new-timetable"
                            onClick={addfridayContents}
                          >
                            <i className="ti ti-square-rounded-plus-filled me-2" />
                            Add New
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* ))} */}
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Add Time Table
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Class Time Table */}
      </>
    </div>
  );
};

export default ClassTimetable;
