import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  // CardBody,
  // CardHeader,
  Col,
  Container,
  // Input,
  // Label,
  Row,
} from "react-bootstrap";
// import { _get, _put } from "../Utils/Helper";
import { useParams, useNavigate } from "react-router-dom";
import { _get, _put } from "../../Utils/Helper";
import { Input, Button } from "antd";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { SingleValue } from "react-select";
// import { Input } from "antd";

export default function Score() {
  const id = useParams().id;
  const navigate = useNavigate(); // Import and define navigate here
  interface FormType {
    query_type: string;
    mathematics: number;
    english: number;
    other_score: number;
    admission_no: string;
    name_of_applicant: string;
    class1: string;
    others: string;
  }
  const _form: FormType = {
    query_type: "update_current_class",
    mathematics: 0,
    english: 0,
    other_score: 0,
    admission_no: "",
    name_of_applicant: "",
    class1: "",
    others: "",
  };

  const [form, setForm] = useState(_form);
  const [errors, setErrors] = useState({});
  const [statusColor, setStatusColor] = useState("#0d6efd");
  const [statusText, setStatusText] = useState("Status");
  const [new_data, setNewData] = useState([]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    _put(
      "update_secondary_school_entrance_form",
      { ...form, id, query_type: "update-score", statusText },
      (res) => {
        // Handle success
        alert(JSON.stringify(res));
        console.log(res);

        if (statusText === "Pass") {
          navigate(`/entrance-exams-score/entrance-exams-score`);
        } else if (statusText === "Failed") {
          navigate(`/scorefeil/${id}`);
        }
      },
      (err) => {
        // Handle error
        console.log(err);
      }
    );
  };

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: parseFloat(e.target.value) }));
  };

  const getSecScl = useCallback(() => {
    _get(
      `get_secondary_school_entrance_form?id=${id}&query_type=select_id`,
      (res) => {
        console.log(res);
        if (res.results.length) {
          setForm(res.results[0]);
        }
      },
      (err) => {
        // Handle error response
        console.log(err);
      }
    );
  }, [id]);
  const getSelectClassName = () => {
    _get(
      `get_class_management?query_type=select_class_name`,
      (res) => {
        setNewData(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getSecScl();
    getSelectClassName();
  }, [getSecScl]);

  useEffect(() => {
    const mathematicsScore: number = form.mathematics || 0;
    const englishScore: number = form.english || 0;
    const othersScore: number = form.other_score || 0;
    const averageScore = (mathematicsScore + englishScore + othersScore) / 3;
    console.log(averageScore);
    if (averageScore <= 49) {
      setStatusColor("#dc3545");
      setStatusText("Failed");
    } else if (averageScore >= 50 && averageScore <= 100) {
      setStatusColor("#198754");
      setStatusText("Pass");
    }
  }, [form.mathematics, form.english, form.other_score]);

  return (
    <div>
      {/* <Container> */}
      <div className="page-wrapper">
        <div className="content content-two"></div>
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="card">
                <div className="card-header bg-light">
                  <div className="d-flex align-items-center">
                    <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                      <i className="ti ti-info-square-rounded fs-16" />
                    </span>
                    <h4 className="text-dark">Entrance Exams Score</h4>
                  </div>
                </div>

                {/* <center>
                  <CardHeader
                    style={{ color: "#fff", backgroundColor: "#5295fa" }}
                  >
                    Score
                  </CardHeader>
                </center> */}
                <CardBody>
                  <Row>
                    {/* {JSON.stringify(form)} */}
                    <Col md={4}>
                      <p>
                        <b>Application: No.</b> {form.admission_no}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Name:</b> {form.name_of_applicant}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Class:</b> {form.class1}
                      </p>
                    </Col>
                  </Row>

                  <div className="col-xxl col-xl-3 col-md-6">
                    <div className="mb-3">
                      <label>Mathematics</label>
                      <Input
                        type="number"
                        name="mathematics"
                        value={form.mathematics}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-xxl col-xl-3 col-md-6">
                    <div className="mb-3">
                      <label>English Language</label>
                      <Input
                        type="number"
                        name="english"
                        value={form.english}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-xxl col-xl-3 col-md-6">
                    <div className="mb-3">
                      <label>{form.others}</label>
                      <Input
                        type="number"
                        name="other_score"
                        value={form.other_score}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-xxl col-xl-3 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Assign Class</label>
                      <CommonSelect
                        className="select"
                        options={new_data.map((i: any) => ({
                          label: i.class_name,
                          value: i.class_name,
                        }))}
                        handleChange={(newValue: SingleValue<Option>) =>
                          setForm((prev) => ({
                            ...prev,
                            current_class: newValue ? newValue.value : "",
                          }))
                        }
                      />
                    </div>
                  </div> */}
                  <Col md={4} className="mt-3">
                    <Button style={{ backgroundColor: statusColor }}>
                      {statusText}
                    </Button>
                  </Col>
                  <center>
                    <Button
                      style={{ width: "5rem", background: "#5295fa" }}
                      className="mt-3"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </center>
                </CardBody>
              </div>
            </form>
          </div>
        </div>
        {/* </Container> */}
      </div>
    </div>
  );
}
