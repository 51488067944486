export const Studentlist = [
  {
    key: "1",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892434",
    RollNo: "35013",
    name: "Janet",
    class: "III",
    section: "A",
    gender: "Female",
    status: "Active",
    DateofJoin: "25 Mar 2024",
    DOB: "10 Jan 2015",
    imgSrc: "assets/img/students/student-01.jpg",
  },
  {
    key: "2",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892433",
    RollNo: "35013",
    name: "Joann",
    class: "IV",
    section: "B",
    gender: "Male",
    status: "Active",
    DateofJoin: "18 Mar 2024",
    DOB: "19 Aug 2014",
    imgSrc: "assets/img/students/student-02.jpg",
  },
  {
    key: "3",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892432",
    RollNo: "35011",
    name: "Kathleen",
    class: "II",
    section: "A",
    gender: "Female",
    status: "Active",
    DateofJoin: "14 Mar 2024",
    DOB: "05 Dec 2017",
    imgSrc: "assets/img/students/student-03.jpg",
  },
  {
    key: "4",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892431",
    RollNo: "35010",
    name: "Gifford",
    class: "I",
    section: "B",
    gender: "Male",
    status: "Active",
    DateofJoin: "27 Feb 2024",
    DOB: "22 Mar 2018",
    imgSrc: "assets/img/students/student-04.jpg",
  },
  {
    key: "5",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892430",
    RollNo: "35009",
    name: "Lisa",
    class: "II",
    section: "B",
    gender: "Female",
    status: "Inactive",
    DateofJoin: "13 Feb 2024",
    DOB: "13 May 2017",
    imgSrc: "assets/img/students/student-05.jpg",
  },
  {
    key: "6",
    result: "Fail",
    promotion: 0,
    AdmissionNo: "AD9892429",
    RollNo: "35008",
    name: "Ralph",
    class: "III",
    section: "B",
    gender: "Male",
    status: "Active",
    DateofJoin: "11 Feb 2024",
    DOB: "20 Jun 2015",
    imgSrc: "assets/img/students/student-06.jpg",
  },
  {
    key: "7",
    result: "Fail",
    promotion: 0,
    AdmissionNo: "AD9892428",
    RollNo: "35007",
    name: "Julie",
    class: "V",
    section: "A",
    gender: "Female",
    status: "Active",
    DateofJoin: "24 Jan 2024",
    DOB: "18 Sep 2013",
    imgSrc: "assets/img/students/student-07.jpg",
  },
  {
    key: "8",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892427",
    RollNo: "35006",
    name: "Ryan",
    class: "VI",
    section: "A",
    gender: "Male",
    status: "Active",
    DateofJoin: "19 Jan 2024",
    DOB: "26 Nov 2012",
    imgSrc: "assets/img/students/student-08.jpg",
  },
  {
    key: "9",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892426",
    RollNo: "35005",
    name: "Susan",
    class: "VIII",
    section: "B",
    gender: "Female",
    status: "Inactive",
    DateofJoin: "08 Jan 2024",
    DOB: "26 May 2010",
    imgSrc: "assets/img/students/student-09.jpg",
  },
  {
    key: "10",
    result: "Fail",
    promotion: 0,
    AdmissionNo: "AD9892425",
    RollNo: "35004",
    name: "Richard",
    class: "VII",
    section: "B",
    gender: "Male",
    status: "Active",
    DateofJoin: "22 Dec 2024",
    DOB: "06 Oct 2011",
    imgSrc: "assets/img/students/student-10.jpg",
  },
  {
    key: "11",
    result: "Pass",
    promotion: 1,
    AdmissionNo: "AD9892424",
    RollNo: "35003",
    name: "Veronica",
    class: "IX",
    section: "A",
    gender: "Female",
    status: "Active",
    DateofJoin: "15 Dec 2024",
    DOB: "27 Dec 2009",
    imgSrc: "assets/img/students/student-11.jpg",
  },
];
