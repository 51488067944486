import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Select, InputNumber, Button } from "antd";
import { _get } from "../../Utils/Helper";
import { academicYear } from "../../../core/common/selectoption/selectoption";

// Define the props type for the component
interface FeesSetupModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (formData: FeeFormValues) => void;
  initialValues?: FeeFormValues; // Include initialValues to support editing
  onDelete?: (id: string) => void; // Callback for handling deletion
}

interface Class {
  id: string;
  class_group: string;
  level: string;
  section: string;
}

// Define the type for the form values
interface FeeFormValues {
  id: string;
  description: string;
  due_date: string;
  amount: string;
  section: string;
  code: string;
  payment_type: string;
  term: string;
  year: string;
  class_level: string;
}

const FeesSetupModal: React.FC<FeesSetupModalProps> = ({
  visible,
  onClose,
  onSubmit,
  initialValues,
  onDelete
}) => {
  const [form] = Form.useForm();

  const [classes, setClasses] = useState<Class[]>([]);

  useEffect(() => {
    // Fetch available classes for selection
    _get(`classes?query_type=select-unique-classes`, 
      (res) => {
        setClasses(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  // Extract unique sections from the classes data
  const unique_sections: string[] = [...new Set(classes.map((item: Class) => item.section))];

  // Populate the form fields with the initial values when editing
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues); // Populate the form fields with data for editing
    }
  }, [initialValues, form]);

  const handleFinish = (values: FeeFormValues) => {
    onSubmit(values); // Pass the form data to the parent component
    form.resetFields(); // Clear the form
    onClose(); // Close the modal
  };

  const handleDelete = () => {
    if (initialValues && onDelete) {
      // If an ID is available in initialValues, perform delete action
      onDelete(initialValues.code);
      onClose(); // Close the modal after deletion
    }
  };

  return (
    <Modal
      title={initialValues ? "Edit Fee Details" : "Setup Fee Details"}
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          discount: 0,
          fine: 0,
        }}
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Description is required" }]}
        >
          <Input placeholder="Enter fee description" />
        </Form.Item>
        <Form.Item
          label="Payment Type"
          name="payment_type"
          rules={[{ required: true, message: "Payment Type is required" }]}
        >
          <Select placeholder="Select Payment Type">
            <Select.Option value="Fee">Fee</Select.Option>
            <Select.Option value="Fine">Fine</Select.Option>
            <Select.Option value="Sale">Sale</Select.Option>
            <Select.Option value="Earning">Earning</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Amount (NGN)"
          name="amount"
          rules={[{ required: true, message: "Amount is required" }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter amount"
          />
        </Form.Item>

        <Form.Item
          label="Section"
          name="section"
          rules={[{ required: true, message: "Section is required" }]}
        >
          <Select placeholder="Select Section">
            <Select.Option value="0">General</Select.Option>
            {unique_sections?.map((section) => (
              <Select.Option value={section} key={section}>
                {section}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Class Group"
          name="class_level"
          rules={[{ required: true, message: "Class Group is required" }]}
        >
          <Select placeholder="Select Class Group">
            <Select.Option value="0">General</Select.Option>
            {classes?.map((classLevel) => (
              <Select.Option key={classLevel.class_group} value={classLevel.level}>
                {classLevel.class_group}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Period"
          name="term"
          rules={[{ required: true, message: "Period is required" }]}
        >
          <Select placeholder="Select Term">
            <Select.Option value="Per Annum">Per Annum</Select.Option>
            <Select.Option value="First Term">First Term</Select.Option>
            <Select.Option value="Second Term">Second Term</Select.Option>
            <Select.Option value="Third Term">Third Term</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Academic Year"
          name="year"
          rules={[{ required: true, message: "Academic Year is required" }]}
        >
          <Select placeholder="Select Academic Year">
            <Select.Option value="0">General</Select.Option>
            {academicYear?.map((Year) => (
              <Select.Option value={Year.label} key={Year.label}>
                {Year.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Due Date"
          name="due_date"
          rules={[{ required: true, message: "Due date is required" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>

        {initialValues && (
          <Form.Item>
            <Button
              color="danger"
              onClick={handleDelete}
              style={{ width: "100%" }}
            >
              Delete
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default FeesSetupModal;
