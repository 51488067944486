import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import useQuery, { _get } from "../../../Utils/Helper";

const StudentSidebar = () => {
  interface Student {
    full_name: string;
    home_address: string;
    date_of_birth: string;
    sex: string;
    student_name: string;
    mother_tongue: string;
    school_attended: string;
    class_name: string;
    l_g_a: string;
    nationality: string;
    status: string;
    blood_group: string;
  }
  const StudentData: Student = {
    full_name: "",
    home_address: "",
    date_of_birth: "",
    sex: "",
    student_name: "",
    mother_tongue: "",
    school_attended: "",
    class_name: "",
    l_g_a: "",
    nationality: "",
    status: "",
    blood_group: "",
  };
  // const [parent, setParent] = useState(_parent);
  // const [new_data, setNewData] = useState(_new_data);
  const id = useParams().id;
  const q = useQuery();
  const student_id = q.get('student_id')
  const [student, setStudent] = useState<Student>(StudentData)

  const [payments, setPayments] = useState([])

  useEffect(() => {
    _get(
      `students?query_type=select&id=${student_id}`,
      (res) => {
        setStudent(res.data[0]);
      },
      (err) => {
        console.log(err);
      }
    );
  },[]);

  return (
    <div className="col-xxl-3 col-xl-4 theiaStickySidebar">
      <div className="stickybar pb-4">
        <div className="card border-white">
          <div className="card-header">
            <div className="d-flex align-items-center flex-wrap row-gap-3">
              <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                <ImageWithBasePath
                  src="assets/img/students/student-01.jpg"
                  className="img-fluid"
                  alt="img"
                />
              </div>
              <div className="overflow-hidden">
                <span className="badge badge-soft-success d-inline-flex align-items-center mb-1">
                  <i className="ti ti-circle-filled fs-5 me-1" />
                  {student.status}
                </span>
                <h5 className="mb-1 text-truncate">
                  {student.student_name}
                </h5>
                <p className="text-primary">{student.student_name}</p>
              </div>
            </div>
          </div>
          {/* Basic Information */}
          <div className="card-body">
            {JSON.stringify(student)}
            <h5 className="mb-3">Basic Information</h5>
            <dl className="row mb-0">
              <dt className="col-6 fw-medium text-dark mb-3">
                Student Name:
              </dt>
              <dd className="col-6 mb-3">{student.full_name}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Home Address:</dt>
              <dd className="col-6 mb-3">{student.home_address}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Date of Birth:</dt>
              <dd className="col-6 mb-3">{student.date_of_birth}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Sex:</dt>
              <dd className="col-6 mb-3">{student.sex}</dd>

              <dt className="col-6 fw-medium text-dark mb-3">Blood group:</dt>
              <dd className="col-6 mb-3">{student.blood_group}</dd>
              
              <dt className="col-6 fw-medium text-dark mb-3">Mother Tongue:</dt>
              <dd className="col-6 mb-3">{student.mother_tongue}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">
                School Attended:
              </dt>
              <dd className="col-6 mb-3">{student.school_attended}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">class:</dt>
              <dd className="col-6 mb-3">{student.class_name}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">L.G.A:</dt>
              <dd className="col-6 mb-3">{student.l_g_a}</dd>
              <dt className="col-6 fw-medium text-dark mb-3">Nationality:</dt>
              <dd className="col-6 mb-3">
                <dd className="col-6 mb-3">{student.nationality}</dd>
              </dd>
            </dl>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add_fees_collect"
              className="btn btn-primary btn-sm w-100"
            >
              Add Fees
            </Link>
          </div>
          {/* /Basic Information */}
        </div>
        {/* Primary Contact Info */}
        <div className="card border-white">
          <div className="card-body">
            <h5 className="mb-3">Primary Contact Info</h5>
            <div className="d-flex align-items-center mb-3">
              <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                <i className="ti ti-phone" />
              </span>
              <div>
                <span className="text-dark fw-medium mb-1">Phone Number</span>
                <p>+1 46548 84498</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                <i className="ti ti-mail" />
              </span>
              <div>
                <span className="text-dark fw-medium mb-1">Email Address</span>
                <p>jan@example.com</p>
              </div>
            </div>
          </div>
        </div>
        {/* /Primary Contact Info */}
        {/* Sibiling Information */}
        {/* <div className="card border-white">
          <div className="card-body">
            <h5 className="mb-3">Sibiling Information</h5>
            <div className="d-flex align-items-center bg-light-300 rounded p-3 mb-3">
              <span className="avatar avatar-lg">
                <ImageWithBasePath
                  src="assets/img/students/student-06.jpg"
                  className="img-fluid rounded"
                  alt="img"
                />
              </span>
              <div className="ms-2">
                <h5 className="fs-14">Ralph Claudia</h5>
                <p>III, B</p>
              </div>
            </div>
            <div className="d-flex align-items-center bg-light-300 rounded p-3">
              <span className="avatar avatar-lg">
                <ImageWithBasePath
                  src="assets/img/students/student-07.jpg"
                  className="img-fluid rounded"
                  alt="img"
                />
              </span>
              <div className="ms-2">
                <h5 className="fs-14">Julie Scott</h5>
                <p>V, A</p>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Sibiling Information */}
        {/* Transport Information */}
        {/* <div className="card border-white mb-0">
          <div className="card-body pb-1">
            <ul className="nav nav-tabs nav-tabs-bottom mb-3">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="#hostel"
                  data-bs-toggle="tab"
                >
                  Hostel
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#transport" data-bs-toggle="tab">
                  Transportation
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="hostel">
                <div className="d-flex align-items-center mb-3">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-building-fortress fs-16" />
                  </span>
                  <div>
                    <h6 className="fs-14 mb-1">HI-Hostel, Floor</h6>
                    <p className="text-primary">Room No : 25</p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="transport">
                <div className="d-flex align-items-center mb-3">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-bus fs-16" />
                  </span>
                  <div>
                    <span className="fs-12 mb-1">Route</span>
                    <p className="text-dark">Newyork</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <span className="fs-12 mb-1">Bus Number</span>
                      <p className="text-dark">AM 54548</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <span className="fs-12 mb-1">Pickup Point</span>
                      <p className="text-dark">Cincinatti</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Transport Information */}
      </div>
    </div>
  );
};

export default StudentSidebar;
