import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

/**
 * Helper function to export data to Excel.
 * 
 * @param data - The data to be exported (array of objects).
 * @param fileName - The name of the exported Excel file.
 */
const toExcel = (data: Record<string, any>[], fileName: string) => {
  // Prepare the worksheet
  const ws = XLSX.utils.json_to_sheet(data);

  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);

  // Generate the Excel file as a binary string
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Convert buffer to a Blob
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  // Save the file
  saveAs(blob, `${fileName}.xlsx`);
};

export { toExcel };
