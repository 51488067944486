import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import dayjs from 'dayjs';
import 'bootstrap-daterangepicker/daterangepicker.css';

interface CustomDateRangesProps {
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  handleChangeDates: (start: dayjs.Dayjs, end: dayjs.Dayjs) => void;
}

const CustomDateRanges: React.FC<CustomDateRangesProps> = ({
  startDate = dayjs().subtract(29, 'day'),
  endDate = dayjs(),
  handleChangeDates,
}) => {
  const [state, setState] = useState({
    start: startDate,
    end: endDate,
  });

  const { start, end } = state;

  useEffect(() => {
    // Update state if props change
    setState({ start: startDate, end: endDate });
  }, [startDate, endDate]);

  const handleCallback = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    setState({ start, end });
    handleChangeDates(start, end); // Call the function with selected dates
  };

  const label = `${start.format('D MMMM, YYYY')} - ${end.format('D MMMM, YYYY')}`;

  return (
    <DateRangePicker
      initialSettings={{
        startDate: start.toDate(),
        endDate: end.toDate(),
        ranges: {
          Today: [dayjs().toDate(), dayjs().toDate()],
          Yesterday: [dayjs().subtract(1, 'day').toDate(), dayjs().subtract(1, 'day').toDate()],
          'Last 7 Days': [dayjs().subtract(6, 'day').toDate(), dayjs().toDate()],
          'Last 30 Days': [dayjs().subtract(29, 'day').toDate(), dayjs().toDate()],
          'This Month': [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()],
          'Last Month': [
            dayjs().subtract(1, 'month').startOf('month').toDate(),
            dayjs().subtract(1, 'month').endOf('month').toDate(),
          ],
        },
      }}
      onCallback={(start, end) => handleCallback(dayjs(start), dayjs(end))}
    >
      <div
        id="reportrange"
        className="col-4"
        style={{
          background: '#fff',
          cursor: 'pointer',
          padding: '0.5rem 0.625rem',
          border: '1px solid #E9EDF4',
          width: '100%',
          borderRadius: '5px',
          fontSize: '14px',
          color: '#202C4B',
          height: '38px',
        }}
      >
        <i className="ti ti-calendar"></i>&nbsp;
        <span>{label}</span>
      </div>
    </DateRangePicker>
  );
};

export default CustomDateRanges;
