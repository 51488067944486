export const fees_report_data = [
  {
    key: "1",
    category: "Class 1 General",
    feesDescription: "(Admission Fees)",
    feesCode: "admission-fees",
    dueDate: "25 Mar 2024",
    amount: 2000,
    status: "UnPaid",
    refId: "435454",
    mode: "Cash",
    datePaid: "25 Jan 2024",
    discount: "10%",
    fine: 200,
    balance: 0,
  }
];
