import React, { useEffect, useRef, useState } from "react";
// import { AssentClass } from "../../../core/data/json/AssentClass";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  activeList,
  classSylabus,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link, useNavigate } from "react-router-dom";
import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../../router/all_routes";
import { _get, _post } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import { Row } from "antd";
const classSection = [
  { value: "Select", label: "Select" },
  { value: "PRE-NURSERY", label: "PPRE-NURSERYRE" },
  { value: "NURSERY", label: "NURSERY" },
  { value: "PRIMARY", label: "PRIMARY" },
  { value: "SECONDARY", label: "SECONDARY" },
];
const AssentClass = () => {
  interface FormType {
    admission_no: string;
    name_of_applicant: string;
    class1: string;
  }
  const _form1: FormType = {
    admission_no: "",
    name_of_applicant: "",
    class1: "",
  };
  const _form = {
    class_code: "",
    class_name: "",
    description: "",
    max_population: "",
    class_teacher: "",
    section: "",
    application_no: "",
    name: "",
    class: "",
  };
  const [form, setForm] = useState(_form);

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    _post(
      "class_management?query_type=create",
      form,
      (res) => {
        // setLoading(false);
        // navigate(-1);
      },
      (err) => {
        // setLoading(false);
        console.log(err);
      }
    );
    console.log(form);
    setForm(_form);
    // setErrors({});
  };

  const navigate = useNavigate();
  const [new_data, setNewData] = useState([]);
  const getAdmissionList = () => {
    _get(
      `get_secondary_school_entrance_form?query_type=select_admission_no`,
      (res) => {
        setNewData(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getAdmissionList();
  }, []);

  const routes = all_routes;

  const data = AssentClass;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const route = all_routes;
  const columns = [
    {
      title: "Admission No",
      dataIndex: "admission_no",
      // sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    {
      title: "Name",
      dataIndex: "name_of_applicant",
      // sorter: (a: TableData, b: TableData) =>
      //   a.section.length - b.section.length,
    },
    {
      title: "Gender",
      dataIndex: "sex",
      // sorter: (a: TableData, b: TableData) =>
      //   a.section.length - b.section.length,
    },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      // sorter: (a: TableData, b: TableData) =>
      //   a.noOfStudents.length - b.noOfStudents.length,
    },
    {
      title: "Class",
      dataIndex: "current_class",
      // sorter: (a: TableData, b: TableData) =>
      //   a.noOfStudents.length - b.noOfStudents.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <button
              className="dropdown-item rounded-1"
              onClick={() => navigate(`/add-assign/${record.id}`)}
              style={{
                width: "5rem",
                backgroundColor: "#5295fa",
                border: "none",
              }}
            >
              <i className="ti ti-edit-circle me-2" />
              Select
            </button>
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Student List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">AssentClass </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Assint Class
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_class"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Assign Class
                </Link>
              </div>
            </div> */}
          </div>
          {/* {JSON.stringify(new_data)} */}
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Assign Class List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                {/* <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                                defaultValue={classSylabus[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <CommonSelect
                                className="select"
                                options={classSection}
                                defaultValue={classSection[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                                defaultValue={activeList[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div> */}
                {/* <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              {/* {JSON.stringify(new_data)} */}
              <Table columns={columns} dataSource={new_data} Selection={true} />
              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      ;{/* /Page Wrapper */}
      <>
        {/* Add AssentClass */}

        <div className="modal fade" id="add_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h4 className="modal-title">Assign Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div> */}

              <form>
                {/* {JSON.stringify(new_data)} */}
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="mb-3">
                        <label className="form-label">Class Code</label>
                        <input
                          type="text"
                          className="form-control"
                          name="class_code"
                          value={form.class_code}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="class_name"
                          value={form.class_name}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          name="description"
                          value={form.description}
                          onChange={handleChange}
                        />
                      </div> */}
                      <Row>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <p>
                            <b>Application: No.</b>
                          </p>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <p>
                            <b>Name:</b>
                          </p>
                        </div>
                        <div className="col-xxl col-xl-3 col-md-6">
                          <p>
                            <b>Class:</b>
                          </p>
                        </div>
                      </Row>
                      <div className="mb-3">
                        <label className="form-label">Assign Class</label>
                        <CommonSelect
                          className="select"
                          options={new_data.map((i: any) => ({
                            label: i.class_name,
                            value: i.class_name,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              section: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label">Max Population</label>
                        <input
                          type="text"
                          className="form-control"
                          name="max_population"
                          value={form.max_population}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* <div className="mb-3">
                        <label className="form-label">Class Teacher</label>
                        <input
                          type="text"
                          className="form-control"
                          name="class_teacher"
                          value={form.class_teacher}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <button onClick={() => handleSubmit()}>Assign Class</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add AssentClass */}
        {/* Edit AssentClass */}
        <div className="modal fade" id="edit_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class Name"
                          defaultValue="I"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <CommonSelect
                          className="select"
                          options={classSection}
                          defaultValue={classSection[0]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Students</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Students"
                          defaultValue={30}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Subjects</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Subjects"
                          // defaultValue={03}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switch-sm2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit AssentClass */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
        {/* View AssentClass */}
        <div className="modal fade" id="view_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="modal-title">Class Details</h4>
                  <span className="badge badge-soft-success ms-2">
                    <i className="ti ti-circle-filled me-1 fs-5" />
                    Active
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Class Name</p>
                        <span>III</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Section</p>
                        <span>A</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Subjects</p>
                        <span>05</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Students</p>
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /View AssentClass */}
      </>
    </div>
  );
};

export default AssentClass;
