import { LOADING_SIGNUP, AUTH, LOADING_LOGIN, AUTH_ERROR, LOGOUT, LOADING_APP, ERRORS } from './types';
import { _get, server_url } from '../../feature-module/Utils/Helper';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

interface ResponseData {
    success: boolean;
    user?: any;
    token?: string;
    classes?: string;
    current_class?: string;
    msg?: string;
    error?: any;
    school?: any[];
}

interface DispatchFunction {
    (action: { type: string; payload?: any }): void;
}
// Define the ThunkAction type for the login function
export function login(
    { username, password, history, type='users' }: { username: string; password: string; history: any , type?: string},
    success: (data: ResponseData) => void,
    error: (err: any) => void
): ThunkAction<void, {}, unknown, AnyAction> {
    return (dispatch: DispatchFunction) => {
        dispatch({ type: LOADING_LOGIN });

        fetch(`${server_url}/${type}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        })
            .then((raw) => raw.json())
            .then((data: ResponseData) => {
                dispatch({ type: LOADING_LOGIN });
                if (data.success) {
                    const { token } = data;
                    if (token) {
                        localStorage.setItem('@@auth_token', token);
                    }
                    getUserProfile(token!)
                        .then((userData: any) => {
                            if (data.success) {
                                dispatch({ type: LOADING_APP });
                                const { user, role } = userData;
                                if (role) {
                                    dispatch({
                                        type: AUTH,
                                        payload: data,
                                    });
                                    success(data);
                                } 
                            } else {
                                localStorage.removeItem('@@auth_token');
                                logout(history);
                                error('Logged out.');
                            }
                        })
                        .catch((error) => {
                            logout(history);
                            dispatch({
                                type: ERRORS,
                                payload: { msg: 'Authentication failed', error },
                            });
                            error('Authentication failed.');
                        });
                } else {
                    dispatch({ type: ERRORS, payload: data.msg });
                    error(data.error);
                }
            })
            .catch((err) => {
                dispatch({ type: LOADING_LOGIN });
                error('Authentication failed.');
            });
    };
}

export function signup(
    objs: Record<string, any> = {},
    success: (data: ResponseData) => void = () => {},
    error: (err: any) => void = () => {}
) {
    return (dispatch: DispatchFunction) => {
        dispatch({ type: LOADING_SIGNUP });
        fetch(`${server_url}/sign_up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objs),
        })
            .then((raw) => raw.json())
            .then((datas: ResponseData) => {
                dispatch({ type: LOADING_SIGNUP });
                if (datas.success) {
                    success(datas);
                } else {
                    dispatch({
                        type: AUTH_ERROR,
                        payload: { msg: 'Server error, try again.' },
                    });
                    error(datas);
                }
            })
            .catch((err) => {
                dispatch({ type: LOADING_SIGNUP });
                console.log({ err });
                error(err);
            });
    };
}

export function expressSignup(
    objs: Record<string, any> = {},
    success: (data: ResponseData) => void = () => {},
    error: (err: any) => void = () => {}
) {
    return (dispatch: DispatchFunction) => {
        dispatch({ type: LOADING_SIGNUP });
        fetch(`${server_url}/sign_up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objs),
        })
            .then((raw) => raw.json())
            .then((datas: ResponseData) => {
                dispatch({ type: LOADING_SIGNUP });
                if (datas.success) {
                    success(datas);
                } else {
                    dispatch({
                        type: AUTH_ERROR,
                        payload: { msg: 'Server error, try again.' },
                    });
                    error(datas);
                }
            })
            .catch((err) => {
                dispatch({ type: LOADING_SIGNUP });
                console.log({ err });
                error(err);
            });
    };
}



export async function getUserProfile(_token: string): Promise<any> {
    try {
        const response = await fetch(`${server_url}/verify-token`, {
            method: 'GET',
            headers: {
                authorization: _token,
            },
        });
        return await response.json();
    } catch (error: any) {
        if (error.status === 401) {
            logout('/');
            window.location.reload();
        }
        return error;
    }
}

export function init(
    history: any,
    success: () => void = () => {},
    error: () => void = () => {}
) {
    return (dispatch: DispatchFunction) => {
        const _token = localStorage.getItem('@@auth_token');
        if (_token) {
            getUserProfile(_token)
                .then((data: ResponseData) => {
                    if (data.success) {
                        const { user, classes,  } = data;
                        if (user && user.role) {
                            dispatch({
                                type: AUTH,
                                payload: data,
                            });
                            success();
                        }
                    } else {
                        localStorage.removeItem('@@auth_token');
                        logout(history);
                        success();
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: ERRORS,
                        payload: { msg: 'Authentication failed', error },
                    });
                    logout(history);
                    error();
                });
        } else {
            logout(history)
            dispatch({ type: ERRORS, payload: { msg: 'Authentication failed' } });
            error();
        }
    };
}

export function logout(history: any) {
    return (dispatch: DispatchFunction) => {
        if (history.location.pathname !== '/sign-up' && history.location.pathname !== '/login') {
            history('/');
        }
        console.error('LOGGING OUT');
        
        localStorage.removeItem('@@auth_token');
        dispatch({ type: LOGOUT });
    };
}
