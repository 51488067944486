export const StudentAttendanceTypeData = [
  {
    id: "AD9892434",
    key: "1",
    date: "25 Mar 2024",
    name: "Janet",
    img: "assets/img/students/student-01.jpg",
    class: "III",
    dob: "10 Jan 2015",
    fname: "Mary",
    img2: "assets/img/parents/parent-10.jpg",
    count: "22",
  },
  {
    id: "AD9892433",
    key: "2",
    date: "18 Mar 2024",
    name: "Joann",
    img: "assets/img/students/student-02.jpg",
    class: "IV",
    dob: "19 Aug 2014",
    fname: "Michael",
    img2: "assets/img/parents/parent-09.jpg",
    count: "15",
  },
  {
    id: "AD9892432",
    key: "3",
    date: "14 Mar 2024",
    name: "Kathleen",
    img: "assets/img/students/student-03.jpg",
    class: "II",
    dob: "05 Dec 2017",
    fname: "Jessie",
    img2: "assets/img/parents/parent-08.jpg",
    count: "24",
  },
  {
    id: "AD9892431",
    key: "4",
    date: "27 Feb 2024",
    name: "Gifford",
    img: "assets/img/students/student-04.jpg",
    class: "I",
    dob: "22 Mar 2018",
    fname: "Robert",
    img2: "assets/img/parents/parent-07.jpg",
    count: "22",
  },
  {
    id: "AD9892430",
    key: "5",
    date: "13 Feb 2024",
    name: "Gifford",
    img: "assets/img/students/student-05.jpg",
    class: "II",
    dob: "13 May 2017",
    fname: "Colleen",
    img2: "assets/img/parents/parent-06.jpg",
    count: "22",
  },
  {
    id: "AD9892429",
    key: "6",
    date: "11 Feb 2024",
    name: "Ralph",
    img: "assets/img/students/student-06.jpg",
    class: "III",
    dob: "20 Jun 2015",
    fname: "Arthur",
    img2: "assets/img/parents/parent-05.jpg",
    count: "24",
  },
  {
    id: "AD9892428",
    key: "7",
    date: "24 Jan 2024",
    name: "Julie",
    img: "assets/img/students/student-07.jpg",
    class: "V",
    dob: "18 Sep 2013",
    fname: "Claudia",
    img2: "assets/img/parents/parent-04.jpg",
    count: "24",
  },
  {
    id: "AD9892427",
    key: "8",
    date: "19 Jan 2024",
    name: "Ryan",
    img: "assets/img/students/student-08.jpg",
    class: "VI",
    dob: "26 Nov 2012",
    fname: "Johnson",
    img2: "assets/img/parents/parent-03.jpg",
    count: "21",
  },
  {
    id: "AD9892426",
    key: "9",
    date: "08 Jan 2024",
    name: "Susan",
    img: "assets/img/students/student-09.jpg",
    class: "VIII",
    dob: "26 May 2010",
    fname: "Marquita",
    img2: "assets/img/parents/parent-02.jpg",
    count: "24",
  },
  {
    id: "AD9892425",
    key: "10",
    date: "22 Dec 2024",
    name: "Richard",
    img: "assets/img/students/student-10.jpg",
    class: "VII",
    dob: "06 Oct 2011",
    fname: "Thomas",
    img2: "assets/img/parents/parent-01.jpg",
    count: "24",
  },
  {
    id: "AD9892424",
    key: "11",
    date: "15 Dec 2024",
    name: "Veronica",
    img: "assets/img/students/student-11.jpg",
    class: "IX",
    dob: "27 Dec 2009",
    fname: "Jessie",
    img2: "assets/img/parents/parent-14.jpg",
    count: "24",
  },
];
