import { all_routes } from "../../../feature-module/router/all_routes";
import store from "../../../redux/store";

const routes = all_routes;

const sideBarData = (user_role: string, user:any) => {
  // Common submenu items based on roles
  const getCommonSubmenuItems = () => {
    switch (user_role) {
      case "teacher":
        return [{ label: "Teacher Dashboard", link: routes.teacherDashboard }];
      case "admin":
        return [{ label: "Admin Dashboard", link: routes.adminDashboard }];
      case "parent":
        return [{ label: "Parent Dashboard", link: routes.parentDashboard }];
      default:
        return [{ label: "Student Dashboard", link: routes.studentDashboard }];
    }
  };

  // Core Sidebar Data
  const sidebarData = [
    {
      label: `MAIN`,
      submenuOpen: true,
      showSubRoute: false,
      submenuHdr: "Main",
      submenuItems: [
        {
          label: `${user.role} Dashboard`,
          icon: "ti ti-layout-dashboard",
          submenu: false,
          showSubRoute: false,
          link: getCommonSubmenuItems()[0].link,
          submenuItems: getCommonSubmenuItems(),
        },
      ],
    },
    // Conditional People's Section
    ...(user_role === "admin" || user_role === "parent"
      ? [
          {
            label: "Peoples",
            submenuOpen: true,
            submenuHdr: "Peoples",
            submenuItems: [
              {
                label: "Students",
                icon: "ti ti-school",
                submenu: true,
                submenuItems: [
                  { label: "Add Returning Student", link: routes.addStudent },
                  { label: "Students List", link: routes.studentList },
                ],
              },
              {
                label: "Application",
                icon: "ti ti-school",
                submenu: true,
                submenuItems: [
                  { label: "Application List", link: routes.applicationtList },
                  { label: "Entrance Exams Score", link: routes.entranceExamsScore },
                ],
              },
              {
                label: "Parents",
                icon: "ti ti-user-bolt",
                submenu: true,
                submenuItems: [{ label: "Parent List", link: routes.parentList }],
              },
              {
                label: "Teachers",
                icon: "ti ti-users",
                submenu: true,
                submenuItems: [{ label: "Teacher List", link: routes.teacherList }],
              },
            ],
          },
        ]
      : []),
    // Academic Section
    {
      label: "Academic",
      submenuOpen: true,
      submenuHdr: "Academic",
      submenuItems:
        user_role === "admin" || user_role === "parent"
          ? [
              {
                label: "Classes",
                icon: "ti ti-school-bell",
                submenu: true,
                submenuItems: [
                  { label: "All Classes", link: routes.classes },
                  { label: "Attendance", link: routes.classAttendance },
                ],
              },
              {
                label: "Subjects",
                link: routes.Subjects,
                icon: "ti ti-book",
                submenu: false,
              },
              {
                label: "Time Table",
                link: routes.classTimetable,
                icon: "ti ti-table",
                submenu: false,
              },
              {
                label: "Examinations",
                icon: "ti ti-hexagonal-prism-plus",
                submenu: true,
                submenuItems: [
                  { label: "Exam Calendar", link: routes.exam },
                  { label: "Exam Schedule", link: routes.examSchedule },
                  { label: "Grade", link: routes.grade },
                  { label: "Exam Attendance", link: routes.examAttendance },
                  { label: "Grade Student", link: routes.examResult },
                  { label: "Exam Results", link: routes.generalResults },
                ],
              },
            ]
          : [
              {
                label: user?.current_class || "My Class",
                icon: "ti ti-school",
                submenu: true,
                submenuItems: [
                  { label: "My Attendances", link: routes.studentAttendance },
                  { label: "Time Table", link: routes.studentTimetable },
                  { label: "Lessons", link: routes.Lessons },
                  { label: "Assignments", link: routes.studentAssignments },
                ],
              },
            ],
    },

      ...(user_role ==='teacher' || user_role ==='admin'?
        [{
          label: "Teacher",
          submenuOpen: true,
          showSubRoute: false,
          submenuHdr: "Teacher",
      
          submenuItems: [
          
            {
              label: "Teacher Activities",
              icon: "ti ti-school-bell",
              submenu: true,
              showSubRoute: false,
      
              submenuItems: [
                { label: "My Class", link: routes.classAttendance },
                { label: "Time Table", link: routes.classTimetable },
                { label: "Lessons", link: routes.TearcherLessons },
                {
                  label: "Lessons",
                  link: routes.LessonForm,
                  icon: "ti ti-books",
                  showSubRoute: false,
                  submenu: false,
                },
                {
                  label: "Assignments",
                  link: routes.classHomeWork,
                  icon: "ti ti-license",
                  showSubRoute: false,
                  submenu: false,
                },
              ],
            },
            {
              label: "Student Activities",
              icon: "ti ti-school",
              submenu: true,
              showSubRoute: false,
      
              submenuItems: [
                { label: "My Class", link: routes.studentAttendance },
                { label: "Time Table", link: routes.studentTimetable },
                { label: "Lessons", link: routes.Lessons },
                { label: "Assignments", link: routes.studentAssignments },
                // {
                //   label: "Assignments",
                //   link: routes.StudentNewTable,
                //   icon: "ti ti-license",
                //   showSubRoute: false,
                //   submenu: false,
                // },
                
              ],
            }
          ]
          }]: []),
              // Admin-Only Management Section
    ...(user_role === "admin"
      ? [
          {
            label: "MANAGEMENT",
            submenuOpen: true,
            submenuHdr: "Management",
            submenuItems: [
              {
                label: "Fees Collection",
                icon: "ti ti-report-money",
                submenu: true,
                submenuItems: [
                  { label: "Fees Group", link: routes.feesGroup },
                  { label: "Fees Type", link: routes.feesType },
                  { label: "Fees Master", link: routes.feesMaster },
                  { label: "Fees Assign", link: routes.feesAssign },
                  { label: "Collect Fees", link: routes.collectFees },
                ],
              },
              {
                label: "Library",
                icon: "ti ti-notebook",
                submenu: true,
                submenuItems: [
                  { label: "Library Members", link: routes.libraryMembers },
                  { label: "Books", link: routes.libraryBooks },
                  { label: "Issue Book", link: routes.libraryIssueBook },
                  { label: "Return", link: routes.libraryReturn },
                ],
              },
            ],
          },
          {
            label: "SCHOOL FEES & SETUP",
            submenuOpen: true,
            submenuHdr: "SCHOOL FEES",
            submenuItems: [
              {
                label: "Fees Setup",
                link: routes.feesSetup,
                icon: "ti ti-bank",
                submenu: false,
              },
            ],
          },
        ]
      :[]),
  ];

  return sidebarData;
};

export default sideBarData;
