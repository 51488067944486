import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  // CardBody,
  // CardHeader,
  Col,
  Container,
  // Input,
  // Label,
  Row,
} from "react-bootstrap";
// import { _get, _put } from "../Utils/Helper";
import { useParams, useNavigate } from "react-router-dom";
import useQuery, { _get, _put } from "../../Utils/Helper";
import { Input } from "antd";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
// import { Input } from "antd";

export default function ExamsScore() {
  const id = useParams().id;
  const application_no = useQuery().get("application_no");
  const navigate = useNavigate(); // Import and define navigate here

  const _form = {
    mathematics: 0,
    english: 0,
    other_score: 0,
    admission_no: "",
    name_of_applicant: "",
    class1: "",
  };

  const [form, setForm] = useState(_form);
  const [errors, setErrors] = useState({});
  const [statusColor, setStatusColor] = useState("secondary"); // Default button color
  const [statusText, setStatusText] = useState("Assigned"); // Default button text
  // const application_no = useQuery().get("application_no");
  const handleSubmit = (e: any) => {
    e.preventDefault();
    _put(
      "update_secondary_school_entrance_form",
      { ...form, application_no, query_type: "update", statusText },
      (res) => {
        // Handle success
        console.log(res);
        if (res.success) {
          toast.success("Assign successfully");
          navigate(`/entrance-exams-score/entrance-exams-score`);
        }
      },
      (err) => {
        // Handle error
        console.log(err);
      }
    );
  };
  const [new_data, setNewData] = useState([]);
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const getSecScl = useCallback(() => {
    _get(
      `get_secondary_school_entrance_form?application_no=${application_no}&query_type=select_application_no`,
      (res) => {
        // Handle success response
        console.log(res);
        if (res.results.length) {
          setForm(res.results[0]);
        }
      },
      (err) => {
        // Handle error response
        console.log(err);
      }
    );
  }, [id]);
  const getSelectClassName = () => {
    _get(
      `classes?query_type=select-all`,
      (res) => {
        setNewData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getSecScl();
    getSelectClassName();
  }, [getSecScl]);

  return (
    <div>
      {/* <Container> */}
      <div className="page-wrapper">
        <div className="content content-two"></div>
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="card">
                <div className="card-header bg-light">
                  <div className="d-flex align-items-center">
                    <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                      <i className="ti ti-info-square-rounded fs-16" />
                    </span>
                    <h4 className="text-dark">Personal Information</h4>
                  </div>
                </div>
                <CardBody>
                  <Row
                    className="w-100 justify-content-center"
                    style={{ marginBottom: "190px" }}
                  >
                    <Col md={6} className="mb-4">
                      <Card>
                        {/* {JSON.stringify(form)} */}
                        <CardHeader
                        // style={{ color: "#fff", backgroundColor: "#5295fa" }}
                        >
                          <h5 className="text-center">EXAMS SCORE</h5>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={6}>
                              <p>
                                <b>Name:</b> {form.name_of_applicant}
                              </p>
                            </Col>
                            <Col md={6}>
                              {" "}
                              <p>
                                <b>Application No:</b>
                                {application_no}
                              </p>
                            </Col>
                          </Row>

                          {/* <h1 className="text-center mt-2">Successfully</h1>
                          <h3 className="text-center mt-2">Admitted</h3>
                          <h3 className="text-center mt-2">
                            Admission No is {form?.admission_no}
                          </h3> */}
                          <div className="col-xxl col-xl-6 col-md-6 mt-3">
                            <div className="mb-3">
                              <label className="form-label">Assign Class</label>
                              <CommonSelect
                                className="select"
                                options={new_data.map((i: any) => ({
                                  label: i.class_name,
                                  value: i.class_name,
                                }))}
                                handleChange={(newValue: SingleValue<Option>) =>
                                  setForm((prev) => ({
                                    ...prev,
                                    current_class: newValue
                                      ? newValue.value
                                      : "",
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <Button
                            className="mt-5"
                            // onClick={() =>
                            //   navigate(
                            //     "/entrance-exams-score/entrance-exams-score"
                            //   )
                            // }
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </div>
            </form>
          </div>
        </div>
        {/* </Container> */}
      </div>
    </div>
  );
}
