import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { _post } from "../../Utils/Helper";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/actions/auth";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const routes = all_routes;
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  type AppDispatch = ThunkDispatch<{}, {}, AnyAction>;
  const _form = {
    username: "",
    password: "",
  };
  const dispatch: AppDispatch = useDispatch();

  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    // Define success and error callback functions for the login action creator
    const onSuccess = (data: any) => {
        setLoading(false);
        toast.success("Login successfully!");
        const user = data.user;
        switch (user.role?.toLowerCase()) {
            case "teacher":
                navigate(routes.teacherDashboard);
                break;
            case "admin":
                navigate(routes.adminDashboard);
                break;
        }
    };

    const onError = (err: any) => {
        console.error("Login error:", err);
        setLoading(false);
        // Check if the error is related to incorrect email/password
        if (err && err.response?.status === 401) {
            toast.warning("Password or email is not correct");
        } else {
            toast.warning("An error occurred. Please check Device Connectivity try again.");
        }
    };

    // Dispatch the login action with form data and the success/error callbacks
    dispatch(login(
            { username: form.username, password: form.password, history: navigate },
            onSuccess,
            onError
        )
    );
};

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  useEffect(() => {
    localStorage.setItem("menuOpened", "Dashboard");
  }, []);
  const date = () => {
    return new Date().getFullYear();
  };
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-4 mx-auto p-4">
                <form>
                  <div>

                    <div className="card">
                      <div className="card-body pb-3">
                        <div className="row text-center">
                          <a href="https://yma.elscholar.ng">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                marginBottom: "20px", // Optional for spacing
                              }}
                            >
                              <img
                                src="./assets/img/YMA.png"
                                style={{
                                  width: 150,
                                  height: 150,
                                }}
                                alt="YMA Logo"
                              />
                            </div>
                          </a>
                        </div>
                        <div className="login-or">
                          <span className="span-or">
                            Admin & Staff SignIn
                          </span>
                        </div>
                        <div className="mb-3 ">
                          <label className="form-label">Email Address</label>
                          <div className="input-icon mb-3 position-relative">
                            <span className="input-icon-addon">
                              <i className="ti ti-mail" />
                            </span>
                            <input
                              type="text"
                              defaultValue=""
                              className="form-control"
                              name="username"
                              value={form.username}
                              onChange={handleChange}
                            />
                          </div>
                          <label className="form-label">Password</label>
                          <div className="pass-group">
                            <input
                              type={isPasswordVisible ? "text" : "password"}
                              className="pass-input form-control"
                              name="password"
                              value={form.password}
                              onChange={handleChange}
                            />
                            <span
                              className={`ti toggle-password ${isPasswordVisible ? "ti-eye" : "ti-eye-off"
                                }`}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                        </div>
                        <div className="form-wrap form-wrap-checkbox">
                          <div className="d-flex align-items-center">
                            <div className="form-check form-check-md mb-0">
                              <input
                                className="form-check-input mt-0"
                                type="checkbox"
                              />
                            </div>
                            <p className="ms-1 mb-0 ">Remember Me</p>
                          </div>
                          <div className="text-end ">
                            <Link
                              to={routes.forgotPassword}
                              className="link-danger"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-4 pt-0">
                        <div className="mb-3">
                          <button
                            // to={routes.adminDashboard}
                            className="btn btn-primary w-100"
                            onClick={handleSubmit}
                          >
                          {loading ? <Spinner/> :  'Sign In'}
                          </button>
                        </div>
                        <div className="text-center">
                          <h6 className="fw-normal text-dark mb-0">
                            Are you a Student?{" "}
                            <Link to={routes.studentLogin} className="hover-a ">
                              {" "}
                              Login As Student
                            </Link>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="mb-0 ">
                        Copyright © {date()} - YAZID MEMORIAL ACADEMY
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
