import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  // CardBody,
  // CardHeader,
  Col,
  Container,
  // Input,
  // Label,
  Row,
} from "react-bootstrap";
// import { _get, _put } from "../Utils/Helper";
import { useParams, useNavigate } from "react-router-dom";
import { _get, _post, _put } from "../../Utils/Helper";
import { Input, Button } from "antd";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { SingleValue } from "react-select";
// import { Input } from "antd";

export default function AddAssign() {
  const id = useParams().id;
  const navigate = useNavigate(); // Import and define navigate here
  interface FormType {
    // Mathematics: number
    // english: number;
    // others: number;
    query_type: string;
    admission_no: string;
    name_of_applicant: string;
    class1: string;
    class_name: string;
    current_class: string;
  }
  const _form: FormType = {
    // Mathematics: 0,
    // english: 0,
    // others: 0,
    query_type: "update_current_class",
    admission_no: "",
    name_of_applicant: "",
    class1: "",
    class_name: "",
    current_class: "",
  };

  const [form, setForm] = useState(_form);
  const [errors, setErrors] = useState({});
  const [statusColor, setStatusColor] = useState("#0d6efd");
  const [statusText, setStatusText] = useState("Status");
  const [new_data, setNewData] = useState([]);

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   _put(
  //     "update_secondary_school_entrance_form",
  //     { ...form, id, query_type: "update", statusText },
  //     (res) => {
  //       // Handle success
  //       console.log(res);

  //       if (statusText === "Passed") {
  //         navigate(`/examsscore/${id}`);
  //       } else if (statusText === "Failed") {
  //         navigate(`/scorefeil/${id}`);
  //       }
  //     },
  //     (err) => {
  //       // Handle error
  //       console.log(err);
  //     }
  //   );
  // };

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: parseFloat(e.target.value) }));
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    _put(
      "update_current_class_secondary_school_entrance_form",
      form,
      (res) => {
        if (res.success) {
          alert("Submitted successfully");
          navigate(-1);
        } else {
          alert(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getSecScl = useCallback(() => {
    _get(
      `get_secondary_school_entrance_form?id=${id}&query_type=select_id`,
      (res) => {
        console.log(res);
        if (res.results.length) {
          setForm(res.results[0]);
        }
      },
      (err) => {
        // Handle error response
        console.log(err);
      }
    );
  }, [id]);

  useEffect(() => {
    getSecScl();
  }, [getSecScl]);
  const getAdmissionList = () => {
    _get(
      `get_class_management?query_type=select_class_name`,
      (res) => {
        setNewData(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getAdmissionList();
  }, []);

  // useEffect(() => {
  //   const MathematicsScore: number = form.Mathematics || 0;
  //   const englishScore: number = form.english || 0;
  //   const othersScore: number = form.others || 0;
  //   const averageScore = (MathematicsScore + englishScore + othersScore) / 3;
  //   console.log(averageScore);
  //   if (averageScore <= 49) {
  //     setStatusColor("#dc3545");
  //     setStatusText("Failed");
  //   } else if (averageScore >= 50 && averageScore <= 100) {
  //     setStatusColor("#198754");
  //     setStatusText("Passed");
  //   }
  // }, [form.Mathematics, form.english, form.others]);

  return (
    <div>
      {/* <Container> */}
      <div className="page-wrapper">
        <div className="content content-two"></div>
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="card">
                <div className="card-header bg-light">
                  <div className="d-flex align-items-center">
                    <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                      <i className="ti ti-info-square-rounded fs-16" />
                    </span>
                    <h4 className="text-dark">Personal Information</h4>
                  </div>
                </div>

                {/* <center>
                  <CardHeader
                    style={{ color: "#fff", backgroundColor: "#5295fa" }}
                  >
                    Score
                  </CardHeader>
                </center> */}
                <CardBody>
                  <Row>
                    {/* {JSON.stringify(new_data)} */}
                    <Col md={4}>
                      <p>
                        <b>Application: No.</b> {form.admission_no}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Name:</b> {form.name_of_applicant}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Class:</b> {form.class1}
                      </p>
                    </Col>
                  </Row>
                  <div className="col-xxl col-xl-3 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Assign Class</label>
                      <CommonSelect
                        className="select"
                        options={new_data.map((i: any) => ({
                          label: i.class_name,
                          value: i.class_name,
                        }))}
                        handleChange={(newValue: SingleValue<Option>) =>
                          setForm((prev) => ({
                            ...prev,
                            current_class: newValue ? newValue.value : "",
                          }))
                        }
                      />
                    </div>
                  </div>
                  {/* <Col md={4} className="mt-3">
                    <Button style={{ backgroundColor: statusColor }}>
                      {statusText}
                    </Button>
                  </Col> */}
                  <center>
                    <Button
                      style={{ width: "5rem", background: "#5295fa" }}
                      className="mt-3"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </center>
                </CardBody>
              </div>
            </form>
          </div>
        </div>
        {/* </Container> */}
      </div>
    </div>
  );
}
