export const transportAssignData = [
  {
    key: "1",
    id: "B80482",
    route: "Seattle",
    pickupPoint: "2233 Wood Street, Slidell, LA",
    vehicle: "8930",
    name: "Thomas",
    img: "assets/img/parents/parent-01.jpg",
    phone: "+1 64044 748904",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "2",
    id: "B80481",
    route: "Camden",
    pickupPoint: "2693 Parker Drive, Cleveland, OH",
    vehicle: "1235",
    name: "Mary",
    img: "assets/img/parents/parent-10.jpg",
    phone: "+1 14541 55665",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "3",
    id: "B80482",
    route: "Detroit",
    pickupPoint: "4650 Aviation Way, Los Angeles, CA",
    vehicle: "6465",
    name: "Michael",
    img: "assets/img/parents/parent-09.jpg",
    phone: "+1 78954 85461",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "4",
    id: "B80481",
    route: "Nashville",
    pickupPoint: "3167 Stadium Drive, Worcester, MA",
    vehicle: "7895",
    name: "Jessie",
    img: "assets/img/parents/parent-08.jpg",
    phone: "+1 12345 68891",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "5",
    id: "B80482",
    route: "Port Graham",
    pickupPoint: "1609 Smith Street, Worcester, MA",
    vehicle: "4625",
    name: "Robert",
    img: "assets/img/parents/parent-07.jpg",
    phone: "+1 78454 78841",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "6",
    id: "B80481",
    route: "Brooklyn North",
    pickupPoint: "3341 Palmer Road, Columbus, OH",
    vehicle: "7854",
    name: "Colleen",
    img: "assets/img/parents/parent-06.jpg",
    phone: "+1 78546 97894",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "7",
    id: "B80482",
    route: "Kansas City",
    pickupPoint: "2261 Sweetwood Drive, Denver, CO",
    vehicle: "9789",
    name: "Arthur",
    img: "assets/img/parents/parent-05.jpg",
    phone: "+1 97878 87854",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "8",
    id: "B80481",
    route: "Rochester",
    pickupPoint: "4025 Khale Street, Folly Beach, SC",
    vehicle: "4569",
    name: "Claudia",
    img: "assets/img/parents/parent-04.jpg",
    phone: "+1 64599 78542",
    status: "Inactive",
    statusClass: "badge badge-soft-danger",
  },
  {
    key: "9",
    id: "B80482",
    route: "Brooklyn Central",
    pickupPoint: "3521 Harvest Lane Kansas City, MO",
    vehicle: "7857",
    name: "Johnson",
    img: "assets/img/parents/parent-03.jpg",
    phone: "+1 45781 45145",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
  {
    key: "10",
    id: "B80481",
    route: "Seattle",
    pickupPoint: "2603 Wood Duck Drive Marquette, MI",
    vehicle: "6879",
    name: "Marquita",
    img: "assets/img/parents/parent-02.jpg",
    phone: "+1 45112 48879",
    status: "Active",
    statusClass: "badge badge-soft-success",
  },
];
