export const teacherAttendance = [
  {
    id: "T849127",
    name: "Janet",
    class: "III A",
    notes: "",
    img: "assets/img/students/student-01.jpg",
    attendance: "",
    key: 1,
  },
  {
    id: "T849126",
    name: "Daniel",
    class: "II (A)",
    notes: "",
    img: "assets/img/students/student-02.jpg",
    attendance: "",
    key: 2,
  },
  {
    id: "T849125",
    name: "Hellana",
    class: "VI (A)",
    notes: "",
    img: "assets/img/students/student-03.jpg",
    attendance: "",
    key: 3,
  },
  {
    id: "T849124",
    name: "Erickson",
    class: "VI (B) , V (A)",
    notes: "",
    img: "assets/img/students/student-04.jpg",
    attendance: "",
    key: 4,
  },
  {
    id: "T849123",
    name: "Morgan",
    class: "VIII",
    notes: "",
    img: "assets/img/students/student-05.jpg",
    attendance: "",
    key: 5,
  },
  {
    id: "T849122",
    name: "Aaron",
    class: "I (A)",
    notes: "",
    img: "assets/img/students/student-06.jpg",
    attendance: "",
    key: 6,
  },
  {
    id: "T849121",
    name: "Jacquelin",
    class: "IV",
    notes: "",
    img: "assets/img/students/student-07.jpg",
    attendance: "",
    key: 7,
  },
  {
    id: "T849120",
    name: "Raul",
    class: "IX",
    notes: "",
    img: "assets/img/students/student-08.jpg",
    attendance: "",
    key: 8,
  },
  {
    id: "T849119",
    name: "Elizabeth",
    class: "VII",
    notes: "",
    img: "assets/img/students/student-09.jpg",
    attendance: "",
    key: 9,
  },
  {
    id: "T849118",
    name: "Edward",
    class: "IX (C) , X (C)",
    notes: "",
    img: "assets/img/students/student-07.jpg",
    attendance: "",
    key: 10,
  },
];
