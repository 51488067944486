import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../../core/common/dataTable/index";
import TooltipOption from "../../../../core/common/tooltipOption";
import { _get, _post } from "../../../Utils/Helper";
import { all_routes } from "../../../router/all_routes";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { SingleValue } from "react-select";
import {  acadamicTerms } from "../../../../core/common/selectoption/selectoption";
import { RootState } from "../../../../redux/reducers";
import { useSelector } from "react-redux";
import { toExcel } from "../../../../core/common/helpers";
import { Input } from "antd";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

// Define types for student and form data
interface Student {
  admission_no: string;
  student_name: string;
  current_class:string;
}
interface Classes {
  class_name: string;
}
interface Subject{
  subject: string;
  status: string;
  section: string;
}
interface FormData {
  admission_no: string;
  student_name: string;
  caScore: string;
  assignmentScore: string;
  examScore: string;
  subject: string;
  class_name: string; // Add class_name field
}

const ExamResult = () => {
  const routes = all_routes;

  const [new_data, setNewData] = useState<Classes[]>([]);
  const classOptions  = new_data.length > 0 ? new_data.map(item=>({label: item.class_name, value: item.class_name})):[]
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const  subjectOptions = subjects.length > 0 ? subjects.map(item=>({label: item.subject, value: item.subject})):[]
  
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [formData, setFormData] = useState<FormData[]>([]); 
  const {academic_year, user} = useSelector((state:RootState) =>state.auth)
  const [form, setForm] = useState({
    term: "",
    subject:"",
    class_name:"",
    academic_year,
    mark_by:user.name
  });

  const getClasses = () => {
    _get(
      "classes?query_type=select-all",
      (res) => {
        console.log(res);
        setNewData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getSubjectsByClass = (className: string) => {
    _get(
      `subjects?query_type=select-all`,
      (res) => {
        console.log(res);
        setSubjects(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getStudentsByClass = (className: string) => {
    _get(
      `students?query_type=select-class&current_class=${className}`,
      (res) => {
        console.log(res);
        // Check if response data exists
        if (res.success && res.data) {
          setStudents(res.data);
  
          // Initialize form data for each student
          const initializedFormData: FormData[] = res.data.map((student: Student) => ({
            admission_no: student.admission_no,
            student_name: student.student_name,
            caScore: "",
            assignmentScore: "",
            examScore: "",
            subject: selectedSubject, // From parent or state
            class_name: student.current_class, // Initialize with class name
          }));
  
          setFormData(initializedFormData);
        } else {
          console.error("No data found in response:", res);
          setStudents([]);
          setFormData([]);
        }
      },
      (err) => {
        console.error("Error fetching students:", err);
      }
    );
  };
  

  const handleClassChange = (value:any) => {
    setForm((p)=>({...p, class_name: value}));
    getSubjectsByClass(value);
    getStudentsByClass(value);

    // Update class_name in formData when the class changes
    const updatedFormData = formData.map((student) => ({
      ...student,
      class_name: value,
      mark_by:user.name,
    }));
    setFormData(updatedFormData);
  };

  const handleSubjectChange = (value:any) => {
    // const subject = e.target.value;
    setSelectedSubject(value);
    setForm((p)=>({...p, subject: value}));
    // Update the subject in formData when the subject changes
    const updatedFormData = formData.map((student) => ({
      ...student,
      subject: value,
    }));
    setFormData(updatedFormData);
  };

  const handleInputChange = (
    index: number,
    field: keyof FormData,
    value: string
  ) => {
    // Ensure index exists in the array
    if (index < 0 || index >= formData.length) {
      console.error("Invalid index:", index);
      return; // Exit the function to avoid further errors
    }
  
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
  
    // alert(JSON.stringify(updatedFormData[index]));
    setFormData(updatedFormData);
  };
  
  const handleSubmit = () => {
    if(!form.academic_year){
      toast.warning('Please select a valid academic year');
      return;
    }
    if(!form.subject){
      toast.warning('Please select a valid subject');
      return;
    }
    if(!form.class_name){
      toast.warning('Please select a valid class');
      return;
    }
    if(!formData.length){
      toast.warning('No students found in the selected class\r\nPlease select class or Upload an Excell file');
      return;
    }


    const payload = formData.map((student) => ({
      admission_no: student.admission_no,
      student_name: student.student_name,
      caScore: student.caScore,
      assignmentScore: student.assignmentScore,
      examScore: student.examScore,
      subject: student.subject||form.subject, 
      class_name: student.class_name||form.class_name,
      term: form.term, 
      academic_year:form.academic_year, 
      mark_by:user.name,
      query_type:'create'
    }));

    _post(
      "exams/gradings",
      payload,
      (res) => {
        if(res.success){
          console.log("Submission successful", res);
          toast.success("Submitted successfully!");}
      },
      (err) => {
        console.log("Error submitting data", err);
        toast.error("There was an error submitting the data.");
      }
    );
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryString = event.target?.result;
        if (binaryString) {
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(worksheet);

          // Assuming `data` is an array of objects from the Excel sheet
          setFormData(data as any[]);
          setStudents(data.map((student:any) => ({...student, student_name: student.student_name})));  

        }
      };

      reader.readAsBinaryString(file);
    }
  };


  useEffect(() => {
    getClasses();
  }, []);

  const processExport = () => {
    return toExcel(formData,'Student List')
  }

  const columns = [
    {
      title: "Admission No",
      dataIndex: "admission_no",
      render: (text: string, record: Student) => (
        <Link to="#" className="link-primary">
          {record.admission_no}
        </Link>
      ),
    },
    {       
      title: "Student Name",
      dataIndex: "student_name",
      render: (text: string) => (
        <span className="attendance-range">{text}</span>
      ),
    },
    {
      title: "CA Score",
      dataIndex: "caScore",
      render: (text: string, record: Student, index: number) => (
        <div style={{ width: "100%" }}>
          <input
            style={{
              border: "1px solid #000",
              padding: "5px",
              borderRadius: "5px",
              width: "100px",
            }}
            type="number"
            placeholder="Enter CA marks"
            value={formData[index]?.caScore || ""}
            onChange={(e) =>
              handleInputChange(index, "caScore", e.target.value)
            }
          />
        </div>
      ),
    },
    {
      title: "Assignment Score",
      dataIndex: "assignmentScore",
      render: (text: string, record: Student, index: number) => (
        <div style={{ width: "100%" }}>
          <input
            style={{
              border: "1px solid #000",
              padding: "5px",
              borderRadius: "5px",
              width: "100px",
            }}
            type="number"
            placeholder="Enter Assignment marks"
            value={formData[index]?.assignmentScore || ""}
            onChange={(e) =>
              handleInputChange(index, "assignmentScore", e.target.value)
            }
          />
        </div>
      ),
    },
    {
      title: "Exam Score",
      dataIndex: "examScore",
      render: (text: string, record: Student, index: number) => (
        <div style={{ width: "100%" }}>
          <input
            style={{
              border: "1px solid #000",
              padding: "5px",
              borderRadius: "5px",
              width: "100px",
            }}
            type="number"
            placeholder="Enter Exam marks"
            value={formData[index]?.examScore || ""}
            onChange={(e) =>
              handleInputChange(index, "examScore", e.target.value)
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-4 mt-4s">

          {/* {JSON.stringify(formData)} */}
       
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Assessments & Grading </h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.adminDashboard}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Academic </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Assessments & Grading 
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <div className="col-md-6 col-sm-3 m b-2">
              <Input  
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload} 
                name="template" 
                placeholder="Upload excell"/>
            </div>
            <TooltipOption with_export setExport={processExport}/>
          </div>
        </div>
        <div className="card p-3">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px",
            }}
          >
               <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label>Select Term</label>
            <CommonSelect
              options={acadamicTerms}
              defaultValue={{
                label: form.term,
                value: form.term,
              }}
              handleChange={(newValue: SingleValue<Option>) =>
                setForm((prev) => ({
                  ...prev,
                  term: newValue ? newValue.value : "",
                }))
              }
            />
          </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label>Select Class</label>
              <CommonSelect
              options={classOptions}
              defaultValue={{
                label: form.class_name,
                value: form.class_name,
              }}
              handleChange={(newValue: SingleValue<Option>) =>
                handleClassChange(newValue?.value)
              }
            />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label>Select Subject</label>
              <CommonSelect
              options={subjectOptions}
              defaultValue={{
                label: form.subject,
                value: form.subject,
              }}
              handleChange={(newValue: SingleValue<Option>) =>
                handleSubjectChange(newValue?.value)
              }
            />
            </div>
          </div>
        </div>
        
        <div>
          <Table columns={columns} dataSource={students} />
        </div>
        <button
          type="button"
          className="btn btn-primary d-flex align-items-center"
          style={{
            marginLeft: "45%",
            marginBottom: 40,
            alignItems: "center",
          }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ExamResult;
