import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import CircleProgress from "./circleProgress";

const Assignments = () => {

  return (
    <div className="col-xxl-5 d-flex">
      <div className="card flex-fill">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h4 className="card-titile">Assigments</h4>
          <div className="dropdown">
            <Link
              to="#"
              className="bg-white dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <i className="ti ti-book-2 me-2" />
              All Subject
            </Link>
            <ul className="dropdown-menu mt-2 p-3">
              <li>
                <Link to="#" className="dropdown-item rounded-1">
                  Physics
                </Link>
              </li>
              <li>
                <Link to="#" className="dropdown-item rounded-1">
                  Chemistry
                </Link>
              </li>
              <li>
                <Link to="#" className="dropdown-item rounded-1">
                  Maths
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body py-1">
          <ul className="list-group list-group-flush">
            <li className="list-group-item py-3 px-0 pb-0">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="d-flex align-items-center overflow-hidden mb-3">
                  <Link
                    to="#"
                    className="avatar avatar-xl flex-shrink-0 me-2"
                  >
                    <ImageWithBasePath
                      src="assets/img/home-work/home-work-01.jpg"
                      alt="img"
                    />
                  </Link>
                  <div className="overflow-hidden">
                    <p className="d-flex align-items-center text-info mb-1">
                      <i className="ti ti-tag me-2" />
                      Physics
                    </p>
                    <h6 className="text-truncate mb-1">
                      <Link to={all_routes.classHomeWork}>
                        Write about Theory of Pendulum
                      </Link>
                    </h6>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex align-items-center border-end me-1 pe-1">
                        <Link
                          to={all_routes.teacherDetails}
                          className="avatar avatar-xs flex-shrink-0 me-2"
                        >
                          <ImageWithBasePath
                            src="assets/img/teachers/teacher-01.jpg"
                            className="rounded-circle"
                            alt="teacher"
                          />
                        </Link>
                        <p className="text-dark">Aaron</p>
                      </div>
                      <p>Due by : 16 Jun 2024</p>
                    </div>
                  </div>
                </div>
                <CircleProgress value={80} />
              </div>
            </li>
            <li className="list-group-item py-3 px-0 pb-0">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="d-flex align-items-center overflow-hidden mb-3">
                  <Link
                    to="#"
                    className="avatar avatar-xl flex-shrink-0 me-2"
                  >
                    <ImageWithBasePath
                      src="assets/img/home-work/home-work-02.jpg"
                      alt="img"
                    />
                  </Link>
                  <div className="overflow-hidden">
                    <p className="d-flex align-items-center text-success mb-1">
                      <i className="ti ti-tag me-2" />
                      Chemistry
                    </p>
                    <h6 className="text-truncate mb-1">
                      <Link to={all_routes.classHomeWork}>
                        Chemistry - Change of Elements
                      </Link>
                    </h6>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex align-items-center border-end me-1 pe-1">
                        <Link
                          to={all_routes.teacherDetails}
                          className="avatar avatar-xs flex-shrink-0 me-2"
                        >
                          <ImageWithBasePath
                            src="assets/img/teachers/teacher-01.jpg"
                            className="rounded-circle"
                            alt="teacher"
                          />
                        </Link>
                        <p className="text-dark">Hellana</p>
                      </div>
                      <p>Due by : 16 Jun 2024</p>
                    </div>
                  </div>
                </div>

                <CircleProgress value={65} />
              </div>
            </li>
            <li className="list-group-item py-3 px-0 pb-0">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="d-flex align-items-center overflow-hidden mb-3">
                  <Link
                    to="#"
                    className="avatar avatar-xl flex-shrink-0 me-2"
                  >
                    <ImageWithBasePath
                      src="assets/img/home-work/home-work-03.jpg"
                      alt="img"
                    />
                  </Link>
                  <div className="overflow-hidden">
                    <p className="d-flex align-items-center text-danger mb-1">
                      <i className="ti ti-tag me-2" />
                      Maths
                    </p>
                    <h6 className="text-truncate mb-1">
                      <Link to={all_routes.classHomeWork}>
                        Maths - Problems to Solve Page 21
                      </Link>
                    </h6>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex align-items-center border-end me-1 pe-1">
                        <Link
                          to={all_routes.teacherDetails}
                          className="avatar avatar-xs flex-shrink-0 me-2"
                        >
                          <ImageWithBasePath
                            src="assets/img/teachers/teacher-01.jpg"
                            className="rounded-circle"
                            alt="teacher"
                          />
                        </Link>
                        <p className="text-dark">Morgan</p>
                      </div>
                      <p>Due by : 21 Jun 2024</p>
                    </div>
                  </div>
                </div>
                <CircleProgress value={30} />
              </div>
            </li>
            <li className="list-group-item py-3 px-0 pb-0">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="d-flex align-items-center overflow-hidden mb-3">
                  <Link
                    to="#"
                    className="avatar avatar-xl flex-shrink-0 me-2"
                  >
                    <ImageWithBasePath
                      src="assets/img/home-work/home-work-04.jpg"
                      alt="img"
                    />
                  </Link>
                  <div className="overflow-hidden">
                    <p className="d-flex align-items-center text-skyblue mb-1">
                      <i className="ti ti-tag me-2" />
                      Engish
                    </p>
                    <h6 className="text-truncate mb-1">
                      <Link to={all_routes.classHomeWork}>
                        English - Vocabulary Introduction
                      </Link>
                    </h6>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex align-items-center border-end me-1 pe-1">
                        <Link
                          to={all_routes.teacherDetails}
                          className="avatar avatar-xs flex-shrink-0 me-2"
                        >
                          <ImageWithBasePath
                            src="assets/img/teachers/teacher-01.jpg"
                            className="rounded-circle"
                            alt="teacher"
                          />
                        </Link>
                        <p className="text-dark">Daniel Josua</p>
                      </div>
                      <p>Due by : 21 Jun 2024</p>
                    </div>
                  </div>
                </div>
                <CircleProgress value={10} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Assignments;