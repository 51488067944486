export const teacherAttendancereportData = [
  {
    key: "1",
    name: "Aaron",
    img: "assets/img/teachers/teacher-07.jpg",
    percentage: "95",
    percentClass: "badge badge-soft-info",
    p: "16",
    l: "2",
    a: "1",
    h: "6",
    f: "1",
    m01: "1",
    t02: "2",
    w03: "1",
    t04: "3",
    f05: "4",
    s06: "1",
    s07: "1",
    m08: "1",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "1",
    s14: "3",
    m15: "1",
    t16: "2",
    w17: "1",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "2",
    name: "Daniel",
    img: "assets/img/teachers/teacher-02.jpg",
    percentage: "95",
    percentClass: "badge badge-soft-info",
    p: "24",
    l: "0",
    a: "0",
    h: "6",
    f: "0",
    m01: "1",
    t02: "1",
    w03: "1",
    t04: "1",
    f05: "1",
    s06: "1",
    s07: "1",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "1",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "1",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "3",
    name: "Edward",
    img: "assets/img/teachers/teacher-09.jpg",
    percentage: "94",
    percentClass: "badge badge-soft-info",
    p: "23",
    l: "1",
    a: "3",
    h: "6",
    f: "0",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "4",
    name: "Elizabeth",
    img: "assets/img/teachers/teacher-10.jpg",
    percentage: "32",
    percentClass: "badge badge-soft-danger",
    p: "21",
    l: "1",
    a: "1",
    h: "6",
    f: "0",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "5",
    name: "Erickson",
    img: "assets/img/teachers/teacher-04.jpg",
    percentage: "45",
    percentClass: "badge badge-soft-danger",
    p: "13",
    l: "1",
    a: "3",
    h: "1",
    f: "0",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "6",
    name: "Erickson",
    img: "assets/img/teachers/teacher-03.jpg",
    percentage: "100",
    percentClass: "badge badge-soft-success",
    p: "3",
    l: "1",
    a: "3",
    h: "1",
    f: "0",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "7",
    name: "Jacquelin",
    img: "assets/img/teachers/teacher-03.jpg",
    percentage: "99",
    percentClass: "badge badge-soft-info",
    p: "3",
    l: "1",
    a: "3",
    h: "1",
    f: "0",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "8",
    name: "Janet",
    img: "assets/img/teachers/teacher-01.jpg",
    percentage: "97",
    percentClass: "badge badge-soft-info",
    p: "3",
    l: "1",
    a: "3",
    h: "1",
    f: "0",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "9",
    name: "Morgan",
    img: "assets/img/teachers/teacher-06.jpg",
    percentage: "100",
    percentClass: "badge badge-soft-success",
    p: "13",
    l: "1",
    a: "3",
    h: "1",
    f: "0",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
  {
    key: "10",
    name: "Morgan",
    img: "assets/img/teachers/teacher-09.jpg",
    percentage: "98",
    percentClass: "badge badge-soft-info",
    p: "23",
    l: "1",
    a: "3",
    h: "1",
    f: "3",
    m01: "1",
    t02: "3",
    w03: "1",
    t04: "1",
    f05: "3",
    s06: "1",
    s07: "4",
    m08: "4",
    t09: "2",
    w10: "1",
    t11: "4",
    f12: "1",
    s13: "4",
    s14: "1",
    m15: "1",
    t16: "2",
    w17: "3",
    t18: "1",
    f19: "3",
    s20: "1",
    s21: "1",
    m22: "1",
    t23: "4",
  },
];
