import React, { useEffect, useState } from "react";
import TeacherModal from "../teacherModal";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import TeacherSidebar from "./teacherSidebar";
import TeacherBreadcrumb from "./teacherBreadcrumb";
import { _get } from "../../../Utils/Helper";

const TeacherDetails = () => {
  const routes = all_routes;
  interface FormType {
    marital_status: string;
    email_address: string;
    date_of_birth: string;
  }
  interface TeacherData {
    marital_status: string;
    email_address: string;
    name: string;
    address: string;
    sex: string;
    date_of_birth: string;
    qualification: string;
    working_experience: string;
    account_name: string;
    account_number: 0;
    bank: string;
  }

  const _TeacherData: TeacherData = {
    marital_status: "",
    email_address: "",
    name: "",
    address: "",
    sex: "",
    date_of_birth: "",
    qualification: "",
    working_experience: "",
    account_name: "",
    account_number: 0,
    bank: "",
  };
  const [teacher, setTeacher] = useState<TeacherData>(_TeacherData);
  const { teacher_id } = useParams<{ teacher_id: string }>(); // Use string type for URL params
  const parsedTeacherId: number | null = teacher_id
    ? parseInt(teacher_id, 10)
    : null;
  const getTeacher = () => {
    _get(
      `get_teacher_personal_data_form?query_type=select&id=${parsedTeacherId}`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setTeacher(res.results[0]);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  useEffect(() => {
    getTeacher();
  }, []);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {/* Page Header */}
            <TeacherBreadcrumb />
            {/* /Page Header */}
            {/* Student Information */}
            <TeacherSidebar teacher_id={parsedTeacherId} />
            {/* /Student Information */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  {/* List */}
                  {JSON.stringify(teacher)}
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link
                        to={routes.teacherDetails}
                        className="nav-link active"
                      >
                        <i className="ti ti-school me-2" />
                        Teacher Details
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.teachersRoutine} className="nav-link">
                        <i className="ti ti-table-options me-2" />
                        Routine
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.teacherLeaves} className="nav-link">
                        <i className="ti ti-calendar-due me-2" />
                        Leave &amp; Attendance
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.teacherSalary} className="nav-link">
                        <i className="ti ti-report-money me-2" />
                        Salary
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.teacherLibrary} className="nav-link">
                        <i className="ti ti-bookmark-edit me-2" />
                        Library
                      </Link>
                    </li>
                  </ul>
                  {/* /List */}
                  {/* Parents Information */}
                  <div className="card">
                    <div className="card-header">
                      <h5>Profile Details</h5>
                    </div>
                    <div className="card-body">
                      <div className="border rounded p-3 pb-0">
                        <div className="row">
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Full Name
                              </p>
                              <p>{teacher.name}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Gender{" "}
                              </p>
                              <p>{teacher.sex}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Date of Birth
                              </p>
                              <p>{teacher.date_of_birth}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Martial Status
                              </p>
                              <p>{teacher?.marital_status || ""}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Qualification
                              </p>
                              <p>{teacher.qualification}</p>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-4">
                            <div className="mb-3">
                              <p className="text-dark fw-medium mb-1">
                                Experience
                              </p>
                              <p>{teacher.working_experience}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Parents Information */}
                </div>
                {/* Documents */}
                {/* <div className="col-xxl-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Documents</h5>
                    </div>
                    <div className="card-body">
                      <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                        <div className="d-flex align-items-center overflow-hidden">
                          <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                            <i className="ti ti-pdf fs-15" />
                          </span>
                          <div className="ms-2">
                            <p className="text-truncate fw-medium text-dark">
                              Resume.pdf
                            </p>
                          </div>
                        </div>
                        <Link to="#" className="btn btn-dark btn-icon btn-sm">
                          <i className="ti ti-download" />
                        </Link>
                      </div>
                      <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between p-2">
                        <div className="d-flex align-items-center overflow-hidden">
                          <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                            <i className="ti ti-pdf fs-15" />
                          </span>
                          <div className="ms-2">
                            <p className="text-truncate fw-medium text-dark">
                              Joining Letter.pdf
                            </p>
                          </div>
                        </div>
                        <Link to="#" className="btn btn-dark btn-icon btn-sm">
                          <i className="ti ti-download" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Documents */}
                {/* Address */}
                {/* <div className="col-xxl-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Address</h5>
                    </div>
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-map-pin-up" />
                        </span>
                        <div>
                          <p className="text-dark fw-medium mb-1">
                            Current Address
                          </p>
                          <p>3495 Red Hawk Road, Buffalo Lake, MN 55314</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-map-pins" />
                        </span>
                        <div>
                          <p className="text-dark fw-medium mb-1">
                            Permanent Address
                          </p>
                          <p>3495 Red Hawk Road, Buffalo Lake, MN 55314</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Address */}
                {/* Previous School Details */}
                {/* <div className="col-xxl-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Previous School Details</h5>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              Previous School Name
                            </p>
                            <p>Oxford Matriculation, USA</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              School Address
                            </p>
                            <p>1852 Barnes Avenue, Cincinnati, OH 45202</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              Phone Number
                            </p>
                            <p>+1 35676 45556</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Previous School Details */}
                {/* Bank Details */}
                <div className="col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Bank Details</h5>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              Bank Name
                            </p>
                            <p>{teacher.account_name}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">Bank</p>
                            <p>{teacher.bank}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              Account Name
                            </p>
                            <p>{teacher.account_number}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Bank Details */}
                {/* Medical History */}
                {/* <div className="col-xxl-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Work Details</h5>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              Contract Type
                            </p>
                            <p>Permanent</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">Shift</p>
                            <p>Morning</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              Work Location
                            </p>
                            <p>2nd Floor</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Medical History */}
                {/* Medical History */}
                {/* <div className="col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5>Social Media</h5>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row row-cols-xxl-5 row-cols-xl-3">
                        <div className="col">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">Facebook</p>
                            <p>www.facebook.com</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">Twitter</p>
                            <p>www.twitter.com</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">Linkedin</p>
                            <p>www.linkedin.com</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">Youtube</p>
                            <p>www.youtube.com</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <p className="mb-1 text-dark fw-medium">
                              Instagram
                            </p>
                            <p>www.instagram.com</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* /Medical History */}
                {/* Other Info */}
                <div className="col-xxl-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Other Info</h5>
                    </div>
                    <div className="card-body">
                      <p>
                        Depending on the specific needs of your organization or
                        system, additional information may be collected or
                        tracked. It's important to ensure that any data
                        collected complies with privacy regulations and policies
                        to protect students' sensitive information.
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Other Info */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <TeacherModal />
    </>
  );
};

export default TeacherDetails;
