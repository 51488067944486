export const sportListData = [
  {
    key: "1",
    year: "2004",
    coachName: "Thomas",
    id: "SP826329",
    name: "Francis",
    img: "assets/img/players/player-01.jpg",
    img2: "assets/img/coach/coach-01.jpg",
    sports: "Cricket",
    dateofJoin: "25 Apr 2024",
  },
  {
    key: "2",
    year: "2005",
    coachName: "Georgia",
    id: "SP826328",
    name: "Cheryl",
    img: "assets/img/players/player-02.jpg",
    img2: "assets/img/coach/coach-02.jpg",
    sports: "Throwball",
    dateofJoin: "28 Apr 2024",
  },
  {
    key: "3",
    year: "2006",
    coachName: "Nicholas",
    id: "SP826327",
    name: "Daniel",
    img: "assets/img/players/player-03.jpg",
    img2: "assets/img/coach/coach-03.jpg",
    sports: "Football",
    dateofJoin: "04 May 2024",
  },
  {
    key: "4",
    year: "2006",
    coachName: "Sandra",
    id: "SP826326",
    name: "Irene",
    img: "assets/img/players/player-04.jpg",
    img2: "assets/img/coach/coach-04.jpg",
    sports: "Tennis",
    dateofJoin: "16 May 2024",
  },
  {
    key: "5",
    year: "2007",
    coachName: "Jon",
    id: "SP826325",
    name: "Keith",
    img: "assets/img/players/player-05.jpg",
    img2: "assets/img/coach/coach-05.jpg",
    sports: "Basketball",
    dateofJoin: "20 May 2024",
  },
  {
    key: "6",
    year: "2008",
    coachName: "Shannon",
    id: "SP826324",
    name: "Dina",
    img: "assets/img/players/player-06.jpg",
    img2: "assets/img/coach/coach-06.jpg",
    sports: "Badminton",
    dateofJoin: "12 Jun 2024",
  },
  {
    key: "7",
    year: "2009",
    coachName: "Wilson",
    id: "SP826323",
    name: "Michael",
    img: "assets/img/players/player-07.jpg",
    img2: "assets/img/coach/coach-07.jpg",
    sports: "Carrom",
    dateofJoin: "17 Jun 2024",
  },
  {
    key: "8",
    year: "2004",
    coachName: "Sonia",
    id: "SP826322",
    name: "Julia",
    img: "assets/img/players/player-08.jpg",
    img2: "assets/img/coach/coach-08.jpg",
    sports: "Chess",
    dateofJoin: "27 Jun 2024",
  },
  {
    key: "9",
    year: "2005",
    coachName: "Adams",
    id: "SP826321",
    name: "Ray",
    img: "assets/img/players/player-09.jpg",
    img2: "assets/img/coach/coach-09.jpg",
    sports: "Hockey",
    dateofJoin: "10 Jul 2024",
  },
  {
    key: "10",
    year: "2008",
    coachName: "Lydia",
    id: "SP826320",
    name: "Lois",
    img: "assets/img/players/player-10.jpg",
    img2: "assets/img/coach/coach-10.jpg",
    sports: "Volleyball",
    dateofJoin: "20 Jul 2024",
  },
];
