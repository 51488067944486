import React, { useEffect, useRef, useState } from "react";
import Table from "../../../core/common/dataTable/index";
import { scheduleClass } from "../../../core/data/json/schedule_class";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  activeList,
  classselect,
  startTime,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect, { Option } from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import { _get, _post } from "../../Utils/Helper";
import { SingleValue } from "react-select";
import moment from "moment";
import { toast } from "react-toastify";

const ScheduleClasses = () => {
  // const _form = {
  //   type: "",
  //   start_time: "",
  //   end_time: "",
  // };
  interface FormType {
    type: string;
    start_time: string;
    end_time: string;
    class_name: string;
    class_room: string;
  }
  const _form: FormType = {
    type: "",
    start_time: "",
    end_time: "",
    class_room: "",
    class_name: "", // Corrected property name
  };
  const [form, setForm] = useState(_form);
  const routes = all_routes;
  const data = scheduleClass;
  const route = all_routes;
  const navigate = useNavigate();
  const [admission_list, setAdmissionList] = useState([]);
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const [new_class_name, setNewClassName] = useState([]);
  const [new_data, setNewData] = useState([]);
  const [new_subjet, setSubject] = useState([]);
  const handleSubmit = () => {
    _post(
      "time_table?query_type=create",
      form,
      (res) => {
        // if (res.success) {
        toast.success("Submitted successfully");
        getTimeTable();
        // navigate(-1);
        // }
      },
      (err) => {
        // Handle error
        console.log(err);
      }
    );
    setForm(_form);
  };

  const getTimeTable = () => {
    _get(
      `get_time_table`,
      (res) => {
        setNewData(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const [time_table, setTimeTable] = useState([]);
  // const [new_time_table, setNewTimeTable] = useState([]);
  const [new_time_table_end, setNewTimeTableEnd] = useState([]);
  // const [new_time_table_end1, setNewTimeTableEnd1] = useState([]);
  const get_class_routine = () => {
    _get(
      `get_class_routine?query_type=selec`,
      (res) => {
        console.log(res);
        setTimeTable(
          res.results.map((s: any) => ({
            ...s,
            start_time: moment(s.start_time).format("DD/MM/YYYY H:mm:ss:A"),
            end_time: moment(s.end_time).format("DD/MM/YYYY H:mm:ss:A"),
          }))
        );
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const get_class_routine_end = () => {
    _get(
      `get_class_routine?query_type=selec`,
      (res) => {
        console.log(res);
        setNewTimeTableEnd(
          res.results.map((s: any) => ({
            ...s,
            start_time: moment(s.start_time).format("DD/MM/YYYY H:mm:ss:A"),
            end_time: moment(s.end_time).format("DD/MM/YYYY H:mm:ss:A"),
          }))
        );
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const getClassName = () => {
    _get(
      `get_class_management?query_type=select_class_name`,
      (res) => {
        console.log(res);
        setNewClassName(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const getSubject = () => {
    _get(
      "get_subject_management",
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubject(res.results);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  useEffect(() => {
    getClassName();
    getTimeTable();
    get_class_routine();
    get_class_routine_end();
    getSubject();
  }, []);
  const classOptions = new_class_name.map((i: any) => ({
    label: i.class_name,
    value: i.class_name,
  }));
  const classOptions4 = new_subjet.map((i: any) => ({
    label: i.subject,
    value: i.subject,
  }));
  const classOptions3 = new_time_table_end.map((i: any) => ({
    label: i.end_time,
    value: i.end_time,
  }));
  const classOptions2 = time_table.map((i: any) => ({
    label: i.start_time,
    value: i.start_time,
  }));
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      // sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },

    {
      title: "Class",
      dataIndex: "class_name",
      // sorter: (a: TableData, b: TableData) => a.type.length - b.type.length,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      // sorter: (a: TableData, b: TableData) => a.type.length - b.type.length,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      // sorter: (a: TableData, b: TableData) =>
      //   a.startTime.length - b.startTime.length,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      // sorter: (a: TableData, b: TableData) =>
      //   a.endTime.length - b.endTime.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Active" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: () => (
    //     <>
    //       <div className="d-flex align-items-center">
    //         <div className="dropdown">
    //           <Link
    //             to="#"
    //             className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="ti ti-dots-vertical fs-14" />
    //           </Link>
    //           <ul className="dropdown-menu dropdown-menu-right p-3">
    //             {/* <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#edit_Schedule"
    //               >
    //                 <i className="ti ti-edit-circle me-2" />
    //                 Select
    //               </Link>
    //             </li> */}
    //             {/* <li>
    //               <Link
    //                 className="dropdown-item rounded-1"
    //                 to="#"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#delete-modal"
    //               >
    //                 <i className="ti ti-trash-x me-2" />
    //                 Delete
    //               </Link>
    //             </li> */}
    //           </ul>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Schedule</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Classes </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Schedule
                  </li>
                </ol>
              </nav>
            </div>
            {/* {JSON.stringify(time_table)} */}
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_Schedule"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add Schedule
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Schedule Classes</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Type</label>
                              <CommonSelect
                                className="select"
                                options={classselect}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              {/* {JSON.stringify(new_data)} */}
              <Table columns={columns} dataSource={new_data} Selection={true} />
              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <div>
        {/* Add Schedule */}
        <div className="modal fade" id="add_Schedule">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Schedule</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        {/* {JSON.stringify(form)} */}
                        <label className="form-label">Class</label>
                        <CommonSelect
                          // className="select"
                          // options={classselect}
                          className="select"
                          options={classOptions}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              class_name: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="mb-3">
                        {/* {JSON.stringify(form)} */}
                        <label className="form-label">Subject</label>
                        <CommonSelect
                          // className="select"
                          // options={classselect}
                          className="select"
                          options={classOptions4}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              subject: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Start Time </label>
                        <CommonSelect
                          className="select"
                          options={classOptions2}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              start_time: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      {/* {JSON.stringify(form)} */}
                      <div className="mb-3">
                        <label className="form-label">End Time </label>
                        <CommonSelect
                          className="select"
                          options={classOptions3}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              end_time: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="status-toggle modal-status">
                          <input type="checkbox" id="user1" className="check" />
                          <label htmlFor="user1" className="checktoggle">
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Add Schedule
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Schedule */}
        {/* Edit Schedule */}
        <div className="modal fade" id="edit_Schedule">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Schedule</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      {JSON.stringify(admission_list)}
                      <div className="mb-3">
                        <label className="form-label">Class</label>
                        <CommonSelect
                          className="select"
                          options={admission_list.map((i: any) => ({
                            label: i.class_name,
                            value: i.class_name,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              class_name: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Start Time </label>
                        <CommonSelect
                          className="select"
                          options={admission_list.map((i: any) => ({
                            label: i.start_time,
                            value: i.start_time,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              class_: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">End Time </label>
                        <CommonSelect
                          className="select"
                          options={admission_list.map((i: any) => ({
                            label: i.end_time,
                            value: i.end_time,
                          }))}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              class_: newValue ? newValue.value : "",
                            }))
                          }
                        />
                      </div>
                      <div className="modal-satus-toggle d-flex align-items-center justify-content-between">
                        <div className="status-title">
                          <h5>Status</h5>
                          <p>Change the Status by toggle </p>
                        </div>
                        <div className="status-toggle modal-status">
                          <input
                            type="checkbox"
                            id="user2"
                            className="check"
                            defaultChecked
                          />
                          <label htmlFor="user2" className="checktoggle">
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Schedule */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </div>
    </div>
  );
};

export default ScheduleClasses;
