import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";

interface TimetableItem {
  id: number;
  day: string;
  class_name: string;
  subject: string;
  teacher_id: number;
  section: string;
  school_location: string;
  start_time: string;
  end_time: string;
  status: string;
  created_at: string;
  updated_at: string;
  teacher: string;
}

interface GroupedTimetable {
  [key: string]: TimetableItem[];
}

interface TimetableCardsProps {
  data: TimetableItem[];
}

const TimetableCards: React.FC<TimetableCardsProps> = ({ data = [] }) => {
  // Grouping the data by 'day'
  const groupedData: GroupedTimetable = data.reduce((acc, item) => {
    if (!acc[item.day]) {
      acc[item.day] = [];
    }
    acc[item.day].push(item);
    return acc;
  }, {} as GroupedTimetable);

  const colors = ['bg-transparent-primary','bg-transparent-info','bg-transparent-warning','bg-transparent-success','bg-transparent-default','bg-transparent-primary','bg-transparent-yellow']
  return (
    <div className="card-body pb-0">
              <div className="d-flex flex-nowrap overflow-auto">
        {Object.keys(groupedData).map((day,idx) => (
           <div key={idx} className="d-flex flex-column me-4 flex-fill">
            <div className="mb-3">
            <h5>{day}</h5>
            </div>
            <div className="d-flex flex-nowrap overflow-auto">
              <div className="d-flex flex-column me-4 flex-fill">
                {groupedData[day].map((item) => (
                  <div key={item.id} className={` ${colors[idx]} rounded p-3 mb-4`}>
                    <p className="d-flex align-items-center text-nowrap mb-1">
                    <b>  <i className="ti ti-clock me-1" />
                     {item.start_time} - {item.end_time}</b>
                    </p>
                    <div className="text-dark">Subject: {item.subject}</div>
                    <div className="text-dark">Class: {item.class_name}</div>
                    <div className="bg-white rounded p-1 mt-3">
                      <Link
                        to={all_routes.teacherDetails}
                        className="text-muted d-flex align-items-center"
                      >
                        <span className="avatar avatar-sm me-2">
                          <ImageWithBasePath
                            src="assets/img/teachers/teacher-07.jpg"
                            alt="Img"
                          />
                        </span>
                        {item.teacher}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimetableCards;
