import { academicYear, school } from "../../core/common/selectoption/selectoption";
import {
  AUTH,
  LOADING_LOGIN,
  LOADING_SIGNUP,
  LOGOUT,
  AUTH_ERROR,
  ERRORS,
  SET_SCHOOL_LOCATION,
  SET_ACADEMIC_YEAR
} from "../actions/types";

interface ActionTypes {
  type: string;
  payload?: {
    user?: any;
    school?: any;
    classes?: any[];
    subjects?: any[];
    admin?: any;
    student?: any;
    teacher?: any;
    school_location?:string;
    academic_year?:string;
  };
}

const initialState = {
  authenticated: false,
  user: {},
  admin: {},
  student: {},
  teacher: {},
  loadingLogin: false,
  loadingSignup: false,
  school: {},
  school_location:null,
  academic_year:academicYear[0].value||"",
  classes: [],
  subjects: [],
  requestIsValid: true,
  error: "",
  loadingApp: false,
  item_code: "",
  recoverPhone: { code: "", phone: "" },
};

export default function auth(state = initialState, action: ActionTypes) {
  let newState;
  switch (action.type) {
    case AUTH:
      newState = {
        ...state,
        user: action.payload?.user,
        authenticated: true,
        school: action.payload?.school || state.school,
        classes: action.payload?.classes || state.classes,
        subjects: action.payload?.subjects || state.subjects,
        admin: action.payload?.admin || state.admin,
        student: action.payload?.student || state.student,
        school_location: action.payload?.user?.school_location
      };
      break;

    case LOADING_LOGIN:
      newState = {
        ...state,
        loadingLogin: !state.loadingLogin,
      };
      break;

    case ERRORS:
      newState = {
        ...state,
        error: action.payload,
      };
      break;

    case LOADING_SIGNUP:
      newState = {
        ...state,
        loadingSignup: !state.loadingSignup,
      };
      break;

    case AUTH_ERROR:
      newState = {
        ...state,
        error: "",
      };
      break;

      case SET_SCHOOL_LOCATION:
        newState = {
          ...state,
          school_location: action.payload,
        };
      break;
      case SET_ACADEMIC_YEAR:
      newState = {
       ...state,
        academic_year: action.payload,
      };
      break;
    case LOGOUT:
      newState = {
        ...state,
        authenticated: false,
        user: {},
        admin: {},
        school: null,
        classes: [],
        subjects: [],
      };
      break;

    default:
      newState = state;
  }
  return newState;
}
