import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { _get, _post } from "../../Utils/Helper";

// Define the type for the student data
interface Student {
  id?: number;
  full_name?: string;
  age?: number;
  section:string;
  // Add other properties as per your data structure
}

// Define the props type for the component
interface StudentModalsProps {
  student_id?: number; // Type of student_id (you can change it if it's not a number)
}

const StudentModals: React.FC<StudentModalsProps> = (props) => {
  const { student_id } = props;
  const StudentData ={
    id: 0,
    full_name:'',
    age: 0,
    section:''
    // Add other properties as per your data structure
  }

  // Initialize the student state with the Student type
  const [student, setStudent] = useState<Student>(StudentData);
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    _get(
      `students?query_type=select&id=${student_id}`,
      (res: { data: Student[] }) => {
        if (res.data && res.data.length > 0) {
          setStudent(res.data[0]);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
  }, [student_id]);

  useEffect(() => {
    if(student.section){
    _post(
      `revenue-heads`,
      {
        query_type: "select",
        section:student.section,
        class_level:'1'
      },
      (resp) => {
        setPayments(resp.data);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  }, [student.section]);

  return (
    <div
      className="modal fade"
      id="add_fees_collect"
      data-bs-backdrop="static" // Prevent closing by clicking outside
      data-bs-keyboard="false" // Prevent closing with the escape key
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <div className="card">
            {/* {JSON.stringify(student)}x1 */}
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">{student?.full_name || ""} Fees</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="dropdown mb-3 me-2">
                  <Link
                    to=""
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-calendar-due me-2" />
                    Year : 2024 / 2025
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="" className="dropdown-item rounded-1">
                        Year : 2024 / 2025
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="dropdown-item rounded-1">
                        Year : 2023 / 2024
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="dropdown-item rounded-1">
                        Year : 2022 / 2023
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Fees List */}
              <div className="custom-datatable-filter table-responsive">
                <table className="table datatable">
                  <thead className="thead-light">
                    <tr>
                      <th>Description</th>
                      <th>Due Date</th>
                      <th>Amount (NGN)</th>
                      <th>Status</th>
                      <th>Discount (NGN)</th>
                      <th>Fine (NGN)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment: any, index: number) => (
                      <tr key={index}>
                        <td>
                          <p className="text-primary fees-group">
                            <span className="d-block">{payment.description}</span>
                          </p>
                        </td>
                        <td>{payment.due_date}</td>
                        <td>{payment.amount}</td>
                        <td>
                          <span className="badge badge-soft-success d-inline-flex align-items-center">
                            <i className="ti ti-circle-filled fs-5 me-1" />
                            {payment.status}
                          </span>
                        </td>
                        <td>0%</td>
                        <td>0</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 5,
                }}
              >
                <Button
                  onClick={() => navigate(`/examsscore?application_no=${student_id}`)}
                >
                  Pay
                </Button>
              </div>
              {/* /Fees List */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentModals;
