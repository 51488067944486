export const guardianListData = [
  {
    key: "1",
    id: "G153735",
    GuardianImage: "assets/img/guardians/guardian-01.jpg",
    ChildImage: "assets/img/students/student-01.jpg",
    name: "William",
    Addedon: "Added on 25 Mar 2024",
    Child: "Janet",
    class: "III, A",
    phone: "+1 82392 37359",
    email: "william@example.com",
  },
  {
    key: "2",
    id: "G153734",
    GuardianImage: "assets/img/guardians/guardian-02.jpg",
    ChildImage: "assets/img/students/student-02.jpg",
    name: "Stacey",
    Addedon: "Added on 18 Mar 2024",
    Child: "Joann",
    class: "IV, B",
    phone: "+1 28249 13139",
    email: "stacey@example.com",
  },
  {
    key: "3",
    id: "G153733",
    GuardianImage: "assets/img/guardians/guardian-03.jpg",
    ChildImage: "assets/img/students/student-03.jpg",
    name: "George",
    Addedon: "Added on 14 Mar 2024",
    Child: "Kathleen",
    class: "III, A",
    phone: "+1 74284 34849",
    email: "george@example.com",
  },
  {
    key: "4",
    id: "G153732",
    GuardianImage: "assets/img/guardians/guardian-04.jpg",
    ChildImage: "assets/img/students/student-04.jpg",
    name: "Sarah",
    Addedon: "Added on 27 Feb 2024",
    Child: "Gifford",
    class: "I, B",
    phone: "+1 49230 23294",
    email: "sarah@example.com",
  },
  {
    key: "5",
    id: "G153731",
    GuardianImage: "assets/img/guardians/guardian-05.jpg",
    ChildImage: "assets/img/students/student-05.jpg",
    name: "Gary",
    Addedon: "Added on 11 Feb 2024",
    Child: "Lisa",
    class: "II, B",
    phone: "+1 82239 42492",
    email: "gary@example.com",
  },
  {
    key: "6",
    id: "G153730",
    GuardianImage: "assets/img/guardians/guardian-06.jpg",
    ChildImage: "assets/img/students/student-06.jpg",
    name: "Linda",
    Addedon: "Added on 24 Jan 2024",
    Child: "Ralph",
    class: "III, B",
    phone: "+1 90139 45835",
    email: "linda@example.com",
  },
  {
    key: "7",
    id: "G153729",
    GuardianImage: "assets/img/guardians/guardian-07.jpg",
    ChildImage: "assets/img/students/student-07.jpg",
    name: "Jeffrey",
    Addedon: "Added on 19 Jan 2024",
    Child: "Julie",
    class: "V, A",
    phone: "+1 23230 37402",
    email: "jeffrey@example.com",
  },
  {
    key: "8",
    id: "G153728",
    GuardianImage: "assets/img/guardians/guardian-08.jpg",
    ChildImage: "assets/img/students/student-08.jpg",
    name: "Galina",
    Addedon: "Added on 08 Jan 2024",
    Child: "Janet",
    class: "III, A",
    phone: "+1 91304 34834",
    email: "galina@example.com",
  },
  {
    key: "9",
    id: "G153727",
    GuardianImage: "assets/img/guardians/guardian-09.jpg",
    ChildImage: "assets/img/students/student-09.jpg",
    name: "Paul",
    Addedon: "Added on 22 Dec 2023",
    Child: "Susan",
    class: "VIII, B",
    phone: "+1 84394 28204",
    email: "paul@example.com",
  },
  {
    key: "10",
    id: "G153726",
    GuardianImage: "assets/img/guardians/guardian-10.jpg",
    ChildImage: "assets/img/students/student-10.jpg",
    name: "Tracy",
    Addedon: "Added on 15 Dec 2024",
    Child: "Richard",
    class: "VII, B",
    phone: "+1 93402 39342",
    email: "tracy@example.com",
  },
  {
    key: "11",
    id: "G153725",
    GuardianImage: "assets/img/guardians/guardian-11.jpg",
    ChildImage: "assets/img/students/student-11.jpg",
    name: "Ramos",
    Addedon: "Added on 10 Dec 2023",
    Child: "Veronica",
    class: "IX, A",
    phone: "+1 57571 74821",
    email: "ram@example.com",
  },
];
