import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Typography,
  message,
} from "antd";
import useQuery, { _get, _post } from "../../Utils/Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import dayjs from "dayjs";
const { Title, Paragraph } = Typography;


interface StudentAssignment {
  id: number;
  assignment_id: number;
  student_id: number;
  admission_no: string | null;
  class_name: string;
  subject: string;
  teacher_name: string;
  student_name:string;
  level: string | null;
  attachment: string | null;
  title: string | null;
  submitting_date: string | null;
  content: string;
  marks: number | null;
  score: number | null;
  remark: string | null;
  comment: string | null;
  
}

const MarkAssignments = () => {
  const studentAssignment = {
    id: 0,
    assignment_id: 0,
    student_id: 0,
    student_name:"",
    admission_no: "" ,
    class_name: "",
    subject: "",
    teacher_name: "",
    level: "" ,
    attachment: "" ,
    content: "",
    marks: null ,
    score:  null,
    remark: null,
    comment: "" ,
    submitting_date:"",
    title:"",
  }
  const [assignments, setAssignments] = useState<StudentAssignment[]>([]);
  const [assignment, setAssignment] = useState<StudentAssignment>(studentAssignment);
  const [selectedAssignment, setSelectedAssignment] = useState<StudentAssignment | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const q = useQuery();
  const assignment_id = q.get('assignment_id');

  const [form] = Form.useForm();

  const getGivenAssignments = useCallback(() => {
    if (assignment_id) {
      _get(
        `assignments?query_type=select&id=${assignment_id}`,
        (res) => {
          if (res.success && res.data) {
            setAssignment(res.data[0]);
          }
        },
        (err) => {
          console.error("Error fetching assignments:", err);
        }
      );
    }
  }, [assignment_id]);


  const getAssignments = useCallback(() => {
    if (user.id) {
      _get(
        `student/assignments?query_type=select&teacher_id=${user.id}&assignment_id=${assignment_id}`,
        (res) => {
          if (res.success && res.data) {
            setAssignments(res.data);
          }
        },
        (err) => {
          console.error("Error fetching assignments:", err);
        }
      );
    }
  }, [user.id]);

  const handleMarkAssignment = async (values: any) => {
    if (!selectedAssignment) return;

    const payload = {
      ...values,
      query_type: "update",
      id: selectedAssignment.id,
    };

    try {
      await _post(
        "student/assignments",
        payload,
        () => {
          message.success("Assignment marked successfully!");
          setModalVisible(false);
          form.resetFields();
          getAssignments();
        },
        (err) => {
          console.error("Error marking assignment:", err);
          message.error("Failed to mark assignment.");
        }
      );
    } catch (error) {
      console.error("Error submitting marks:", error);
    }
  };

  const openMarkingModal = (assignment: StudentAssignment) => {
    setSelectedAssignment(assignment);
    form.setFieldsValue({
      score: assignment.score,
      remark: assignment.remark,
      comment: assignment.comment,
    });
    setModalVisible(true);
  };

  useEffect(() => {
    getGivenAssignments();
    getAssignments();
  }, [getAssignments,getGivenAssignments]);

  const columns = [
    {
      title: "Class",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    // {
    //   title: "Content",
    //   dataIndex: "content",
    //   key: "content",
    // },
    {
      title: "Submission Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => dayjs(text).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (text: string, record:any) =><p>{record.score}/{record.marks}</p>
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: StudentAssignment) => (
        <Button type="primary" onClick={() => openMarkingModal(record)}>
          Mark
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">

      <Title level={2} style={{ textAlign: "center" }}>
        Assignment:  {assignment.title}
      </Title>

      {assignment.id ? (
        <div style={{ marginBottom: "24px", padding: "16px", border: "1px solid #f0f0f0", borderRadius: "8px" }}>
          <Paragraph>
            <b>Class:</b> {assignment.class_name}
          </Paragraph>
          <Paragraph>
            <b>Subject:</b> {assignment.subject}
          </Paragraph>
          <Paragraph>
            <b>Teacher:</b> {assignment.teacher_name}
          </Paragraph>
          <Paragraph>
            <b>Due Date:</b> {assignment.submitting_date} 
          </Paragraph>
          {assignment.attachment?<Paragraph>
            <b>Attachment:</b>  <a href={assignment.attachment} target="_blank" rel="noopener noreferrer">
                View Attachment
              </a>
          </Paragraph>:null}
          <Paragraph>
            <b>Content:</b> {assignment.content}
          </Paragraph>
        </div>
      ):null}

      <Table
        columns={columns}
        dataSource={assignments}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title="Mark Assignment"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div>
        <Title level={2} style={{ textAlign: "center" }}>
        Assignment:  {selectedAssignment?.subject}
      </Title>
      Teacher
      {selectedAssignment?.id ? (
        <div style={{ marginBottom: "24px", padding: "16px", border: "1px solid #f0f0f0", borderRadius: "8px" }}>
          <Paragraph>
            <b>Student Name:</b> {selectedAssignment?.student_name}
          </Paragraph>
          <Paragraph>
            <b>Admission No:</b> {selectedAssignment?.admission_no}
          </Paragraph>
          {selectedAssignment.attachment?<Paragraph>
            <b>Attachment:</b>  <a href={selectedAssignment.attachment} target="_blank" rel="noopener noreferrer">
                View Attachment
              </a>
          </Paragraph>:null}
          <Paragraph>
            <b>Content:</b> {selectedAssignment.content}
          </Paragraph>
        </div>
      ):null}
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleMarkAssignment}
        >
          <Form.Item label="Score" name="score" rules={[{ required: true, message: "Please enter score" }]}>
            <Input type="number" placeholder="Enter score" />
          </Form.Item>
          <Form.Item label="Remark" name="remark" rules={[{ required: true, message: "Please enter a remark" }]}>
            <Input placeholder="Enter remark" />
          </Form.Item>
          <Form.Item label="Comment" name="comment">
            <Input.TextArea rows={4} placeholder="Enter comment (optional)" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit Marks
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
    </div>
  );
};

export default MarkAssignments;
