import { useCallback, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import useQuery, { _get, _post, server_url } from "../../Utils/Helper";
import { toast } from "react-toastify";

const ViewLesson = () => {
  const routes = all_routes;
  interface Assignment {
    class_name: string;
    assignment_date: string;
    submitting_date: string;
    attachment: string;
    content: string;
    subject: string;
    title: string;
    teacher: string;
  }
  interface Classes {
    class_name: string;
  }
  const _form = {
    assignment_date: "",
    class_name: "",
    submitting_date: "",
    attachment: "",
    subject: "",
    content: "",
    query_type: "create",
    teacher: "",
    title: "",
  };

  const id = useParams().id;
  const query = useQuery();
  const lesson_id = query.get("lesson_id");
  const [new_data, setNewData] = useState<Assignment>(_form);

  const onChangeIle = (e: any) => {
    const attachmentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("media", attachmentFile);
    console.log(formData, "LLSS");

    fetch(server_url + "/upload_transfer_certificate-document", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Uploaded Image URL:", data.url);
        setForm((p) => ({ ...p, upload: data.url }));
        alert("Uploaded successfully");
      })
      .catch((error) => {
        console.error("Error uploading attachment:", error);
      });
  };

  const [form, setForm] = useState(_form);

  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    _post(
      "lessons?query_type=create",
      form,
      (res) => {
        getLesson();
        // setLoading(false);
        toast.success("Created Assignment successfully");
      },
      (err) => {
        // setLoading(false);
        console.error("Error creating Assignment:", err);
      }
    );


  };
  const getLesson = useCallback(() => {
    _get(
      `assignments?query_type=select&id=${lesson_id}`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setNewData(res.data[0]);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  },[lesson_id]);

 

  useEffect(() => {
    getLesson();
  }, [getLesson]);
  



  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div
            className="container-fluid d-flex align-items-center justify-content-center"
            style={{
              display: "flex",
            }}
          >
            <div className="row w-100" style={{ justifyContent: "center" }}>
              <div className="col-md-12 col-12">
                <div
                  className="card"
                  style={{
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div className="card-body p-4">
                    <div className="my-auto mb-2">
                    <div className="row">
                    <div className="col-md-6 col-12"> 
                       <p className="page-title mb-1">
                       <b>Subject:</b> {new_data?.subject}
                      </p>
                      </div>
                    <div className="col-md-6 col-12">
                      <p className="page-title mb-1">
                      <b> Teacher:</b> {new_data?.teacher}
                      </p>
                      </div>
                      <div className="col-md-12 col-12">
                        <p className="text-center">
                          <b>Title:</b> {new_data?.title}
                        </p>
                      </div>
                      <div className="col-md-12 col-12">
                      {new_data?.attachment?<div className="mt-5">
                        <Link
                          className="btn btn-secondary"
                          to={new_data?.attachment}
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px 15px",
                            backgroundColor: "#6c757d",
                            color: "#fff",
                            textDecoration: "none",
                            borderRadius: "5px",
                          }}
                        >
                          <p className="ti ti-gift"></p> View Attachment
                        </Link>
                      </div>:null}
                      </div>
                      <div className="col-md-12 col-12">
                      <div className="container page-content m-3">
                        {new_data?.content}
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">

                        </div>
                      </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ViewLesson;
