import React from "react";
import { Link } from "react-router-dom";

interface AttendanceRecord {
  created_at: string;
  status: string;
}

interface AttendanceBadgesProps {
  data: AttendanceRecord[];
}

const AttendanceBadges: React.FC<AttendanceBadgesProps> = ({ data }) => {
  // Generate last 7 days
  const last7Days = Array.from({ length: 5 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - (6 - i));
    return {
      day: [ "M", "T", "W", "T", "F"][date.getDay()],
      date: date.toLocaleDateString("en-GB"), // "dd/mm/yyyy"
    };
  });

  // Map attendance data to a date-based object
  const attendanceByDate = data.reduce((acc, entry) => {
    const date = new Date(entry.created_at).toLocaleDateString("en-GB");
    acc[date] = entry.status; // Use the status field
    return acc;
  }, {} as Record<string, string>);

  // Render badges
  return (
    <div className="d-flex align-items-center rounded gap-1 flex-wrap">
      {last7Days.map(({ day, date }, index) => {
        const status = attendanceByDate[date] || null; // Check attendance for this date
        let badgeClass = "badge badge-lg bg-white border text-default";

        if (status === "Present") badgeClass = "badge badge-lg bg-success text-white";
        else if (status === "Late") badgeClass = "badge badge-lg bg-danger text-white";

        return (
          <Link key={index} to="#" className={badgeClass}>
            {day}
          </Link>
        );
      })}
    </div>
  );
};

export default AttendanceBadges;
