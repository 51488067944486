export const transportdriver = [
  {
    key: "1",
    id: "D0482",
    name: "Thomas",
    phone: "+1 64044 74890",
    driverLicenseNo: "LC7899456689",
    address: "2233 Wood Street, Slidell, LA",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-01.jpg",
  },
  {
    key: "2",
    id: "D0481",
    name: "Mary",
    phone: "+1 14541 55665",
    driverLicenseNo: "LCS4579454785",
    address: "2693 Parker Drive, Cleveland, OH",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-02.jpg",
  },
  {
    key: "3",
    id: "D0480",
    name: "Michael",
    phone: "+1 78954 85461",
    driverLicenseNo: "LCS3254789541",
    address: "4650 Aviation Way, Los Angeles, CA",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-03.jpg",
  },
  {
    key: "4",
    id: "D0479",
    name: "Jessie",
    phone: "+1 12345 68891",
    driverLicenseNo: "LCS1478545214",
    address: "3167 Stadium Drive, Worcester, MA",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-04.jpg",
  },
  {
    key: "5",
    id: "D0478",
    name: "Robert",
    phone: "+1 78454 7884",
    driverLicenseNo: "LCS6985478541",
    address: "1609 Smith Street, Worcester, MA",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-05.jpg",
  },
  {
    key: "6",
    id: "D0477",
    name: "Colleen",
    phone: "+1 78546 97894",
    driverLicenseNo: "LCS3254145874",
    address: "3341 Palmer Road, Columbus, OH",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-06.jpg",
  },
  {
    key: "7",
    id: "D0476",
    name: "Arthur",
    phone: "+1 97878 87854",
    driverLicenseNo: "LCS4854651254",
    address: "2261 Sweetwood Drive, Denver, CO",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-07.jpg",
  },
  {
    key: "8",
    id: "D0475",
    name: "Claudia",
    phone: "+1 64599 78542",
    driverLicenseNo: "LCS9658745214",
    address: "4025 Khale Street, Folly Beach, SC",
    status: "Inactive",
    statusClass: "badge badge-soft-danger",
    img: "assets/img/parents/parent-08.jpg",
  },
  {
    key: "9",
    id: "D0474",
    name: "Johnson",
    phone: "+1 45781 45145",
    driverLicenseNo: "LCS7854652145",
    address: "3521 Harvest Lane Kansas City, MO",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-09.jpg",
  },
  {
    key: "10",
    id: "D0473",
    name: "Marquita",
    phone: "+1 45112 48879",
    driverLicenseNo: "LCS9985231258",
    address: "2603 Wood Duck Drive Marquette, MI",
    status: "Active",
    statusClass: "badge badge-soft-success",
    img: "assets/img/parents/parent-10.jpg",
  },
];
