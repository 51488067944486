import React, { useEffect, useRef, useState } from "react";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import {
  classSection,
  classSylabus,
  count,
  durationOne,
  examOne,
  examtwo,
  maxMark,
  minMark,
  mothertongue,
  startTime,
  startTimeOne,
} from "../../../../core/common/selectoption/selectoption";
import Select, { SingleValue } from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../../core/common/dataTable/index";
import { examSchedule } from "../../../../core/data/json/exam_schedule";
import { TableData } from "../../../../core/data/interface";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { all_routes } from "../../../router/all_routes";
import TooltipOption from "../../../../core/common/tooltipOption";
import { _get, _post } from "../../../Utils/Helper";
import moment from "moment";
import { TimePicker } from "antd";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

const ExamSchedule = () => {
  interface FormType {
    invigilator: string;
    subject: string;
    start_time: Date | null;
    end_time: Date | null;
    class_name: string;
    exammination_hall: string;
    duration: string;
    exam_date: string;
    exam_name: string;
  }
  const _form: FormType = {
    invigilator: "",
    subject: "",
    start_time: null as Date | null,
    end_time: null as Date | null,
    exammination_hall: "",
    class_name: "",
    duration: "",
    exam_date: "",
    exam_name: "", // Corrected property name
  };

  const routes = all_routes;
  const data = examSchedule;
  const [new_data, setNewData] = useState([]);
  const [new_time_table_end, setNewTimeTableEnd] = useState([]);
  const [new_class_name, setNewClassName] = useState([]);
  const [form, setForm] = useState(_form);
  const [new_subjet, setSubject] = useState([]);
  const [time_table, setTimeTable] = useState([]);
  const [admissionList, setAdmissionList] = useState([]);
  const [teacherNames, setTeacherNames] = useState([]);
  const getModalContainer2 = () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const navigate = useNavigate();
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const getTeaherPersonal = () => {
    _get(
      `get_exammination?query_type=select`,
      (res) => {
        console.log(res);
        if (res.results && res.results.length) {
          setNewData(
            res.results?.map((s: any) => ({
              ...s,
              start_time: moment(s.start_time, "HH:mm:ss").format("HH:mm:ssA"),
              end_time: moment(s.end_time, "HH:mm:ss").format("HH:mm:ssA"),
            }))
          );
        }
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  const handleSubmit = () => {
    _post(
      "exammination?query_type=create",
      form,
      (res) => {
        if (res.success) {
          toast.success("Submitted successfully");
          // navigate(-1);
        }
      },
      (err) => {
        // Handle error
        console.error("Submission failed:", err);
      }
    );

    // Reset the form after submission
    setForm(_form);
  };

  const [loading, setLoading] = useState(false);
  const [examsCalendar, setExamsCalendar] = useState([]);
  const getClassName = () => {
    _get(
      `classes?query_type=select-all`,
      (res) => {
        console.log(res);
        setNewClassName(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const getSubject = () => {
    _get(
      `subjects?query_type=select-all`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setSubject(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  const getTeacherName = () => {
    _get(
      `get_teacher_personal_data_form?query_type=select-all`,
      (res) => {
        console.log("Teacher Name Response:", res);
        setTeacherNames(res.results);
      },
      (err) => {
        console.log("Error fetching teacher names:", err);
      }
    );
  };

  const getCalendars = () => {
    _get(
      `exam_calendar?query_type=select-all`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setExamsCalendar(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };
  useEffect(() => {
    getClassName();
    // getTimeTable();
    getCalendars();
    getTeaherPersonal();
    getTeacherName();
    getSubject();
  }, []);
  const classOptions3 = new_time_table_end?.map((i: any) => ({
    label: i.end_time,
    value: i.end_time,
  }));
  const teacherOptions = teacherNames?.map((i: any) => ({
    label: i.name,
    value: i.name,
  }));
  const examOptions = examsCalendar?.map((i: any) => ({
    label: i.exam_name,
    value: i.exam_name,
  }));
  const [newContents, setNewContents] = useState<number[]>([0]);
  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.subject}{" "}
            {/* Use record.subject instead of record.subject */}
          </Link>
        </>
      ),
      // sorter: (a: TableData, b: TableData) =>
      //   a.subject.length - b.subject.length, // Adjust the sorter to use subject
    },

    {
      title: "Class",
      dataIndex: "class_name",
      sorter: (a: TableData, b: TableData) =>
        a.startTime.length - b.startTime.length,
    },
    {
      title: "Exam Date",
      dataIndex: "exam_date",
      sorter: (a: TableData, b: TableData) =>
        a.examDate.length - b.examDate.length,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      sorter: (a: TableData, b: TableData) =>
        a.startTime.length - b.startTime.length,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      sorter: (a: TableData, b: TableData) =>
        a.endTime.length - b.endTime.length,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      sorter: (a: TableData, b: TableData) =>
        a.duration.length - b.duration.length,
    },
    {
      title: "Invegelator",
      dataIndex: "invigilator",
      sorter: (a: TableData, b: TableData) =>
        a.duration.length - b.duration.length,
    },
    {
      title: "Attendance",
      dataIndex: "id",
      render:  (text: string, record: any, index: number) => (
        <Link 
          className="btn btn-primary btn-small" 
          to={`${all_routes.examAttendance}?exam_id=${record.id}&subject=${record.subject}&class_name=${record.class_name}`}
         onClick={()=>navigate(`${all_routes.examAttendance}?exam_id=${record.id}&subject=${record.subject}&class_name=${record.class_name}`)}>Attendace</Link>
      )
    },
    // {
    //   title: "Max Mark",
    //   dataIndex: "maxMarks",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.maxMarks.length - b.maxMarks.length,
    // },
    // {
    //   title: "Min Mark",
    //   dataIndex: "minMarks",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.minMarks.length - b.minMarks.length,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_exam_schedule"
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index: any) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Exam Schedule</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Academic </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Exam Schedule
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_exam_schedule"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add Exam Schedule
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Exam Schedule</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class 1-A</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Exam Date</label>
                              <CommonSelect
                                className="select"
                                options={examOne}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* {JSON.stringify(new_data)} */}
              {/* Guardians List */}
              <Table dataSource={new_data} columns={columns} Selection={true} />

              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      <>
        {/* Add Exam Schedule */}
        <div className="modal fade" id="add_exam_schedule">
          <div className="modal-dialog modal-dialog-centered  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Exam Schedule</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              {/* {JSON.stringify(form)} */}
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Class</label>
                            <CommonSelect
                              className="select"
                              options={new_class_name?.map((i: any) => ({
                                label: i.class_name,
                                value: i.class_name,
                              }))}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  class_name: newValue ? newValue.value : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Section</label>
                            <CommonSelect
                              className="select"
                              options={classSection}
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Exam Code</label>
                            <input
                              type="text"
                              className="form-control"
                              name="exam_code"
                              value={form.exam_code}
                              onChange={handleChange}
                            />
                          </div>
                        </div> */}
                        <div className="col-md-4">
                          {/* {JSON.stringify(new_subjet)} */}
                          <div className="mb-3">
                            <label className="form-label">Subject</label>
                            <CommonSelect
                              className="select"
                              options={new_subjet?.map((i: any) => ({
                                label: i.subject,
                                value: i.subject,
                              }))}
                              defaultValue={{
                                label: form.subject,
                                value: form.subject,
                              }}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  subject: newValue ? newValue.value : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          {/* {JSON.stringify(new_subjet)} */}
                          <div className="mb-3">
                            <label className="form-label">Exams Name</label>
                            <CommonSelect
                              className="select"
                              options={examOptions}
                              defaultValue={{
                                label: form.exam_name,
                                value: form.exam_name,
                              }}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  exam_name: newValue ? newValue.value : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Date</label>
                            <input
                              type="date"
                              className="form-control"
                              name="exam_date"
                              value={form.exam_date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Start Time</label>
                            <div className="date-pic">
                              <TimePicker
                                getPopupContainer={getModalContainer2}
                                use12Hours
                                placeholder="Choose"
                                format="h:mm A"
                                className="form-control timepicker"
                                name="start_time"
                                onChange={(time) => {
                                  // Check if a valid time is selected
                                  if (time) {
                                    setForm((prev) => ({
                                      ...prev,
                                      start_time: time.toDate(), // Store as Date object
                                    }));
                                  } else {
                                    setForm((prev) => ({
                                      ...prev,
                                      start_time: null, // Reset if no valid time is selected
                                    }));
                                  }
                                }}
                              />

                              <span className="cal-icon">
                                <i className="ti ti-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">End Time</label>
                            <div className="date-pic">
                              <TimePicker
                                getPopupContainer={getModalContainer}
                                use12Hours
                                placeholder="Choose"
                                format="h:mm A"
                                className="form-control timepicker"
                                name="end_time"
                                onChange={(time) => {
                                  // Check if a valid time is selected
                                  if (time) {
                                    setForm((prev) => ({
                                      ...prev,
                                      end_time: time.toDate(), // Store as Date object
                                    }));
                                  } else {
                                    setForm((prev) => ({
                                      ...prev,
                                      end_time: null, // Reset if no valid time is selected
                                    }));
                                  }
                                }}
                              />

                              <span className="cal-icon">
                                <i className="ti ti-clock" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Duration(min)</label>
                            <CommonSelect
                              className="select"
                              options={durationOne}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  duration: newValue ? newValue.value : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">
                              Exammination Hall
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="exammination_hall"
                              value={form.exammination_hall}
                              onChange={handleChange}
                            />
                          </div>
                        </div> */}
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Invigilator</label>
                            <CommonSelect
                              className="select"
                              // options={teacher}
                              // defaultValue={class[0]}
                              options={teacherOptions}
                              handleChange={(newValue: SingleValue<Option>) =>
                                setForm((prev) => ({
                                  ...prev,
                                  invigilator: newValue ? newValue.value : "",
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {newContents.map((_, index) => (
                    <div className="exam-schedule-add">
                      <div className="exam-schedule-row d-flex align-items-center flex-wrap column-gap-3">
                        <div className="shedule-info flex-fill">
                          <div className="mb-3">
                            <label className="form-label">Exam Date</label>
                            <CommonSelect
                              className="select"
                              options={examOne}
                            />
                          </div>
                        </div>
                        <div className="shedule-info flex-fill">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>
                            <CommonSelect
                              className="select"
                              options={mothertongue}
                            />
                          </div>
                        </div>
                        <div className="shedule-info flex-fill">
                          <div className="mb-3">
                            <label className="form-label">Room No</label>
                            <CommonSelect className="select" options={count} />
                          </div>
                        </div>
                        <div className="shedule-info flex-fill">
                          <div className="mb-3">
                            <label className="form-label">Max Marks</label>
                            <CommonSelect
                              className="select"
                              options={maxMark}
                            />
                          </div>
                        </div>
                        <div className="shedule-info flex-fill">
                          <div className="d-flex align-items-end">
                            <div className="mb-3 flex-fill">
                              <label className="form-label">Min Marks</label>
                              <CommonSelect
                                className="select"
                                options={minMark}
                              />
                            </div>
                            {newContents.length > 1 && (
                              <div className="mb-3 ms-2">
                                <Link
                                  to="#"
                                  className="delete-schedule-table"
                                  onClick={() => removeContent(index)}
                                >
                                  <i className="ti ti-trash" />
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}
                  {/* <div>
                    <Link
                      to="#"
                      onClick={addNewContent}
                      className="btn btn-primary add-new-schedule"
                    >
                      <i className="ti ti-square-rounded-plus-filled me-2" />
                      Add New
                    </Link>
                  </div> */}
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Add Exam Schedule
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Add Exam Schedule */}
        {/* Edit Exam Schedule */}
        <div className="modal fade" id="edit_exam_schedule">
          <div className="modal-dialog modal-dialog-centered  modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Exam Schedule</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Class</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Class"
                              defaultValue="I"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Section</label>
                            <CommonSelect
                              className="select"
                              options={classSection}
                              defaultValue={classSection[1]}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Exam Name</label>
                            <CommonSelect
                              className="select"
                              options={examtwo}
                              defaultValue={examtwo[1]}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Start Time</label>
                            <CommonSelect
                              className="select"
                              options={startTime}
                              defaultValue={startTime[1]}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">End Time</label>
                            <CommonSelect
                              className="select"
                              options={startTimeOne}
                              defaultValue={startTimeOne[1]}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Duration(min)</label>
                            <CommonSelect
                              className="select"
                              options={durationOne}
                              defaultValue={startTime[1]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="exam-schedule-add">
                    <div className="exam-schedule-row d-flex align-items-center flex-wrap column-gap-3">
                      <div className="shedule-info flex-fill">
                        <div className="mb-3">
                          <label className="form-label">Exam Date</label>
                          <CommonSelect
                            className="select"
                            options={examOne}
                            defaultValue={examOne[1]}
                          />
                        </div>
                      </div>
                      <div className="shedule-info flex-fill">
                        <div className="mb-3">
                          <label className="form-label">Subject</label>
                          <CommonSelect
                            className="select"
                            options={mothertongue}
                            defaultValue={mothertongue[1]}
                          />
                        </div>
                      </div>
                      <div className="shedule-info flex-fill">
                        <div className="mb-3">
                          <label className="form-label">Room No</label>
                          <CommonSelect
                            className="select"
                            options={count}
                            defaultValue={count[1]}
                          />
                        </div>
                      </div>
                      <div className="shedule-info flex-fill">
                        <div className="mb-3">
                          <label className="form-label">Max Marks</label>
                          <CommonSelect
                            className="select"
                            options={maxMark}
                            defaultValue={maxMark[1]}
                          />
                        </div>
                      </div>
                      <div className="shedule-info flex-fill">
                        <div className="d-flex align-items-end">
                          <div className="mb-3 flex-fill">
                            <label className="form-label">Min Marks</label>
                            <CommonSelect
                              className="select"
                              options={minMark}
                              defaultValue={minMark[1]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Edit Exam Schedule */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default ExamSchedule;
