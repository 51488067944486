import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Table from "../../../../core/common/dataTable/index";
import { exam } from "../../../../core/data/json/exam";
import {
  academicYear,
  examOne,
  examtwo,
  startTime,
  startTimeOne,
} from "../../../../core/common/selectoption/selectoption";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import { TableData } from "../../../../core/data/interface";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import TooltipOption from "../../../../core/common/tooltipOption";
import { _get, _post } from "../../../Utils/Helper";
import { toast } from "react-toastify";
import { SingleValue } from "react-select";

const Exam = () => {
  const routes = all_routes;
  // const _form = {
  //   exam_name: "",
  //   exam_date: "",
  //   academic_year: "",
  //   start_date: "",
  //   end_date: "",
  // };

  interface FormType {
    start_date: Date | null;
    end_date: Date | null;
    exam_name: string;
    academic_year: string;
    exam_date: string;
  }

  const _form: FormType = {
    start_date: null,
    end_date: null,
    exam_name: "",
    academic_year: "",
    exam_date: "",
  };

  const [form, setForm] = useState(_form);
  const handleChange = (e: any) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };
  const [examsCalendar, setExamsCalendar] = useState([]);
  const handleSubmit = () => {
    _post(
      "exam_calendar?query_type=create",
      form,
      (res) => {
        if (res.success) {
          toast.success("Submitted successfully");
          getCalendars();
        }
      },
      (err) => {
        // Handle error
        console.error("Submission failed:", err);
      }
    );

    // Reset the form after submission
    setForm(_form);
  };

  const getCalendars = () => {
    _get(
      `exam_calendar?query_type=select-all`,
      (res) => {
        //   navigate(`/agent`)
        console.log(res);
        setExamsCalendar(res.data);
      },
      (err) => {
        // setLoading(false)
        console.log(err);
      }
    );
    // console.log(form)
  };

  useEffect(() => {
    getCalendars();
  }, []);
  const getModalContainer2 = () => {
    const modalElement = document.getElementById("modal_datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };
  const data = exam;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${month}-${day}-${year}`;
  const defaultValue = dayjs(formattedDate);

  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const handleDateChange = (name: string, newValue: any) => {
    // Ensure the newValue is a valid Day.js date object before formatting
    const formattedDate =
      newValue && dayjs(newValue).isValid()
        ? dayjs(newValue).format("YYYY-MM-DD")
        : "";

    setForm((prevForm) => ({
      ...prevForm,
      [name]: formattedDate,
    }));
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: any, b: any) => a.id.length - b.id.length,
    },
    {
      title: "Exam Name",
      dataIndex: "exam_name",
      sorter: (a: TableData, b: TableData) =>
        a.examName.length - b.examName.length,
    },
    // {
    //   title: "Exam Date",
    //   dataIndex: "exam_date",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.examDate.length - b.examDate.length,
    // },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sorter: (a: TableData, b: TableData) =>
        a.startTime.length - b.startTime.length,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      sorter: (a: TableData, b: TableData) =>
        a.endTime.length - b.endTime.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_exam"
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Exam</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Academic </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Exam
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_exam"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add Exam
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Exam List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Exam Name</label>
                              <CommonSelect
                                className="select"
                                options={examtwo}
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Exam Date</label>
                              <CommonSelect
                                className="select"
                                options={examOne}
                              />
                            </div> 
                          </div>*/}
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              <Table
                columns={columns}
                dataSource={examsCalendar}
                Selection={true}
              />

              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      <>
        {/* Add Exam */}
        <div className="modal fade" id="add_exam">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Exam</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                {/* {JSON.stringify(form)} */}
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Exam Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="exam_name"
                          value={form.exam_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Academic Year</label>
                        <CommonSelect
                          className="select"
                          options={academicYear}
                          // handleChange={({target: {value}})=>{   setForm((p) => ({ ...p, academic_year: value }));}}
                          handleChange={(newValue: SingleValue<Option>) =>
                            setForm((prev) => ({
                              ...prev,
                              academic_year: newValue ? newValue.value : "",
                            }))
                          }
                          defaultValue={{
                            label: form.academic_year,
                            value: form.academic_year,
                          }}
                          // onChange={handleChange}
                        />
                        {/* <select
                                    name="type_of_application"
                                    value={form.type_of_application}
                                    onChange={handleChange}>
                                    <option>---select---</option>
                                    <option>Nursery</option>
                                    <option>Primary</option>
                                    <option>Secondary</option>
                                </select> */}
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label">Exam Date</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            defaultValue={defaultValue}
                            placeholder="16 May 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div> */}
                      <div className="mb-3">
                        <label className="form-label">Start Date</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            name="start_date"
                            onChange={(e) => handleDateChange("start_date", e)}
                          />

                          <span className="cal-icon">
                            <i className="ti ti-clock" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">End Date</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            // defaultValue={form.start_date}
                            name="end_date"
                            onChange={(e) => handleDateChange("end_date", e)}
                          />

                          <span className="cal-icon">
                            <i className="ti ti-clock" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    className="btn btn-primary"
                    to="#"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit}
                  >
                    Add Exam
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Add Exam */}
        {/* Edit Exam */}
        <div className="modal fade" id="edit_exam">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Exam</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Exam Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Exam Name"
                          defaultValue="Week Test"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Exam Date</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            defaultValue={defaultValue}
                            placeholder="16 May 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Start Time</label>
                        <CommonSelect
                          className="select"
                          options={startTime}
                          defaultValue={startTime[1]}
                        />
                      </div>
                      <div className="mb-0">
                        <label className="form-label">End Time</label>
                        <CommonSelect
                          className="select"
                          options={startTimeOne}
                          defaultValue={startTimeOne[1]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    className="btn btn-primary"
                    to="#"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Edit Exam */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="btn btn-danger"
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default Exam;
