import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
// import { EntranceExamsScore } from "../../../core/data/json/entranceExamsScore";
import { TableData } from "../../../core/data/interface";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
// import StudentModals from "../studentModals";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  allClass,
  allSection,
  gender,
  names,
  status,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import TooltipOption from "../../../core/common/tooltipOption";
import { _get } from "../../Utils/Helper";

const EntranceExamsScore = () => {
  const _form = {
    search: "",
  };
  const [form, setForm] = useState(_form);

  const navigate = useNavigate();
  const [new_data, setNewData] = useState([]);

  // const handleChange = ({ target: { name, value } }) => {
  //     setForm((p) => ({ ...p, [name]: value }));
  // }

  // const getSecScl = () => {
  //   _get(
  //     `get_secondary_school_entrance_form`,
  //     (res) => {
  //       console.log(res);
  //       setNewData(res.results);
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // };

  const getSecScl = () => {
    _get(
      `get_secondary_school_entrance_form`,
      (res) => {
        console.log(res);
        setNewData(res.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getSecScl();
  }, []);

  const routes = all_routes;
  const data = EntranceExamsScore;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    {
      title: "Application No",
      dataIndex: "application_no",
      // render: (text: string) => (
      //   <Link to={routes.studentDetail} className="link-primary">
      //     {text}
      //   </Link>
      // ),
      // sorter: (a: TableData, b: TableData) =>
      //   a.AdmissionNo.length - b.AdmissionNo.length,
    },
    // {
    //   title: "Roll No",
    //   dataIndex: "RollNo",
    //   sorter: (a: TableData, b: TableData) => a.RollNo.length - b.RollNo.length,
    // },
    {
      title: "Name",
      dataIndex: "name_of_applicant",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          {/* <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.imgSrc}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link> */}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text} </Link>
            </p>
          </div>
        </div>
      ),
      // sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Last Class Attended",
      dataIndex: "class1",
      // sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    // {
    //   title: "Section",
    //   dataIndex: "section",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.section.length - b.section.length,
    // },
    {
      title: "Gender",
      dataIndex: "sex",
      // sorter: (a: TableData, b: TableData) => a.gender.length - b.gender.length,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Active" ? (
            <span
              className={`badge badge-soft-success d-inline-flex align-items-center`}
            >
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span
              className={`badge ${
                text === "Assigned"
                  ? "badge-soft-success"
                  : text === "Admitted"
                  ? "badge-soft-primary"
                  : "badge-soft-danger"
              } d-inline-flex align-items-center`}
            >
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      // sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
    // {
    //   title: "Date of Join",
    //   dataIndex: "DateofJoin",
    //   // sorter: (a: TableData, b: TableData) =>
    //   //   a.DateofJoin.length - b.DateofJoin.length,
    // },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      // sorter: (a: TableData, b: TableData) => a.DOB.length - b.DOB.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div className="d-flex align-items-center">
            {record.status === "Assigned" ? (
              <button
                className="dropdown-item rounded-1"
                data-bs-target="#edit_Schedule"
                // onClick={() => navigate(`/change-class/${record.id}`)}
                style={{
                  textAlign: "center",
                  backgroundColor: "#5295fa",
                  border: "none",
                }}
              >
                <i className="ti ti-edit-circle me-2" />
                Change class
              </button>
            ) : record.status === "Pass" ? (
              <button
                className="dropdown-item rounded-1"
                data-bs-target="#edit_Schedule"
                onClick={() =>
                  navigate(
                    `/student/student-fees?application_no=${record.application_no}`
                  )
                }
                style={{
                  textAlign: "center",
                  backgroundColor: "#5295fa",
                  border: "none",
                }}
              >
                <i className="ti ti-edit-circle me-2" />
                Proceed to Registration
              </button>
            ) : (
              <button
                className="dropdown-item rounded-1"
                data-bs-target="#edit_Schedule"
                onClick={() => navigate(`/score/${record.id}`)}
                style={{
                  textAlign: "center",
                  backgroundColor: "#5295fa",
                  border: "none",
                }}
              >
                <i className="ti ti-edit-circle me-2" />
                Fill Entrance Score
              </button>
            )}
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="card">
            <div className="card-body p-0 py-3">
              Entrance List
              <Table dataSource={new_data} columns={columns} Selection={true} />
              Entrance List
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* <StudentModals /> */}
    </>
  );
};

export default EntranceExamsScore;
