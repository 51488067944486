export const examresult = [
  {
    admissionNo: "AD9892434",
    studentName: "Janet",
    english: 95,
    spanish: 93,
    physics: 88,
    chemistry: 90,
    maths: 85,
    computer: 98,
    envScience: 95,
    total: 644,
    percent: 92,
    grade: "O",
    result: "Pass",
    roll: "Roll No : 35013",
    img: "assets/img/students/student-01.jpg",
    key: 1,
  },
  {
    admissionNo: "AD9892433",
    studentName: "Joann",
    english: "30",
    spanish: 35,
    physics: 36,
    chemistry: "28",
    maths: 38,
    computer: 40,
    envScience: 37,
    total: 244,
    percent: 34,
    grade: "F",
    result: "Fail",
    roll: "Roll No : 35012",
    img: "assets/img/students/student-02.jpg",
    key: 2,
  },
  {
    admissionNo: "AD98924",
    studentName: "Kathleen",
    english: 70,
    spanish: 80,
    physics: 85,
    chemistry: 78,
    maths: 82,
    computer: 83,
    envScience: 80,
    total: 558,
    percent: 79,
    grade: "A",
    result: "Pass",
    roll: "Roll No : 35011",
    img: "assets/img/students/student-03.jpg",
    key: 3,
  },
  {
    admissionNo: "AD9892431",
    studentName: "Gifford",
    english: 60,
    spanish: 68,
    physics: 65,
    chemistry: 70,
    maths: 67,
    computer: 72,
    envScience: 75,
    total: 477,
    percent: 68,
    grade: "B+",
    result: "Pass",
    roll: "Roll No : 35010",
    img: "assets/img/students/student-04.jpg",
    key: 4,
  },
  {
    admissionNo: "AD98924",
    studentName: "Lisa",
    english: 36,
    spanish: "30",
    physics: 40,
    chemistry: 38,
    maths: 50,
    computer: 48,
    envScience: 43,
    total: "28",
    percent: 40,
    grade: "F",
    result: "Fail",
    roll: "Roll No : 35009",
    img: "assets/img/students/student-05.jpg",
    key: 5,
  },
  {
    admissionNo: "AD9892429",
    studentName: "Ralph",
    english: 43,
    spanish: 50,
    physics: 62,
    chemistry: 70,
    maths: 65,
    computer: 58,
    envScience: 60,
    total: 408,
    percent: 58,
    grade: "B",
    result: "Pass",
    roll: "Roll No : 35008",
    img: "assets/img/students/student-06.jpg",
    key: 6,
  },
  {
    admissionNo: "AD98924",
    studentName: "Julie",
    english: 72,
    spanish: 80,
    physics: 75,
    chemistry: 78,
    maths: 84,
    computer: 87,
    envScience: 76,
    total: 552,
    percent: 78,
    grade: "A",
    result: "Pass",
    roll: "Roll No : 35007",
    img: "assets/img/students/student-07.jpg",
    key: 7,
  },
  {
    admissionNo: "AD9892427",
    studentName: "Ryan",
    english: 40,
    spanish: 48,
    physics: 42,
    chemistry: 47,
    maths: "32",
    computer: 58,
    envScience: 50,
    total: 317,
    percent: 45,
    grade: "F",
    result: "Fail",
    roll: "Roll No : 35006",
    img: "assets/img/students/student-08.jpg",
    key: 8,
  },
  {
    admissionNo: "AD9892426",
    studentName: "Susan",
    english: 60,
    spanish: 62,
    physics: 65,
    chemistry: 70,
    maths: 72,
    computer: 63,
    envScience: 78,
    total: 470,
    percent: 67,
    grade: "B+",
    result: "Pass",
    roll: "Roll No : 35005",
    img: "assets/img/students/student-09.jpg",
    key: 9,
  },
  {
    admissionNo: "AD9892425",
    studentName: "Susan",
    english: 72,
    spanish: 78,
    physics: 85,
    chemistry: 83,
    maths: 86,
    computer: 90,
    envScience: 92,
    total: 586,
    percent: 83,
    grade: "A+",
    result: "Pass",
    roll: "Roll No : 35004",
    img: "assets/img/students/student-10.jpg",
    key: 10,
  },
];
